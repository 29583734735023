import React, { useContext, useEffect, useState } from 'react';
import CustomButton from '../UI/CustomButton';
import { SVGs } from '../../constants/Svgs';
import RoutesSummary from './RoutesSummary';
import { DifficultySummaryItemProps, GymMinimalProps } from '../../constants/types';
import { pages } from '../../constants/Consts';
import { GlobalContext } from '../../context/GlobalStateProvider';
import Modal from '../UI/ModalRoute';
import { useNavigate } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner';

interface NavBarBackOfficeProps {
    profilePicture?: string;
    firstName?: string;
    lastName?: string;
    gyms?: string;
    difficultySummary? : DifficultySummaryItemProps []
    totalRoutesCount?: number
    difficultySystem?: boolean
   
}


const NavBarBackOffice: React.FC<NavBarBackOfficeProps> = ({ profilePicture, firstName, lastName, gyms }) => {
    const globalContext = useContext(GlobalContext);
    const { currentPage,setCurrentGym,gymsFilterInfo,currentGym,routeSetterInfo,setUser} = globalContext;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate()


    const handleGymSelect = (gym: GymMinimalProps) => {
        setCurrentGym(gym);
        localStorage.setItem('favoriteGymInfo', JSON.stringify(gym));
        setIsModalOpen(false);
        window.location.reload()
    };

   




return (
    <nav>
        <div className="bo-left-sidebar">
            <div className="bo-left-sidebar__logo-layout">
                <CustomButton
                    Svg={SVGs.gastonOuvertureSmall}
                    onClickProp={()=>navigate(`/homepagebo`,)}
                />
            </div>
            <div className="bo-left-sidebar__menu">
                
                <div className="bo-left-sidebar__submenu">
                    <div className="gyms__my-gyms">
                        {gyms &&
                            <CustomButton 
                                customStyle='gyms__my-gyms-single-container bold normal'
                                text={gyms}
                               
                                pointerDisabled={true}
                            />
                            
                        }
                        <CustomButton 
                                customStyle='gyms__my-gyms-single-container-change bold normal'
                                text='Changer salle'
                                Svg={SVGs.changeGym}
                                onClickProp={()=>setIsModalOpen(true)}
                            />
                        
                    </div>
                    <div className="navbar-line-separator"></div>
                    <div className="submenu__options">
                        <CustomButton 
                            LeftSvg={SVGs.smallHouse}
                            text='Accueil'
                            customStyle={currentPage == pages.accueil ? 'switch-page-button-active' : 'switch-page-button'}
                            customTextStyle='regular normal'
                            onClickProp={()=>navigate(`/homepagebo`,)}


                        />
                        <CustomButton 
                            LeftSvg={SVGs.sectorsIcon}
                            text='Secteurs'
                            customStyle={currentPage == pages.secteurs ? 'switch-page-button-active' : 'switch-page-button'}
                            customTextStyle='regular normal'
                            onClickProp={()=>navigate(`/sectors-details`,)}
                        />
                        <CustomButton 
                            LeftSvg={SVGs.circuitsIcon}
                            text='Circuits'
                            customStyle={currentPage == pages.circuits ? 'switch-page-button-active' : 'switch-page-button'}
                            customTextStyle='regular normal'
                            onClickProp={()=>navigate('/circuits-overview',)}

                        />
                        <CustomButton 
                            LeftSvg={SVGs.commentsIcon}
                            text='Commentaires'
                            customStyle={currentPage == pages.commentaires ? 'switch-page-button-active' : 'switch-page-button'}
                            customTextStyle='regular normal'
                            onClickProp={()=>navigate('/comments',)}
                        />
                        <CustomButton 
                            LeftSvg={SVGs.boulderDetailsIcon}
                            text='Détail blocs'
                            customStyle={currentPage == pages.detailBlocs ? 'switch-page-button-active' : 'switch-page-button'}
                            customTextStyle='regular normal'
                            onClickProp={()=>navigate(`/bomain`,)}

                        />
                    </div>
                    <div className="navbar-line-separator"></div>
                        <div className="bo-left-sidebar__profile">
                            <div className="bo-left-sidebar__profile-info">
                                <div className="bo-left-sidebar__Profile-pictures">
                                    <a href="#">
                                        <img src={profilePicture} alt="profile-picture"/>
                                    </a>
                                </div>
                                <div className="bo-left-sidebar__name">
                                    <div className="regular normal">
                                        {firstName} {lastName}
                                    </div>
                                </div>
                            </div>
                            <div className="bo-left-sidebar__profile-settings">
                                {routeSetterInfo && routeSetterInfo.isAdmin &&
                                    <CustomButton 
                                        LeftSvg={SVGs.settingsIcon}
                                        text='Paramètres'
                                        customStyle={currentPage == pages.parameters ? 'switch-page-button-active' : 'switch-page-button'}
                                        customTextStyle='regular normal'
                                        onClickProp={()=>navigate(`/parameters-admin`)}
                                        
                                    />
                                }
                                <CustomButton 
                                    LeftSvg={SVGs.logoutIcon}
                                    text='Déconnexion'
                                    customStyle='settings-page-button'
                                    customTextStyle='regular normal'
                                    onClickProp={() => {
                                        localStorage.removeItem('user');
                                        navigate(`/login-pro`);
                                        setUser('')
                                    }}
                                />
                            </div>

                        </div>

                </div>    
            </div> 
            {/*
                <RoutesSummary 
                    difficulties={difficultySummary}
                    totalRoutesCount={totalRoutesCount}
                    difficultySystem={difficultySystem}
                />
            */}
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} titleModal="Choix salle" width='fit-content' position='relative'>
                <div className="gym-list">
                    
                    { gymsFilterInfo ? 
                    
                        gymsFilterInfo.map((gym) => ( 
                        <CustomButton
                            customStyle={currentGym && gym.id === currentGym.id ? "select-gym-button" : "select-gym-button-inactive"}
                            onClickProp={() => handleGymSelect(gym)}
                            text={gym.name}
                            customTextStyle='bold large2'
                        />
                        
                        ))
                        :
                        <div style={{alignSelf: 'center'}}>
                        <TailSpin color="#0099CC" height={80} width={80} />
                        </div>
                    }
                </div>
            </Modal>
        </div>            
        
    </nav>
)}

export default NavBarBackOffice