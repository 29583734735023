import React, { useState,useEffect } from 'react';

interface DifficultySvgProps {
    svg:string
    text:string
    isClicked? : boolean,
    customStyle? : string,
    customTextStyle? : string,
    onClickProp?: () => void
    hasDifficultySystem : boolean

}


const DifficultySvg : React.FC<DifficultySvgProps> = ({ isClicked, customStyle, customTextStyle,text,onClickProp,svg, hasDifficultySystem }) => {
    const [isClickedStatus, setIsClickedStatus] =useState(isClicked)
  
    const baseClasses = [];

    if (customStyle) {
        baseClasses.push(customStyle);
    }
    if(customTextStyle) {
        baseClasses.push(customTextStyle)
    }


    useEffect(() => {
        setIsClickedStatus(isClicked);
    }, [isClicked]);


    return (
        <div className={`boulder-difficulty__filter ${baseClasses.join(' ')} 
                ${isClickedStatus && hasDifficultySystem ? 'clicked2' : ''} 
                ${isClickedStatus && !hasDifficultySystem ? 'clicked3' : ''}`}
                dangerouslySetInnerHTML={{ __html: svg }} onClick={onClickProp}>
    
        </div>
    )
}


export default DifficultySvg