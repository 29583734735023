import React, { useState } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { csrftoken, formatDate } from '../../constants/helpers';
import Modal from '../UI/ModalRoute';
import axios from 'axios';
import { Colors } from '../../constants/Consts';
import { useNavigate } from 'react-router-dom';

interface SectorSummaryRowProps {
    id: number
    name: string,
    countBoulder : number
    openingDate : string,
    plannedDismantling  : string,
    
}


const SectorSummaryRow : React.FC<SectorSummaryRowProps> = ({ countBoulder,id,name,openingDate,plannedDismantling }) => {

    const [changeDate, setChangeDate] = useState(false)
    const navigate = useNavigate()

    const toggleChangeDate = () => {
        setChangeDate(prev => !prev); // Toggle the state of extendedRow
    };
    const [date, setDate] = useState('');
    const [error, setError] = useState('');

    const handleDateChange = (e) => {
        const value = e.target.value;
        setDate(value);
    
        // Validate the date format (DD/MM/YYYY)
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!datePattern.test(value)) {
          setError('La date doit être saisie au format JJ/MM/AAAA');
        } else {
          setError('');
        }
    };

    const handleConfirm = async () => {
        if (error) {
            alert('Veuillez corriger les erreurs avant de confirmer.');
            return;
        }

        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
            const response = await axios.post(`/api/update-sector/${id}/`, {
                date,
                
            });

            if (response.status === 200) {
                window.location.reload(); // Refresh the page on success
            }
        } catch (error) {
            console.error('Error updating sector:', error);
        }
    };
 
    return (
        <div className='table-body__row__outer-container'>
            <div className='sector-summary-row'>  
                <div className='sector-summary-row-name regular normal' onClick={() => {navigate('/sectors-details')}}>
                    {name}
                </div>
                <div className='sector-summary-row-count regular normal' onClick={() => {navigate('/sectors-details')}}>
                    {countBoulder}
                </div>   
                <div className='sector-summary-row-opening regular normal' onClick={() => {navigate('/sectors-details')}}>
                    {formatDate(openingDate)} 
                </div>
                <div className='sector-summary-row-closing regular normal' onClick={() => {navigate('/sectors-details')}}>
                    {formatDate(plannedDismantling)}
                </div>
                <CustomButton
                    Svg={SVGs.modifySector}
                    customStyle='sector-summary-row-modify'
                    onClickProp={() => setChangeDate(true)}
                />
            </div>
            <Modal isOpen={changeDate} onClose={toggleChangeDate} titleModal='Modification date de démontage' width='fit-content' position='relative'>
            <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '32px 40px 32px 40px', alignItems:'center'}}>
                <div className='modal-change-closing-date-container'>
                    <div className="form-change-closing-date-container">
                        <label htmlFor="demontage-date" className='bold normal' style={{color:Colors.colorbackoffice800}}>Date démontage</label>
                        <input
                        type="text"
                        id="demontage-date"
                        value={date}
                        onChange={handleDateChange}
                        placeholder="JJ/MM/AAAA"
                        
                        />
                        { <div className={error ?"error-change-date-message regular lower2" : "change-date-message regular lower2"}>
                            <CustomButton
                                Svg={SVGs.information}
                            />
                            <div>La date doit être saisie au format JJ/MM/AAAA</div>
                        </div>}
                    </div>
                    <div className='checkbox-group-container'>                        
                    </div>
                    <div className="modal-change-closing-date-button">
                        <CustomButton 
                            customStyle='modal-change-closing-date-button-confirm bold normal'
                            text='Confirmer'
                            disabled={!!error || date == ''}
                            onClickProp={handleConfirm}
                            customDisableStyle='modal-change-closing-date-button-confirm-disabled bold normal'
                        />
                        <CustomButton 
                            customStyle='modal-change-closing-date-button-cancel bold normal'
                            text='Retour'
                            onClickProp={()=>setChangeDate(false)}
                        
                        />
                        
                    </div>
                </div>
            </div>

        </Modal>
    </div>
    )

}

export default SectorSummaryRow