import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';

interface StatBoxProps {
    text:string
    currentMonthStat : number,
    lastMonthStat : number,
    isPercentage?: boolean;
    additionalStyle?: string

    
}


const StatBox : React.FC<StatBoxProps> = ({  text, currentMonthStat,lastMonthStat , isPercentage, additionalStyle}) => {
   
    const variation = currentMonthStat -lastMonthStat
    const displayStat = currentMonthStat % 1 === 0 ? currentMonthStat.toFixed(0) : currentMonthStat.toFixed(1);
    const displayVariation = variation % 1 === 0 ? variation.toFixed(0) : variation.toFixed(1);
   
   
    return (
        <div className={additionalStyle ? additionalStyle : 'box-stat-container'}>  
            <div className='box-stat-actual-stat-container bold large3'>
                {displayStat}
                {isPercentage && '%'}
                <p className={variation > 0 ? 'stat-is-up bold normal' : variation === 0 ? 'stat-is-equal bold normal' : 'stat-is-down bold normal'}>
                    {variation > 0 ? `(+${displayVariation}${isPercentage ? ' ppts':''})` : variation === 0 ? '-' : `(${displayVariation}${isPercentage ? ' ppts':''})`}
                </p>
                <CustomButton 
                    Svg={variation > 0 ? SVGs.statIsUp : variation == 0  ? SVGs.statIsEqual : SVGs.statIsDown}
                    customStyle='stat-icon'
                />

            </div>
            <div className='regular large'>
                {text}
            </div>
            
        </div>
        

   
    )
}


export default StatBox