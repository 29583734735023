import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { getGymGeneralStats, getLatestGymRouteComments, getRouteSetterGymFilterInfo, getRouteSetterUserInfo, getSectorsOverview, getTopBoulders, ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken, formatDateRange } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButton';

import NavBarBackOffice from '../components/backOffice/Navbar';
import { BestBoulderCard, CommentBouldersSector, GymMinimalProps, GymStats, RouteSetterProps, SectorCategory, SectorsOverview } from '../constants/types';
import { GlobalContext } from '../context/GlobalStateProvider';
import Modal from '../components/UI/ModalRoute';
import { pages } from '../constants/Consts';
import StatBox from '../components/backOffice/StatBox';
import DifficultySummaryHome from '../components/HomePage/DifficultySummaryHome';
import SectorSummaryRow from '../components/backOffice/SectorSummaryRow';
import { TailSpin } from 'react-loader-spinner';
import BestBoulderCardComponent from '../components/UI/BestBoulderCard';
import CommentsCard from '../components/backOffice/CommentsCard';


interface BestBouldersListProps {
    boulders: BestBoulderCard[];
}

export const BestBouldersList: React.FC<BestBouldersListProps> = ({ boulders }) => {
    const [showMoreBoulders, setShowMoreBoulders] = useState(false);

    const displayedBoulders = showMoreBoulders ? boulders : boulders.slice(0, 4);

    return (
        <div style={{display:'flex', flexDirection:'column', gap:'16px',alignItems:'flex-start', width: '100%'}}>
            <div className='best-boulder-card-list-container'>
                {displayedBoulders.map((boulder) => (
                    <BestBoulderCardComponent
                        key={boulder.id}
                        {...boulder}
                        hasDifficultySystem={true} // or false based on your logic
                    />
                ))}
            </div>
            {boulders.length > 4 && !showMoreBoulders && (
                <CustomButton  
                    text='Afficher la suite des blocs'
                    onClickProp={()=>setShowMoreBoulders(true)}
                    customStyle='show-more-best-boulder-cards-button bold normal'
                />
            )}
        
        </div>
    );
};

const HomePageBO: React.FC = () => {

    const location = useLocation();
    const uuid = location.state?.uuid;
    const [gymsGeneralStats, setGymsGeneralStats] = useState<GymStats | undefined >(undefined)
    const [shouldBeLoading, setShouldBeLoading] = useState(true)
    const [comments, setComments] = useState<CommentBouldersSector[]>([]);
    const navigate = useNavigate()

    const [sectorsOverview, setSectorsOverview] = useState<SectorsOverview[]>([]);
    const [latestOpeningSector, setLatestOpeningSector] = useState<SectorCategory | undefined>(undefined);
    const [upcomingClosingSectors, setUpcomingClosingSectors] = useState<SectorCategory[]>([]);
    const [zeroBouldersSectors, setZeroBouldersSectors] = useState<SectorCategory[]>([
        
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const globalContext = useContext(GlobalContext);
    const { currentGym, setCurrentGym,setCurrentPage,currentPage,setGymsFilterInfo,gymsFilterInfo,routeSetterInfo,setRouteSetterInfo,user } = globalContext;
    const [bestBoulders, setBestBoulders] = useState<BestBoulderCard[] | undefined>(undefined);




    useEffect(() => {
        document.title = "Accueil"
    },[])

    useEffect(() => {
        const favoriteGym = localStorage.getItem('favoriteGymInfo');
        if (favoriteGym) {
            setCurrentGym(JSON.parse(favoriteGym));
        } else if (user) {
            getRouteSetterGymFilterInfo(user).then(data => {
                setGymsFilterInfo(data);
                if (data.length > 0) {
                    setIsModalOpen(true); // Open the modal if no favorite gym in localStorage
                }
            });

        } else {
            console.error("user not provided");
        }
    }, [user, setCurrentGym]);

    useEffect(() => {
        setCurrentPage(pages.accueil)
    }, []);





    useEffect(() => {
        if ((user) && currentGym) {
            getRouteSetterUserInfo(user).then(data => {
                setRouteSetterInfo(data);
            });
            getGymGeneralStats(user,currentGym.id).then(data=>{
                setGymsGeneralStats(data)
            })
            getTopBoulders(currentGym.id).then(data=>{
                setBestBoulders(data)
            })
            getLatestGymRouteComments(currentGym.id).then(data=>{
                setComments(data)
            })
            getSectorsOverview(user, currentGym.id).then(data => {
                const sortedData = data.sort((a, b) => {
                    const dateA = new Date(a.plannedClosingDate).getTime();
                    const dateB = new Date(b.plannedClosingDate).getTime();
                    return dateA - dateB; // Sorts in ascending order
                });
            
                setSectorsOverview(sortedData);
            

                // Find the sector with the earliest opening date
                const latestOpening = data.reduce((latest, sector) => {
                    return !latest || new Date(sector.openingDate) > new Date(latest.relevantDate)
                        ? { id: sector.id, name: sector.name, relevantDate: sector.openingDate }
                        : latest;
                }, undefined as SectorCategory | undefined);
                setLatestOpeningSector(latestOpening);

                // Find sectors with planned dismantling after today
                const today = new Date();
                const upcomingClosing = data
                    .filter(sector => new Date(sector.plannedClosingDate) < today)
                    .map(sector => ({
                        id : sector.id,
                        name: sector.name,
                        relevantDate: sector.plannedClosingDate
                    }));
                setUpcomingClosingSectors(upcomingClosing);

                // Find sectors with countBoulders = 0
                const zeroBoulders = data
                    .filter(sector => sector.countBoulders === 0)
                    .map(sector => ({id: sector.id, name: sector.name, relevantDate: sector.plannedClosingDate }));
                setZeroBouldersSectors(zeroBoulders);
                setShouldBeLoading(false)
            });
            
            
        } else {
            // Handle the case where user is not provided
            if (!localStorage.getItem('user')){
                navigate('/login-pro')
            }
            
            console.error("user not provided");
            setShouldBeLoading(false)
        }
    }, [user, currentGym]);


    const handleGymSelect = (gym: GymMinimalProps) => {
        setCurrentGym(gym);
        localStorage.setItem('favoriteGymInfo', JSON.stringify(gym));
        setIsModalOpen(false);
        setShouldBeLoading(true)
    };

    
    return (
        
        <div className='outerContainer'>
            <NavBarBackOffice
                profilePicture={routeSetterInfo?.picture}
                firstName={routeSetterInfo?.firstName}
                lastName={routeSetterInfo?.lastName}
                gyms={currentGym && currentGym.name}
                
            />  {shouldBeLoading && 
                    <div className="loading-overlay">
                        <TailSpin color="#0099CC" height={80} width={80} />
                    </div>
                }   
                <div className="bo-main-content">
                <div className="bo-main-content__title">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0607 2.24989C12.4749 1.6641 11.5251 1.6641 10.9393 2.24989L0.96967 12.2196C0.676777 12.5125 0.676777 12.9873 0.96967 13.2802C1.26256 13.5731 1.73744 13.5731 2.03033 13.2802L3 12.3105V20.2499C3 21.4925 4.00736 22.4999 5.25 22.4999H18.75C19.9926 22.4999 21 21.4925 21 20.2499V12.3105L21.9697 13.2802C22.2626 13.5731 22.7374 13.5731 23.0303 13.2802C23.3232 12.9873 23.3232 12.5125 23.0303 12.2196L19.5 8.68923V3.74989C19.5 3.33567 19.1642 2.99989 18.75 2.99989H17.25C16.8358 2.99989 16.5 3.33567 16.5 3.74989V5.68923L13.0607 2.24989ZM19.5 10.8105V20.2499C19.5 20.6641 19.1642 20.9999 18.75 20.9999H5.25C4.83579 20.9999 4.5 20.6641 4.5 20.2499V10.8105L12 3.31055L19.5 10.8105Z" fill="#1D5073"/>
                    </svg>
                    <div className="bold large3" >
                        <p>Tableau de bord - {currentGym && currentGym.name}</p>
                    </div>
                </div>
                <div className="pannels-filter__filters-with-2">
                    <div className="pannels-filter__filters-with-2-1">
                        <div>
                            <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Données générales Gaston<p className='normal'>({formatDateRange()})</p></div>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Les données d’utilisation de Gaston par les grimpeurs sont comparées à celles du mois précédent</div>
                        </div>
                        {gymsGeneralStats &&
                            <div className='stats-outer-container-home'>
                                <StatBox 
                                    currentMonthStat={gymsGeneralStats.connexionsTotal}
                                    lastMonthStat={gymsGeneralStats.lastMonthConnexionsTotal}
                                    text='Connexions Gaston'
                                />
                                <StatBox 
                                    currentMonthStat={gymsGeneralStats.sessionsTotal}
                                    lastMonthStat={gymsGeneralStats.lastMonthSessionsTotal}
                                    text='Séances Créées'
                                />
                                <StatBox 
                                    currentMonthStat={gymsGeneralStats.trysTotal}
                                    lastMonthStat={gymsGeneralStats.lastMonthTrysTotal}
                                    text='Essais Ajoutés'
                                />
                                <StatBox 
                                    currentMonthStat={gymsGeneralStats.distinctConnexions}
                                    lastMonthStat={gymsGeneralStats.lastMonthDistinctConnexions}
                                    text='Utilisateurs actifs'
                                />
                            </div>
                        }

                    </div>
                    <div className="pannels-filter__filters-with-2-2">
                        <div className='header-tips'>
                            <CustomButton 
                                Svg={SVGs.brilliantIdea}
                            />
                            <div className='bold normal'>Astuce Gaston : comment accéder aux vues détaillées ?</div>
                        </div>
                        <div className='regular lower'>
                            Clique sur une des rubriques pour accéder à la vue secteur ou à la vue par difficulté
                        </div>
                    </div>
                </div>  
                <div className="pannels-filter__filters"  onClick={() => {navigate('/circuits-overview')}}>
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Répartition des blocs</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Les blocs de la salle classés par difficulté et sous-difficulté</div>
                    </div>
                    {gymsGeneralStats&&
                        <DifficultySummaryHome 
                            difficultyheaders={gymsGeneralStats.difficulties}
                            totalBoulderCount={gymsGeneralStats.totalActiveRoutes}
                        />
                    }
                </div>
                <div className="pannels-filter__filters">

                    <div style={{width:'100%'}}>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline',cursor:'pointer'}}  onClick={() => {navigate('/sectors-details')}}>Etat de l’ouverture</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal' style={{cursor:'pointer'}} onClick={() => {navigate('/sectors-details')}}>Clique sur un secteur pour consulter les blocs actuels</div>
                    </div>
                    <div className='sectors-summary-display'>
                        <div className= "sectors-tab">
                            <div className="sectors-tab-header bold lower" onClick={() => {navigate('/sectors-details')}}>
                                <div className='sector-header-name'>Derniers secteurs ouverts</div>
                                <div className='sector-header-count'>Blocs</div>
                                <div className='sector-header-opening'>Ouverture</div>
                                <div className='sector-header-closing'>Démontage</div>
                                <div className='sector-header-modify'></div>
                            </div>
                            <div className="sectors-tab-rows">
                                {sectorsOverview && sectorsOverview.map ((sector, index) =>{
                                    return (
                                        <SectorSummaryRow 
                                            key={index}
                                            countBoulder={sector.countBoulders}
                                            id={sector.id}
                                            name={sector.name}
                                            openingDate={sector.openingDate}
                                            plannedDismantling={sector.plannedClosingDate}
                                        />
                                    )
                                })}
                            </div>
                        </div>

                        <div className='sector-warning-container'>
                            {zeroBouldersSectors.length>0 && 
                                <div className='empty-sector-container'>
                                    <div className='bold normal empty-sector-container-header'>
                                        Secteur(s) démonté(s)
                                    </div>
                                    {zeroBouldersSectors.slice(0,3).map((sector,index) =>(
                                        
                                            <div key={index} className='empty-sector-container-item'>
                                                <div className='empty-sector-container-sector-name regular normal'>
                                                    {sector.name}
                                                </div>
                                                <div className='empty-sector-container-sector-dismantled-date light lower'>
                                                {sector.relevantDate}
                                                </div>
                                            </div>
                                        
                                    ))}
                                    {zeroBouldersSectors.length>3 &&
                                        <div>...</div>
                                    }
                                    

                                </div>
                            }
                            <div className='second-sector-summary-part'>
                                {upcomingClosingSectors && upcomingClosingSectors.length>0  &&
                                    <div className='upcoming-sector-container'>
                                        <div className='bold normal upcoming-sector-container-header'>
                                            Ouverture en retard
                                        </div>
                                        {upcomingClosingSectors.slice(0,3).map((sector,index) =>(
                                            
                                                <div key={index} className='empty-sector-container-item'>
                                                    <div className='empty-sector-container-sector-name regular normal'>
                                                        {sector.name}
                                                    </div>
                                                    <div className='empty-sector-container-sector-dismantled-date light lower'>
                                                    {sector.relevantDate}
                                                    </div>
                                                </div>
                                            
                                        ))}
                                        {upcomingClosingSectors.length>3 &&
                                            <div>...</div>
                                        }
                                        

                                    </div>
                                }
                                {latestOpeningSector &&
                                    <div className='latest-sector-container'>
                                        <div className='bold normal latest-sector-container-header'>
                                            Dernière ouverture
                                        </div>
                                        <div className='empty-sector-container-item'>
                                            <div className='empty-sector-container-sector-name regular normal'>
                                                {latestOpeningSector.name}
                                            </div>
                                            <div className='empty-sector-container-sector-dismantled-date light lower'>
                                                {latestOpeningSector.relevantDate}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    

                </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Les blocs les plus essayés</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Classés par le nombre d’essais par jour</div>
                    </div>
                    {bestBoulders && <BestBouldersList boulders={bestBoulders} />}
                </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Commentaires</div>
                    </div>
                    <div style={{display: 'flex', flexDirection:'column',gap :'16px', width:'100%'}}> 
                        {(comments && comments.length > 0) ?
                            comments.map((comment)=>
                                <CommentsCard 
                                    profilePictureUser={comment.profilePictureUser}
                                    boulderId={comment.boulderId}
                                    boulderName={comment.boulderName}
                                    boulderPicture={comment.boulderPicture}
                                    comment={comment.comment}
                                    commentId={comment.commentId}
                                    createdAt={comment.createdAt}
                                    difficultySvg={comment.difficultySvg}
                                    firstNameUser={comment.firstNameUser}
                                    lastNameUser={comment.lastNameUser}
                                    level={comment.level}
                                    sectorName={comment.sectorName}
                                />
                            )
                            :
                            <div className='no-routes-msg light large2'>
                                Pas encore de commentaire  
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} titleModal="Choix salle" position='relative' width='fit-content'>
                <div className="gym-list">
                    { gymsFilterInfo ? 
                        
                        gymsFilterInfo.map((gym) => ( 
                        <CustomButton
                            customStyle={currentGym && gym.id === currentGym.id ? "select-gym-button" : "select-gym-button-inactive"}
                            onClickProp={() => handleGymSelect(gym)}
                            text={gym.name}
                            customTextStyle='bold large2'
                        />
                        
                        ))
                        :
                        <div style={{alignSelf: 'center'}}>
                        <TailSpin color="#0099CC" height={80} width={80} />
                        </div>
                    }
                </div>
            </Modal>
        </div>
    );
}

export default HomePageBO;

