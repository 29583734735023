import { SVGs } from "./Svgs";
import { Level } from "./types";
import React from 'react';

export const GRADES = [
    "3A", "3A+", "3B", "3B+", "3C", "3C+","4A", "4A+", "4B", "4B+","4C", "4C+", "5A", "5A+", "5B", "5B+", "5C", "5C+",
    "6A", "6A+","6B", "6B+", "6C", "6C+", "7A", "7A+", "7B", "7B+",
    "7C", "7C+","8A", "8A+", "8B", "8B+", "8C", "8C+", "9A","9A+", "9B", "9B+"
];


const level1: Level = {
    id: 1,
    backValue: -1
};

const level2: Level = {
    id: 2,
    backValue:0
};

const level3: Level = {
    id: 3,
    backValue: 1
};

export const Levels = { level1, level2, level3 };

export const initialRoutesToFetchValueStart = 0
export const initialRoutesToFetchValueEnd = 24

export const gyms = [
    { id: 'ablok-annecy', name: 'Ablok, Annecy' },
    { id: 'ablok-grenoble', name: 'Ablok, Grenoble' },
    { id: 'altigrimpe', name: 'Altigrimpe' },
    { id: 'altissimo-albi', name: 'Altissimo, Albi' },
    { id: 'altissimo-marseille', name: 'Altissimo, Marseille' },
    { id: 'altissimo-metz', name: 'Altissimo, Metz' },
    { id: 'altissimo-montpellier-grabels', name: 'Altissimo, Montpellier Grabels' },
    { id: 'altissimo-montpellier-odysseum', name: 'Altissimo Montpellier Odysseum' },
    { id: 'altissimo-nantes', name: 'Altissimo, Nantes' },
    { id: 'altissimo-toulouse-montaudran', name: 'Altissimo, Toulouse Montaudran' },
    { id: 'altissimo-toulouse-saint-martin', name: 'Altissimo Toulouse Saint-Martin' },
    { id: 'antrebloc', name: 'Antrebloc' },
    { id: 'arkose-bordeaux', name: 'Arkose, Bordeaux' },
    { id: 'arkose-genevois', name: 'Arkose, Genevois' },
    { id: 'arkose-montreuil', name: 'Arkose, Montreuil' },
    { id: 'arkose-massy', name: 'Arkose, Massy' },
    { id: 'arkose-nation', name: 'Arkose, Nation' },
    { id: 'arkose-pantin', name: 'Arkose, Pantin' },
    { id: 'arkose-prado', name: 'Arkose, Prado' },
    { id: 'arkose-rouen', name: 'Arkose, Rouen' },
    { id: 'arkose-toulouse', name: 'Arkose, Toulouse' },
    { id: 'arkose-tours', name: 'Arkose, Tours' },
    { id: 'art-bloc', name: 'Art Bloc' },
    { id: 'asanakene', name: 'Asanakene' },
    { id: 'atome-annecy', name: 'Atome, Annecy' },
    { id: 'beta-bloc', name: 'Beta Bloc' },
    { id: 'bloc-session-ajaccio', name: 'Bloc Session, Ajaccio' },
    { id: 'bloc-session-avignon', name: 'Bloc Session, Avignon' },
    { id: 'bloc-session-la-ciotat', name: 'Bloc Session, La ciotat' },
    { id: 'bloc-session-lyon', name: 'Bloc Session, Lyon' },
    { id: 'bloc-session-marseille-centre', name: 'Bloc Session, Marseille Centre' },
    { id: 'bloc-session-marseille-sud', name: 'Bloc Session, Marseille Sud' },
    { id: 'bloc-session-montpellier', name: 'Bloc Session, Montpellier' },
    { id: 'bloc-session-nancy', name: 'Bloc Session, Nancy' },
    { id: 'bloc-session-nimes', name: 'Bloc Session, Nimes' },
    { id: 'bloc-session-pertuis', name: 'Bloc Session, Pertuis' },
    { id: 'bloc-session-toulon', name: 'Bloc Session, Toulon' },
    { id: 'blocabrac', name: 'Blocabrac' },
    { id: 'blocbuster-courbevoie', name: 'Blocbuster, Courbevoie' },
    { id: 'blockout-aix-en-provence', name: 'Block\'out, Aix en provence' },
    { id: 'block-alpes', name: 'Block Alpes' },
    { id: 'blockout-lyon', name: 'Block\'out, Lyon' },
    { id: 'blockout-nantes', name: 'Block\'out, Nantes' },
    { id: 'block-session', name: 'Block-Session' },
    { id: 'blockout-strasbourg', name: 'Block\'out, Strasbourg' },
    { id: 'blockout-bordeaux', name: 'Block\'out, Bordeaux' },
    { id: 'blockout-cergy', name: 'Block\'out, Cergy' },
    { id: 'blockout-evry-lisses', name: 'Block\'out, Evry lisses' },
    { id: 'blockout-lille', name: 'Block\'out, Lille' },
    { id: 'blockout-metz', name: 'Block\'out, Metz' },
    { id: 'blockout-reims', name: 'Block\'out, Reims' },
    { id: 'blockout-rennes', name: 'Block\'out, Rennes' },
    { id: 'blockout-st-ouen', name: 'Block\'out, St ouen' },
    { id: 'blockout-toulouse', name: 'Block\'out, Toulouse' },
    { id: 'blockout-tours', name: 'Block\'out, Tours' },
    { id: 'climbup-aix', name: 'Cimb\'up, Aix' },
    { id: 'climbup-aix-bouc-bel-air', name: 'Cimb\'up, Aix-bouc Bel Air' },
    { id: 'cime-altitude', name: 'Cime Altitude' },
    { id: 'climbup-caen', name: 'Climb\'up, Caen' },
    { id: 'climbup-aubagne', name: 'Climb\'up, Aubagne' },
    { id: 'climbup-bordeaux-eysines', name: 'Climb\'up, Bordeaux Eysines' },
    { id: 'climbup-bordeaux-merignac', name: 'Climb\'up, Bordeaux Mérignac' },
    { id: 'climbup-brest', name: 'Climb\'up, Brest' },
    { id: 'climbup-chambery', name: 'Climb\'up, Chambéry' },
    { id: 'climbup-dijon', name: 'Climb\'up, Dijon' },
    { id: 'climbup-lille', name: 'Climb\'up, Lille' },
    { id: 'climbup-lyon-confluence', name: 'Climb\'up, Lyon Confluence' },
    { id: 'climbup-lyon-gerland', name: 'Climb\'up, Lyon Gerland' },
    { id: 'climbup-marseille', name: 'Climb\'up, Marseille' },
    { id: 'climbup-nancy', name: 'Climb\'up, Nancy' },
    { id: 'climbup-orleans', name: 'Climb\'up, Orléans' },
    { id: 'climbup-villeneuve-dascq', name: 'Climb\'up, Villeneuve d\'ascq' },
    { id: 'climbup-wambrechies', name: 'Climb\'up, Wambrechies' },
    { id: 'climbzone', name: 'Climb\'zone' },
    { id: 'clumbup-epinay', name: 'Clumb\'up, Epinay' },
    { id: 'clumbup-porte-italie', name: 'Clumb\'up, Porte d\'italie' },
    { id: 'cortigrimpe-73', name: 'Cortigrimpe, 73' },
    { id: 'cortigrimpe-annecy', name: 'Cortigrimpe, Annecy' },
    { id: 'couleur-caillou', name: 'Couleur Caillou' },
    { id: 'duo-des-cimes', name: 'Duo des Cimes' },
    { id: 'edenwall', name: 'Edenwall' },
    { id: 'el-cap', name: 'El Cap' },
    { id: 'espace-escalade', name: 'Espace Escalade' },
    { id: 'espace-escalade-2', name: 'Espace Escalade 2' },
    { id: 'espace-escalade-3', name: 'Espace Escalade 3' },
    { id: 'hardbloc', name: 'Hardbloc' },
    { id: 'hold-up-lyon', name: 'Hold up, Lyon' },
    { id: 'hueko-city', name: 'Hueko City' },
    { id: 'hueko-zenith', name: 'Hueko Zenith' },
    { id: 'isatix-caen', name: 'Isatix, Caen' },
    { id: 'karma-fontainebleau', name: 'Karma, Fontainebleau' },
    { id: 'kern-up', name: 'Kern\'up' },
    { id: 'la-grappe', name: 'La grappe' },
    { id: 'la-o-escalade', name: 'La O Escalade' },
    { id: 'la-verticale-acclameur', name: 'La verticale de L\'acclameur' },
    { id: 'la-zipette-chambery', name: 'La zipette, Chambéry' },
    { id: 'le-labo', name: 'Le labo' },
    { id: 'le-pan-davignon', name: 'Le pan D\'avignon' },
    { id: 'le-perchoir', name: 'Le perchoir' },
    { id: 'le-triangle', name: 'Le triangle' },
    { id: 'lenautil', name: 'Lenautil' },
    { id: 'les-cabanes-urbaines', name: 'Les Cabanes urbaines' },
    { id: 'mroc-laennec', name: 'M\'Roc, Laennec' },
    { id: 'mroc-part-dieu', name: 'M\'Roc, Part Dieu' },
    { id: 'mroc-villeurbanne', name: 'M\'Roc, Villeurbanne' },
    { id: 'modjo', name: 'Modjo' },
    { id: 'pic-et-paroi', name: 'Pic et Paroi' },
    { id: 'pic-et-paroi-2', name: 'Pic et Paroi 2' },
    { id: 'solo-escalade', name: 'Solo Escalade' },
    { id: 'solo-escalade-2', name: 'Solo Escalade 2' },
    { id: 'space-bloc', name: 'Space Bloc' },
    { id: 'start-in-bloc', name: 'Start in Bloc' },
    { id: 'the-roof-bayonne', name: 'The Roof, Bayonne' },
    { id: 'the-roof-brest', name: 'The Roof, Brest' },
    { id: 'the-roof-poitier', name: 'The Roof, Poitier' },
    { id: 'vertical-art-le-mans', name: 'Vertical Art, Le Mans' },
    { id: 'vertical-art-lille', name: 'Vertical Art, Lille' },
    { id: 'vertical-art-lyon', name: 'Vertical Art, Lyon' },
    { id: 'vertical-art-montigny', name: 'Vertical Art, Montigny' },
    { id: 'vertical-art-nantes', name: 'Vertical Art, Nantes' },
    { id: 'vertical-art-pigalle', name: 'Vertical Art, Pigalle' },
    { id: 'vertical-art-rungis', name: 'Vertical Art, Rungis' },
    { id: 'vertical-art-toulon', name: 'Vertical Art, Toulon' },
    { id: 'vitam', name: 'Vitam' },
    { id: 'wattabloc', name: 'Wattabloc' }
]


export const pages = {
    accueil : 'accueil',
    secteurs : 'secteurs',
    circuits : 'circuits',
    commentaires : 'commentaires',
    detailBlocs : 'détail blocs',
    parameters : 'parameters'

}

export const roleMapping = {
    'Chief Route Setter': 'Chef-ouvreur',
    'Route Setter': 'Ouvreur',
    'Marketing Staff': 'Média',
};

export const roleMappingReverse = {
    'Chef-ouvreur': 'Chief Route Setter',
    'Ouvreur': 'Route Setter',
    'Média': 'Marketing Staff',
};


interface GripProps {
    colorCode: string;
}

export const Grip: React.FC<GripProps> = React.memo(({ colorCode }) => {
    const svgWithColor = gripSvg.replace('#EE2F46', colorCode);
    return <div dangerouslySetInnerHTML={{ __html: svgWithColor }} />;
});

const gripSvg = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.8439 23.6691L17.8518 23.67C19.9053 23.8952 21.2515 23.626 22.1253 23.1307C22.9913 22.6398 23.423 21.91 23.6164 21.1414C23.8117 20.3658 23.7627 19.5533 23.6603 18.9257C23.6094 18.6136 23.546 18.3513 23.4955 18.1677C23.4703 18.076 23.4484 18.0041 23.433 17.9558C23.4252 17.9316 23.4192 17.9133 23.4151 17.9014L23.4107 17.8883L23.4097 17.8855L23.4095 17.8852L17.8439 23.6691ZM17.8439 23.6691L17.836 23.6687L17.8246 23.9185L17.8359 23.6687L17.8353 23.6687L17.8327 23.6686L17.8216 23.668L17.7766 23.6656C17.7366 23.6633 17.6769 23.6597 17.599 23.6544C17.4431 23.6437 17.2143 23.6261 16.9242 23.5986C16.344 23.5435 15.5193 23.4485 14.5432 23.2892C12.5893 22.9703 10.0378 22.3951 7.6306 21.3706C5.22053 20.345 2.97934 18.8794 1.6147 16.7942C0.25774 14.7207 -0.253007 12.0034 0.852072 8.41944C1.52288 6.25074 2.8883 4.3688 4.73931 3.09425L4.74011 3.0937C6.21693 2.06825 8.256 1.02065 10.7459 0.609354L10.7567 0.60758L10.7671 0.604901L10.7672 0.604881L10.7673 0.604851L10.7686 0.604538L10.7744 0.603091L10.7989 0.597102C10.821 0.591804 10.8543 0.583991 10.8981 0.574229C10.9857 0.554704 11.1152 0.527397 11.2805 0.496832C11.6112 0.435681 12.0847 0.361616 12.6526 0.310665C13.7906 0.208565 15.2969 0.200309 16.7907 0.567399C18.2825 0.933989 19.751 1.67184 20.8363 3.05497C21.9197 4.43566 22.643 6.48697 22.5958 9.52252L22.5958 9.52298L22.5917 9.56059C22.5882 9.59342 22.5834 9.64161 22.5777 9.7041C22.5663 9.82908 22.5517 10.0113 22.5383 10.2423C22.5114 10.7044 22.4893 11.3621 22.5082 12.1482C22.5461 13.7185 22.7476 15.8096 23.4068 17.877L23.4066 17.8771L23.4095 17.885L17.8439 23.6691ZM13.7655 12.001C13.7655 11.0164 12.9828 10.2153 12.0042 10.2153C11.0255 10.2153 10.2429 11.0164 10.2429 12.001C10.2429 12.9856 11.0255 13.7867 12.0042 13.7867C12.9828 13.7867 13.7655 12.9856 13.7655 12.001Z" fill="#EE2F46" stroke="#101828" stroke-width="1.5"/>
    </svg>`

export const Colors ={
    colorgrey25: '#FCFCFD',
	colorgrey50: '#F9FAFB',
	colorgrey100: '#F2F4F7',
	colorgrey200: '#E4E7EC',
	colorgrey300: '#D0D5DD',
	colorgrey400: '#98A2B3',
	colorgrey500: '#667085',
	colorgrey600: '#475467',
	colorgrey700: '#344054',
	colorgrey800: '#1D2939',
	colorgrey900: '#101828',
	colorred25: '#F8AFB8',
	colorred50: '#F79CA7',
	colorred100: '#F58492',
	colorred200: '#F47383',
	colorred300: '#F25F70',
	colorred400: '#F0475B',
	colorred500: '#EE2F46',
	colorred600: '#E7132C',
	colorred700: '#D91229',
	colorred800: '#BC1024',
	colorred900: '#960D1D',
	colorpurple25: '#D6BCF5',
	colorpurple50: '#CBAAF3',
	colorpurple100: '#BF98F0',
	colorpurple200: '#B586EE',
	colorpurple300: '#A36CEA',
	colorpurple400: '#9555E7',
	colorpurple500: '#8236E2',
	colorpurple600: '#661CC4',
	colorpurple700: '#5818A9',
	colorpurple800: '#451386',
	colorpurple900: '#320E62',
	colororange25: '#F8D6AF',
	colororange50: '#F7CC9C',
	colororange100: '#F5C084',
	colororange200: '#F4B873',
	colororange300: '#F2AD5F',
	colororange400: '#F0A147',
	colororange500: '#EE952F',
	colororange600: '#E78413',
	colororange700: '#D97C12',
	colororange800: '#BC6C10',
	colororange900: '#96560D',
	colorbackoffice25: '#F5FBFF',
	colorbackoffice50: '#F0F9FF',
	colorbackoffice100: '#E0F2FE',
	colorbackoffice200: '#8CBAD9',
	colorbackoffice300: '#73B7E5',
	colorbackoffice400: '#50A9E5',
	colorbackoffice500: '#3698D9',
	colorbackoffice600: '#2C7CB2',
	colorbackoffice700: '#266B99',
	colorbackoffice800: '#1D5073',
	colorbackoffice900: '#163E59',
	colorblackorwhiteblack: '#000000',
	colorblackorwhitewhite: '#ffffff',
	colorprimary25 :'#DDA5F3',
	colorprimary50 :'#D185EF',
	colorprimary100 :'#C76AEC',
	colorprimary200 :'#BE53E9',
	colorprimary300: '#B842E6',
	colorprimary400: '#AD26E3',
	colorprimary500: '#A01CD4',
	colorprimary600: '#9012BA',
	colorprimary700: '#701395',
	colorprimary800: '#550F70',
	colorprimary900: '#3A0A4D',
	colorsecondary25 :'#A3F5DC',
	colorsecondary50 :'#5EEDC2',
	colorsecondary100 :'#3EEAB6',
	colorsecondary200 :'#19E1A5',
	colorsecondary300: '#17D39B',
	colorsecondary400: '#15C18D',
	colorsecondary500: '#13A97C',
	colorsecondary600: '#10936C',
	colorsecondary700: '#0D7758',
	colorsecondary800: '#0A5740',
	colorsecondary900: '#063728',
	colortertiary25 :'#AAE5F8',
	colortertiary50 :'#89DAF5',
	colortertiary100 :'#6DD1F3',
	colortertiary200 :'#4CC7F0',
	colortertiary300: '#2BBDEE',
	colortertiary400: '#12AEE2',
	colortertiary500: '#1099C6',
	colortertiary600: '#0E83AA',
	colortertiary700: '#0B6D8D',
	colortertiary800: '#0A5E7B',
	colortertiary900: '#074155',
	coloryellow25: '#FBFBA7',
	coloryellow50: '#FAFA89',
	coloryellow100: '#F9F976',
	coloryellow200: '#F8F859',
	coloryellow300: '#F7F74A',
	coloryellow400: '#F6F62C',
	coloryellow500: '#F5F50A',
	coloryellow600: '#DCDC09',
	coloryellow700: '#C6C610',
	coloryellow800: '#A5A50D',
	coloryellow900: '#8D8D0B',
}


