import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButtonBO';
import CustomButtonBO from '../components/UI/CustomButtonBO';
import { GlobalContext } from '../context/GlobalStateProvider';
import { Colors } from '../constants/Consts';

const LoginScreenRouteSetter = () => {
    const globalContext = useContext(GlobalContext);
    const { setUser } = globalContext;


    useEffect(() => {
        document.title = "Login ouverture"},[])
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);
    const navigate = useNavigate()
    
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    const togglePasswordConfirmationVisibility = () => {
        setPasswordConfirmationShown(!passwordConfirmationShown);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Adresse email invalide')
                .required("L'email est requis"),
            password: Yup.string()
                .required('Le mot de passe est requis')
        }),
        onSubmit: (values) => {
            // Replace with your API endpoint and necessary payload
            loginUser(
                values.email, // assuming your loginUser function expects an email for username
                values.password,
                (uuid) => { // onSuccess callback
                    navigate(`/homepagebo`, { state: { uuid: uuid } });
                    setUser(uuid)
                    localStorage.setItem('user', JSON.stringify(uuid));
                },
                (error) => { // onError callback
                    setErrorMsg(error);
                    formik.setSubmitting(false)
                    
                }
            );
        
       
        },
    });

    return (
        <div className='login_outercontainer'>
            <div style={{alignSelf:'center', justifySelf:'center'}}>
                    <IconButton 
                        Svg={SVGs.gastonOuvertureLogo}
                    />
                </div>
            <div className='login_container'>
                <div className='password_reset_title_description'> 
                    <h2 className='password_reset_title'>Connexion à mon espace ouvreur</h2>
                    <p className='password_reset_description'>Saisir ses identifiants pour se connecter</p>
                </div>
                <form className='password_reset_container_form' onSubmit={formik.handleSubmit}>
                    <div className='password_reset_container_form_full_formik_validation'>
                        <div className={formik.touched.email && formik.errors.email ? 'password_reset_container_form_input_icon_container_error' : 'password_reset_container_form_input_icon_container' }>
                            <input 
                                type="text"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="E-mail *"
                                className={(formik.touched.email && formik.errors.email) && 'error_input'}
                                onFocus={() => formik.setFieldError('email', '')}
                                
                            />
                            {(formik.touched.email && formik.errors.email) &&
                                <IconButton 
                                    text=''
                                    Svg={SVGs.warningIcon}
                                />
                            }
                        
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                            <div className='error_password_message'>{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className='password_reset_container_form_full_formik_validation'>
                        <div className={'special_password_confirmation_container' }>
                            <div className={formik.touched.password && formik.errors.password ? 'password_reset_container_form_input_icon_container_error' : 'password_reset_container_form_input_icon_container'}>
                                <input 
                                    type={passwordConfirmationShown ? "text" : "password"}
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Mot de passe*"
                                    className={(formik.touched.password && formik.errors.password) && 'error_input'}
                                    onFocus={() => formik.setFieldError('password', '')}
                                />
                                {(formik.touched.password && formik.errors.password) &&
                                    <IconButton 
                                        text=''
                                        Svg={SVGs.warningIcon}
                                    />
                                }
                                <CustomButtonBO
                                    text=''
                                    Svg={passwordConfirmationShown ? SVGs.openEye : SVGs.closedEye}
                                    onClickProp={togglePasswordConfirmationVisibility}
                                    customStyle={'eye_layout'}
                                    
                                /> 
                            </div>

                            {!formik.errors.password &&  
                                <div className='password_indication'>* Le mot de passe doit contenir au moins : 1 majuscule, 1 caractère spécial et 1 chiffre</div>
                            }
                            <div onClick={()=>navigate(`/password-reset`,)} className='light normal' style={{color:Colors.colorgrey900, textDecoration:'underline',marginTop:'24px', cursor:'pointer'   }}>Mot de passe oublié ?</div>
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                            <div className='error_password_message'>{formik.errors.password}</div>
                        ) : null}
                    </div>
                    <button 
                        className={`${formik.isValid && formik.dirty && !formik.isSubmitting ? 'login_button' : 'login_button_disabled'}`}
                        type="submit"
                        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
                    >
                        Se connecter
                        
                    </button>
                </form>
                {errorMsg &&
                        <p style={{marginTop:'-16px'}} className='Landing-page-gym-finder-header-description-error'>{errorMsg}</p>
                }
            </div>
        </div>
    );
}

export default LoginScreenRouteSetter;