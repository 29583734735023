import React from 'react';
import { DifficultySplit } from '../../constants/types';
import { Colors } from '../../constants/Consts';

const DifficultySplitComponent: React.FC<{ difficulty: DifficultySplit }> = ({ difficulty }) => {
    const { difficultyName, boulderCount, difficultyColor1, difficultyColor2 } = difficulty;
    const color1 = difficultyColor1 || Colors.colorgrey100; // Default to grey if no color1
    const color2 = difficultyColor2 || color1; // Default to color1 if no color2

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',gap:'4px'}}>
            <div className='bold normal'>{boulderCount}</div>
            <div style={{
                height: `${20 * boulderCount}px`,
                width: '48px',
                background: `linear-gradient(to right, ${color1} 50%, ${color2} 50%)`,
                borderRadius:'4px'
            }}></div>
            <div className='regular lower' style={{color:Colors.colorgrey600}}>{difficultyName}</div>
        </div>
    );
};

export default DifficultySplitComponent;