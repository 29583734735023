import { Colors } from "../../constants/Consts";
import { SectorBasisChoices, SectorDetailsProps } from "../../constants/types";

const SectorItem: React.FC<SectorBasisChoices & { isSelected: boolean, onSelect: (id: number) => void }> = ({ sectorId, x, y, svgPaths, isSelected, onSelect, width, height,newestSector,oldestSector}) => {
    const handlePress = () => {
        onSelect(sectorId);
    };

    const updatedSvgPaths = svgPaths.map((path, index) => {
        let fillColor;
        if (index === 0) {
            fillColor = 'transparent'; 
        } else if (index === 1) {
            fillColor = isSelected ? Colors.colorprimary400 : newestSector? Colors.colorsecondary400 : oldestSector? Colors.colorred400 :path.fill; 
        } else {
            fillColor = isSelected ? Colors.colorprimary400 : newestSector? Colors.colorsecondary400 : oldestSector? Colors.colorred400 :path.fill;
        }

        return {
            ...path,
            fill: fillColor
        };
    });

    return (
        <svg 
            width={width} 
            height={height} 
            viewBox={`0 0 ${width} ${height}`} 
            style={{
                position: 'absolute',
                left: x,
                top: y,
                cursor: 'pointer'
            }}
            onClick={handlePress}
        >
            {updatedSvgPaths.map((path, index) => (
                <path key={index} d={path.d} fill={path.fill} />
            ))}
        </svg>
    );
};

export default SectorItem