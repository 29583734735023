import React, { useState, FormEvent,useEffect, use, useContext } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'

import {ipAdress, getRouteSetterInfo, getRouteSetterGymFilterInfo, getRouteSetterUserInfo, getTagListInfo, hasAdditionnalDifficultySystem, getRouteSetterInitialInfo, getRouteSetterGymFilterInfoSpecific} from '../data/getAxiosRequests'
import NavBarBackOffice from '../components/backOffice/Navbar';
import { GymMinimalProps, RouteSetterAllRouteInfoProps, SectorExtraMinimalProps,RouteSetterProps, Status, TagsList, GymDifficultySystemMinimalProps, SectorMinimalProps, Level } from '../constants/types';
import CheckMarkButton from '../components/UI/CheckMarkButton';
import ColorSquare from '../components/UI/ColorSquare';
import DifficultySvg from '../components/UI/DifficultySvg';
import LevelSelector from '../components/backOffice/LevelSelector';
import { initialRoutesToFetchValueEnd, initialRoutesToFetchValueStart, Levels, pages } from '../constants/Consts';
import RouteRow from '../components/backOffice/RouteRow';
import { handleItemClick, handleCheckToggle, handleSorting } from '../constants/helpers';
import { SVGs } from '../constants/Svgs';
import CustomButtonSort from '../components/UI/CustomButtonSort';
import CustomButtonBO from '../components/UI/CustomButtonBO';
import CustomButton from '../components/UI/CustomButton';
import { TailSpin } from 'react-loader-spinner';
import { GlobalContext } from '../context/GlobalStateProvider';


// Type for the response from Django (customize this based on your API)





const BackOfficeMainPage: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const globalContext = useContext(GlobalContext);
    const { currentGym, setCurrentGym,user,setCurrentPage } = globalContext;
    
    const [routesInfo, setRoutesInfo] = useState<RouteSetterAllRouteInfoProps | undefined>(undefined);
    const [activeRoutesInfo, setActiveRoutesInfo] = useState<RouteSetterAllRouteInfoProps | undefined>(undefined);
    const [routesFiltered, setRoutesFiltered] = useState<RouteSetterAllRouteInfoProps | undefined>(undefined);
    const [shouldBeLoading, setShouldBeLoading] = useState(true)
    const [routeSetterInfo, setRouteSetterInfo] = useState<RouteSetterProps | undefined >(undefined)
    const [gymsFilterInfo, setGymsFilterInfo] = useState<GymMinimalProps[] | undefined >([])
    const [additionnalDifficultySystem, setAdditionnalDifficultySystem] = useState(false)
    const [difficultyList, setDifficultyList] = useState <GymDifficultySystemMinimalProps[] |undefined> ([])
    const [sectorsList, setSectorsList] = useState <SectorExtraMinimalProps[] |undefined> ([])
    const [tagsList, setTagsList] = useState<TagsList | undefined >(undefined)
    const levelList :Level[] = Object.values(Levels)
    const [initialListSorted, setInitialListSorted] = useState(false)
    const [shouldFilterRoutes, setShouldFilterRoutes] = useState(false)
    const [checkGymAllFilters, setCheckGymAllFilters] = useState(true)
    const [checkColorsAllFilters,setCheckColorsAllFilters] = useState(true)
    const [checkDifficultyAllFilters,setCheckDifficultyAllFilters] = useState(true)
    const [checkLevelAllFilters, setCheckLevelAllFilters] = useState(true)
    const [checkSectorsAllFilters,setCheckSectorsAllFilters] = useState(true)
    const [checkTagsInclinaisonAllFilters, setCheckTagsInclinaisonAllFilters] = useState(true)
    const [checkTagsPrisesAllFilters, setCheckTagsPrisesAllFilters] = useState(true)
    const [checkTagsMovementAllFilters, setCheckTagsMovementAllFilters]= useState(true)
    const [checkStatusAllFilters, setCheckStatusAllFilters] = useState(false)
    const [clickedGyms, setClickedGyms] = useState({});
    const [clickedTagsSteepness, setClickedTagsSteepness] = useState({})
    const [clickedTagsGrips, setClickedTagsGrips] = useState({})
    const [clickedTagsStyle, setClickedTagsStyle] = useState({})
    const [clickedColors, setClickedColors] = useState({});
    const [clickedStatus, setClickedStatus] = useState({})
    const [clickedDifficulties, setClickedDifficulties] = useState({});
    const [clickedLevels, setClickedLevels] = useState({})
    const [clickedSectors, setClickedSectors] = useState({});
    const [startPositionRoute, setStarPositionRoute] = useState(initialRoutesToFetchValueStart)
    const [endPositionRoute, setEndPositionRoute] = useState(initialRoutesToFetchValueEnd)
    const [adder,setAdder] =useState(25)
    const [filteredGyms, setFilteredGyms]=useState([0])
    const [filteredTagsSteepness, setFilteredTagsSteepness]=useState([0])
    const [filteredTagsGrips, setFilteredTagsGrips]=useState([0])
    const [filteredTagsStyle, setFilteredTagsStyle]=useState([0])
    const [filteredStatus, setFilteredStatus]=useState([1])
    const [filteredDifficulties, setFilteredDifficulties]=useState([0])
    const [filteredLevels, setFilteredLevels]=useState([0])
    const [filteredSectors, setFilteredSectors]=useState([0])
    const [sortGyms, setSortGyms] = useState(0)
    const [sortStatus, setSortStatus] = useState(0)
    const [sortDifficulties, setSortDifficulties] = useState(0)
    const [sortLevels, setSortLevels] = useState(0)
    const [sortSectors, setSortSectors] = useState(0)
    const [sortName, setSortName] = useState(0)
    const [sortDate, setSortDate] = useState(2)
    const [sortColor, setSortColor] = useState(0)
    const [difficultySummary, setDifficultySummary] = useState([])
    const [totalRoutesCount, setTotalRoutesCount] = useState(0);
    const [areFiltersOpened, setAreFiltersOpened] = useState(true)
    const [disableClick, setDisableClick]= useState(false)
    const [initialDataFetched, setInitialDataFetched] = useState(false)

    
    useEffect(() => {
        setCurrentPage(pages.detailBlocs)
    }, []);

    useEffect(() => {
        if(currentGym){
            setFilteredGyms([currentGym.id])
        }
        else{
            const favoriteGym = localStorage.getItem('favoriteGymInfo');
            setCurrentGym(JSON.parse(favoriteGym));
        }
    }, []);

    useEffect(() => {
        if(currentGym){
            setFilteredGyms([currentGym.id])
        }
    }, [currentGym]);

    
    const sortSetters = {
        sortGyms: setSortGyms,
        sortStatus: setSortStatus,
        sortDifficulties: setSortDifficulties,
        sortLevels: setSortLevels,
        sortSectors: setSortSectors,
        sortName: setSortName,
        sortDate: setSortDate
    }

    
    
    
    const statusList: Status[] = [
        {
            id: 1,
            status: 'Actif'
        },
        {
            id: 2,
            status: 'Fermé'
        }
    ];

    const loadMoreRoutes = () =>{
        getRouteSetterInfo(
            user,
            startPositionRoute + adder,
            endPositionRoute + adder,
            sortGyms,
            sortSectors,
            sortDifficulties,
            sortLevels,
            sortStatus,
            sortName,
            sortDate,
            filteredGyms,
            filteredDifficulties,
            filteredLevels,
            filteredTagsSteepness,
            filteredTagsGrips,
            filteredTagsStyle,
            filteredStatus,
            filteredSectors
        ).then(data => {
            setRoutesInfo(prevRoutesInfo => ({
                routes: [...prevRoutesInfo.routes, ...data.routes],
                count : data.count

            }));
        
        })
        setStarPositionRoute(startPositionRoute + adder)
        setEndPositionRoute(endPositionRoute + adder)
        
    }

    useEffect(() => {
        document.title = "Back office"},
    [])

    useEffect(() => {
        
        const fetchData = () => {
            
            
            getRouteSetterInitialInfo(
                user,
                currentGym.id,
                
            ).then(data => {
                setRoutesInfo({
                    routes: [...data.routes],
                    count : data.count
                });
                setActiveRoutesInfo({
                    routes: [...data.routes],
                    count : data.count
                })
                setStarPositionRoute(initialRoutesToFetchValueStart);
                setEndPositionRoute(initialRoutesToFetchValueEnd);
                setInitialListSorted(true)
                setShouldFilterRoutes(true)
                setShouldBeLoading(false)
                console.log(data.routes.length)
            });
           
        }
    
        // Call the fetchData function
        if (clickedStatus[1] === true && clickedStatus[2] === false && currentGym) {
            fetchData();
            setInitialDataFetched(true)
        }
      
    }, 
    [clickedStatus, currentGym
        
    ]);


    

    useEffect(() => {
        // Function to call when any state changes
        const fetchData = () => {
            
            getRouteSetterInfo(
                user,
                initialRoutesToFetchValueStart,
                initialRoutesToFetchValueEnd,
                sortGyms,
                sortSectors,
                sortDifficulties,
                sortLevels,
                sortStatus,
                sortName,
                sortDate,
                filteredGyms,
                filteredDifficulties,
                filteredLevels,
                filteredTagsSteepness,
                filteredTagsGrips,
                filteredTagsStyle,
                filteredStatus,
                filteredSectors
            ).then(data => {
                setRoutesInfo({
                    routes: [...data.routes],
                    count : data.count
                });
                setDisableClick(false)
                setStarPositionRoute(initialRoutesToFetchValueStart);
                setEndPositionRoute(initialRoutesToFetchValueEnd);
              
                setTotalRoutesCount(data.count);
                
            });
            
        }
    
        // Call the fetchData function
        if (initialListSorted && clickedStatus[2]===true) {
            fetchData();
          
        } else if (initialListSorted ||initialDataFetched) {
           
            // Local filtering logic
            let filteredRoutes =[]
            if (activeRoutesInfo && activeRoutesInfo.routes) {
                
            let filteredRoutes = activeRoutesInfo.routes.filter(route => {
                const matchesDifficulty = !Object.keys(clickedDifficulties).length || Object.keys(clickedDifficulties).some(difficultyId => clickedDifficulties[difficultyId] && route.difficultyGymSystem.id === parseInt(difficultyId));
                
                const matchesTagsSteepness = !Object.keys(clickedTagsSteepness).length || Object.keys(clickedTagsSteepness).some(tagId => clickedTagsSteepness[tagId] && route.tagSteepness.id === parseInt(tagId));
                
                const matchesTagsGrips = !Object.keys(clickedTagsGrips).length || Object.keys(clickedTagsGrips).some(tagId => clickedTagsGrips[tagId] && route.tagGrips.id === parseInt(tagId));
                
                const matchesTagsStyle = !Object.keys(clickedTagsStyle).length || Object.keys(clickedTagsStyle).some(tagId => clickedTagsStyle[tagId] && route.tagStyle.id === parseInt(tagId)  );
                
                const matchesLevels = !Object.keys(clickedLevels).length || Object.keys(clickedLevels).some(levelId => clickedLevels[levelId] && (route.level + 2 ) === parseInt(levelId));
                
                const matchesSectors = !Object.keys(clickedSectors).length || Object.keys(clickedSectors).some(sectorId => clickedSectors[sectorId] && route.sector.id === parseInt(sectorId));

                
                console.log('servusss',matchesDifficulty,matchesLevels,matchesSectors,matchesTagsGrips,matchesTagsSteepness,matchesTagsStyle)
                // Return true if any criteria match
                return matchesDifficulty && matchesTagsSteepness && matchesTagsGrips && matchesTagsStyle && matchesLevels && matchesSectors;


            });
             // Apply sorting logic
             if (sortDifficulties) {
                filteredRoutes = filteredRoutes.sort((a, b) => sortDifficulties === 1 ? a.difficultyGymSystem.id - b.difficultyGymSystem.id : b.difficultyGymSystem.id - a.difficultyGymSystem.id);
            }

            if (sortSectors) {
                filteredRoutes = filteredRoutes.sort((a, b) => sortSectors === 1 ? a.sector.name.localeCompare(b.sector.name) : b.sector.name.localeCompare(a.sector.name));
            }

            if (sortName) {
                filteredRoutes = filteredRoutes.sort((a, b) => sortName === 1 ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name));
            }

            if (sortLevels) {
                filteredRoutes = filteredRoutes.sort((a, b) => sortLevels === 1 ? (a.difficultyGrading - a.difficultyGymSystem.grade + 2) - (b.difficultyGrading - b.difficultyGymSystem.grade + 2) : (b.difficultyGrading - b.difficultyGymSystem.grade + 2) - (a.difficultyGrading - a.difficultyGymSystem.grade + 2));
            }

            if (sortDate) {
                filteredRoutes = filteredRoutes.sort((a, b) => sortDate === 1 ? new Date(a.openingDate).getTime() - new Date(b.openingDate).getTime() : new Date(b.openingDate).getTime() - new Date(a.openingDate).getTime());
            }

            setRoutesFiltered({
                routes: filteredRoutes,
                count: filteredRoutes.length
            });
        }
        else{
            filteredRoutes=[]
            setRoutesFiltered({
                routes:filteredRoutes,
                count :0
            })
        }
            setShouldFilterRoutes(false)
            setDisableClick(false)
        }
        
        }, [
        // Dependencies: list of states to observe
        sortGyms,
        sortSectors,
        sortDifficulties,
        sortLevels,
        sortStatus,
        sortName,
        sortDate,
        filteredGyms,
        filteredTagsSteepness,
        filteredTagsGrips,
        filteredTagsStyle,
        filteredStatus,
        filteredDifficulties,
        filteredLevels,
        filteredSectors,
        initialListSorted,
        clickedDifficulties,clickedLevels,clickedSectors,clickedTagsGrips,clickedTagsSteepness,clickedTagsStyle,clickedStatus,
        initialDataFetched,
        
        
    ]);

   

   

    const handleGymClick = (gymId) => ( setDisableClick(true), handleItemClick(gymId, gymsFilterInfo, clickedGyms, setClickedGyms, checkGymAllFilters, setCheckGymAllFilters, setFilteredGyms, setRoutesInfo,clickedStatus[2] === true ? true : false));
    const handleCheckGymsToggle = (status) => ( setDisableClick(true),handleCheckToggle(status, gymsFilterInfo, setClickedGyms, checkGymAllFilters, setCheckGymAllFilters, setFilteredGyms, setRoutesInfo,clickedStatus[2] === true ? true : false));
    
    const handleDifficultyClick = (difficultyId) => ( setDisableClick(true),handleItemClick(difficultyId,difficultyList,clickedDifficulties,setClickedDifficulties,checkDifficultyAllFilters,setCheckDifficultyAllFilters,setFilteredDifficulties,setRoutesInfo,clickedStatus[2] === true ? true : false))
    const handleCheckDifficultyToggle = (status) => ( setDisableClick(true),handleCheckToggle(status,difficultyList,setClickedDifficulties,checkDifficultyAllFilters,setCheckDifficultyAllFilters,setFilteredDifficulties,setRoutesInfo,clickedStatus[2] === true ? true : false))

    const handleSectorClick = (sectorId) => ( setDisableClick(true),handleItemClick(sectorId,sectorsList,clickedSectors, setClickedSectors,checkSectorsAllFilters,setCheckSectorsAllFilters,setFilteredSectors,setRoutesInfo,clickedStatus[2] === true ? true : false))
    const handleCheckSectorsToggle = (status) => ( setDisableClick(true),handleCheckToggle(status,sectorsList,setClickedSectors,checkSectorsAllFilters,setCheckSectorsAllFilters,setFilteredSectors,setRoutesInfo,clickedStatus[2] === true ? true : false))

    const handleTagSteepnessClick = (tagId) => ( setDisableClick(true),handleItemClick(tagId,tagsList.tagSteepness,clickedTagsSteepness,setClickedTagsSteepness,checkTagsInclinaisonAllFilters,setCheckTagsInclinaisonAllFilters,setFilteredTagsSteepness,setRoutesInfo,clickedStatus[2] === true ? true : false))
    const handleCheckSteepnessToggle = (status) => ( setDisableClick(true),handleCheckToggle(status, tagsList.tagSteepness,setClickedTagsSteepness,checkTagsInclinaisonAllFilters,setCheckTagsInclinaisonAllFilters,setFilteredTagsSteepness,setRoutesInfo,clickedStatus[2] === true ? true : false))

    const handleTagGripsClick = (tagId) => ( setDisableClick(true),handleItemClick(tagId,tagsList.tagGrips,clickedTagsGrips,setClickedTagsGrips,checkTagsPrisesAllFilters,setCheckTagsPrisesAllFilters,setFilteredTagsGrips,setRoutesInfo,clickedStatus[2] === true ? true : false))
    const handleCheckGripsToggle = (status) => ( setDisableClick(true),handleCheckToggle(status, tagsList.tagGrips, setClickedTagsGrips, checkTagsPrisesAllFilters,setCheckTagsPrisesAllFilters,setFilteredTagsGrips,setRoutesInfo,clickedStatus[2] === true ? true : false))

    const handleTagStyleClick = (tagId) =>( setDisableClick(true), handleItemClick(tagId,tagsList.tagStyle,clickedTagsStyle,setClickedTagsStyle,checkTagsMovementAllFilters,setCheckTagsMovementAllFilters,setFilteredTagsStyle,setRoutesInfo,clickedStatus[2] === true ? true : false))
    const handleCheckStyleToggle = (status) =>( setDisableClick(true), handleCheckToggle (status, tagsList.tagStyle, setClickedTagsStyle, checkTagsMovementAllFilters,setCheckTagsMovementAllFilters,setFilteredTagsStyle,setRoutesInfo,clickedStatus[2] === true ? true : false))

    const handleStatusClick = (statusId) => ( setDisableClick(true),handleItemClick (statusId, statusList, clickedStatus,setClickedStatus,checkStatusAllFilters,setCheckStatusAllFilters,setFilteredStatus,setRoutesInfo,clickedStatus[2] === true ? true : false))
    const handleCheckStatusToggle = (status) =>( setDisableClick(true),handleCheckToggle (status, statusList, setClickedStatus,checkStatusAllFilters,setCheckStatusAllFilters,setFilteredStatus,setRoutesInfo,clickedStatus[2] === true ? true : false))

    const handleLevelClick = (levelId) =>( setDisableClick(true), handleItemClick (levelId, levelList, clickedLevels, setClickedLevels,checkLevelAllFilters,setCheckLevelAllFilters,setFilteredLevels,setRoutesInfo,clickedStatus[2] === true ? true : false))
    const handleCheckLevelToggle = (status) => ( setDisableClick(true),handleCheckToggle (status, levelList, setClickedLevels, checkLevelAllFilters, setCheckLevelAllFilters,setFilteredLevels, setRoutesInfo,clickedStatus[2] === true ? true : false))

    
    const handleGymSorting = () => { setDisableClick(true) ; handleSorting(sortGyms, setSortGyms, sortSetters) ;}
    const handleStatusSorting = () => {setDisableClick(true); handleSorting(sortStatus, setSortStatus, sortSetters);}
    const handleDifficultySorting = () => {setDisableClick(true); handleSorting(sortDifficulties,setSortDifficulties,sortSetters)}
    const handleSectorSorting = () => {setDisableClick(true) ; handleSorting(sortSectors,setSortSectors,sortSetters)}
    const handleNameSorting = () => {setDisableClick(true); handleSorting(sortName,setSortName,sortSetters)}
    const handleLevelSorting = () => {setDisableClick(true) ; handleSorting(sortLevels,setSortLevels,sortSetters)}
    const handleDateSorting = () => {setDisableClick(true) ; handleSorting(sortDate,setSortDate,sortSetters)}
    const handleColorSorting = () => {setDisableClick(true) ; handleSorting(sortColor,setSortColor,sortSetters)}

    


    useEffect(() => {
        if (user && currentGym) {
            getRouteSetterGymFilterInfoSpecific(user, currentGym.id).then(data => {
                setGymsFilterInfo(data)
                const allDifficulties = data.flatMap(gym => gym.difficultySystem);
                setDifficultyList(allDifficulties);
                const allSectors = data.flatMap(gym => gym.sectors);
                setSectorsList(allSectors);
                const difficultySystem = data[0].hasCustomDifficultySystem
                setAdditionnalDifficultySystem(difficultySystem)
                const allSummaryDifficulty = data.flatMap(gym => gym.summaryDifficulty);
                setDifficultySummary(allSummaryDifficulty);
               

               
            });
            
        } else {
            // Handle the case where user is not provided
            console.error("user not provided");
        }
    }, [user,currentGym]);

    

    useEffect(() => {
        const fetchTags = async () => {
            const tags = await getTagListInfo();
            setTagsList(tags);
        };
        
        fetchTags();
    }, []);

    useEffect(() => {
        if (user) {
            getRouteSetterUserInfo(user).then(data => {
                setRouteSetterInfo(data);
            });
            
        } else {
            // Handle the case where user is not provided
            console.error("user not provided");
        }
    }, [user]);

    useEffect(() => {
        if (gymsFilterInfo) {
            const initialGymState = gymsFilterInfo.reduce((acc, gym) => {
                acc[gym.id] = true;
                return acc;
            }, {});
            setClickedGyms(initialGymState);
        }
    }, [gymsFilterInfo]);

    useEffect(() => {
        if (statusList && statusList.length === 2) {
            const initialStatusState = {
                [statusList[0].id]: true,
                [statusList[1].id]: false
            };
            setClickedStatus(initialStatusState);
        }
    }, [gymsFilterInfo]);

    useEffect(() => {
        if (levelList) {
            const initialStatusState = levelList.reduce((acc, obj) => {
                acc[obj.id] = true;
                return acc;
            }, {});
            setClickedLevels(initialStatusState);
        }
    }, []);

    useEffect(() => {
        if (difficultyList) {
            const initialDifficultyState = difficultyList.reduce((acc, obj) => {
                acc[obj.id] = true;
                return acc;
            }, {});
            setClickedDifficulties(initialDifficultyState);
        }
    }, [difficultyList]);

    useEffect(() => {
        if (sectorsList) {
            const initialSectorsState = sectorsList.reduce((acc, obj) => {
                acc[obj.id] = true;
                return acc;
            }, {});
            setClickedSectors(initialSectorsState);
        }
    }, [sectorsList]);

    useEffect(() => {
        if (tagsList) {
            const initSteepness = {};
            tagsList.tagSteepness.forEach(tag => {
                initSteepness[tag.id] = true;
            });
    
            const initGrips = {};
            tagsList.tagGrips.forEach(tag => {
                initGrips[tag.id] = true;
            });
    
            const initStyle = {};
            tagsList.tagStyle.forEach(tag => {
                initStyle[tag.id] = true;
            });
    
            setClickedTagsSteepness(initSteepness);
            setClickedTagsGrips(initGrips);
            setClickedTagsStyle(initStyle);
        }
    }, [tagsList]);
    
    
    

    return (
    <div className='outerContainer'>
       <NavBarBackOffice
       profilePicture={routeSetterInfo?.picture}
        firstName={routeSetterInfo?.firstName}
        lastName={routeSetterInfo?.lastName}
        gyms={currentGym && currentGym.name}
        difficultySummary={difficultySummary}
        totalRoutesCount={(clickedStatus[2] === true ? true : false && routesFiltered) ? routesFiltered.count : totalRoutesCount}
        difficultySystem={hasAdditionnalDifficultySystem}
       />
       <div className="bo-main-content">
            {shouldBeLoading && (
                <div className="loading-overlay">
                <TailSpin color="#0099CC" height={80} width={80} />
                </div>
            )}
            <div className="bo-main-content__title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6823 8.92495L16.556 4.60572C16.4549 4.53126 16.3286 4.48157 16.177 4.5065L6.34661 4.53126C6.16963 4.53126 5.99283 4.60572 5.86642 4.72988L0.231021 9.94285C0.0288315 10.1167 -0.0469677 10.3896 0.0288313 10.6626L2.6065 19.0034C2.70751 19.3012 2.98549 19.4998 3.28886 19.4998L19.3358 19.5C19.6138 19.5 19.8918 19.3261 19.9928 19.078L23.935 9.79397C24.0868 9.47121 23.9604 9.12368 23.6824 8.92505L23.6823 8.92495ZM8.29239 13.3436L16.4043 12.9216L18.3249 18.1097H4.70389L8.29239 13.3436ZM17.7942 12.5741L21.9638 10.8365L19.3863 16.8934L17.7942 12.5741ZM21.7618 9.4216L16.9857 11.4075L8.67154 5.92151L15.9748 5.89675L21.7618 9.4216ZM6.42244 6.12005L14.7365 11.6061L8.0145 11.9536L2.10113 10.1415L6.42244 6.12005ZM1.79788 11.5068L6.77623 13.021L3.56679 17.2658L1.79788 11.5068Z" fill="#1D5073"/>
                </svg>

                    <div className="bold large3" >
                        <p>Détail blocs</p>
                    </div>
                    {/* user == '88545ed0-4c55-4325-a008-bef0c6a48a21' &&
                        <CustomButton 
                            customStyle='login_button'
                            text='Classement Soirée'
                            onClickProp={()=>navigate(`/soiree-gaston-corti/`, { state: { user: user } })}
                        />
                    */}
            </div>

            {
                /*
                <div className="pannels-filter__pannel-selection">
                    <div className="large2 bold pannel-selection__library">
                        Bibliothèque
                    </div>
                    <div className="large2 light pannel-selection_route-setting">
                        Suivi ouvertures
                    </div>
                </div>
                */
            }
            <div className="pannels-filter__filters" style={!additionnalDifficultySystem ?{ width:'100%'} : {}}>
                {!areFiltersOpened &&
                    <div className='filter-header'>
                    <div className='filter-toggle bold large2'>
                        <CustomButton 
                            text='Filtres' 
                            onClickProp={()=>setAreFiltersOpened(true)} 
                        />
                        <div className='filter-toggle bold lower2' style={{gap:'8px', paddingLeft:'12px',paddingRight:'12px'}}>
                            {!checkDifficultyAllFilters &&
                                <CustomButton 
                                    text='Difficultés'
                                    customStyle='filter-card bold lower2'
                                    onClickProp={()=>{
                                        if (!disableClick) {
                                            handleCheckDifficultyToggle(!checkDifficultyAllFilters)
                                        }}}
                                />
                                
                            }
                            {!checkLevelAllFilters &&
                                <CustomButton 
                                    text='Niveau'
                                    customStyle='filter-card bold lower2'
                                    onClickProp={()=>{
                                        if (!disableClick) {
                                            handleCheckLevelToggle(!checkLevelAllFilters)
                                        }}}
                                />
                                
                            }
                            {!checkSectorsAllFilters &&
                                <CustomButton 
                                    text='Secteurs'
                                    customStyle='filter-card bold lower2'
                                    onClickProp={()=>{
                                        if (!disableClick) {
                                            handleCheckSectorsToggle(!checkSectorsAllFilters)
                                        }}}
                                />
                                
                            }
                            {!checkTagsInclinaisonAllFilters &&
                                <CustomButton 
                                    text='Tags : inclinaison'
                                    customStyle='filter-card bold lower2'
                                    onClickProp={()=>{
                                        if (!disableClick) {
                                            handleCheckSteepnessToggle(!checkTagsInclinaisonAllFilters)
                                        }}}
                                />
                                
                            }
                            {!checkTagsPrisesAllFilters &&
                                <CustomButton 
                                    text='Tags : prises'
                                    customStyle='filter-card bold lower2'
                                    onClickProp={()=>{
                                        if (!disableClick) {
                                            handleCheckGripsToggle(!checkTagsPrisesAllFilters)
                                        }}}
                                />
                                
                            }
                            {!checkTagsMovementAllFilters &&
                                <CustomButton 
                                    text='Tags : style'
                                    customStyle='filter-card bold lower2'
                                    onClickProp={()=>{
                                        if (!disableClick) {
                                            handleCheckStyleToggle(!checkTagsMovementAllFilters)
                                        }}}
                                />
                                
                            }
                            {!checkStatusAllFilters &&
                                <CustomButton 
                                    text='Statuts'
                                    customStyle='filter-card bold lower2'
                                    onClickProp={()=>{
                                        if (!disableClick) {
                                            handleCheckStatusToggle(!checkStatusAllFilters)
                                        }}}
                                />
                                
                            }
                        </div>
                        <CustomButton 
                            Svg={SVGs.bigChevronDown}
                            onClickProp={()=>setAreFiltersOpened(true)}
                        />
                    </div>

                </div>
                }
                {areFiltersOpened &&
                <>
                    <div className='filter-header'>
                        <div className='filter-toggle bold large2'>
                            <CustomButton 
                                text='Filtres' 
                                onClickProp={()=>setAreFiltersOpened(false)} 
                            />
                            <div className='filter-toggle bold lower2' style={{gap:'8px', paddingLeft:'12px',paddingRight:'12px'}}>
                                {!checkDifficultyAllFilters &&
                                    <CustomButton 
                                        text='Difficultés'
                                        customStyle='filter-card bold lower2'
                                        onClickProp={()=>{
                                            if (!disableClick) {
                                                handleCheckDifficultyToggle(!checkDifficultyAllFilters)
                                            }}}
                                    />
                                    
                                }
                                {!checkLevelAllFilters &&
                                    <CustomButton 
                                        text='Niveau'
                                        customStyle='filter-card bold lower2'
                                        onClickProp={()=>{
                                            if (!disableClick) {
                                                handleCheckLevelToggle(!checkLevelAllFilters)
                                            }}}
                                    />
                                    
                                }
                                {!checkSectorsAllFilters &&
                                    <CustomButton 
                                        text='Secteurs'
                                        customStyle='filter-card bold lower2'
                                        onClickProp={()=>{
                                            if (!disableClick) {
                                                handleCheckSectorsToggle(!checkSectorsAllFilters)
                                            }}}
                                    />
                                    
                                }
                                {!checkTagsInclinaisonAllFilters &&
                                    <CustomButton 
                                        text='Tags : inclinaison'
                                        customStyle='filter-card bold lower2'
                                        onClickProp={()=>{
                                            if (!disableClick) {
                                                handleCheckSteepnessToggle(!checkTagsInclinaisonAllFilters)
                                            }}}
                                    />
                                    
                                }
                                {!checkTagsPrisesAllFilters &&
                                    <CustomButton 
                                        text='Tags : prises'
                                        customStyle='filter-card bold lower2'
                                        onClickProp={()=>{
                                            if (!disableClick) {
                                                handleCheckGripsToggle(!checkTagsPrisesAllFilters)
                                            }}}
                                    />
                                    
                                }
                                {!checkTagsMovementAllFilters &&
                                    <CustomButton 
                                        text='Tags : style'
                                        customStyle='filter-card bold lower2'
                                        onClickProp={()=>{
                                            if (!disableClick) {
                                                handleCheckStyleToggle(!checkTagsMovementAllFilters)
                                            }}}
                                    />
                                    
                                }
                                {!checkStatusAllFilters &&
                                    <CustomButton 
                                        text='Statuts'
                                        customStyle='filter-card bold lower2'
                                        onClickProp={()=>{
                                            if (!disableClick) {
                                                handleCheckStatusToggle(!checkStatusAllFilters)
                                            }}}
                                    />
                                    
                                }
                            </div>
                            <CustomButton 
                                Svg={SVGs.bigChevronUp}
                                onClickProp={()=>setAreFiltersOpened(false)}
                            />
                        </div>

                    </div>
                    <div style={{display:'flex', flexDirection:'row', gap:'16px'}}>
                        
                        <div className='filter-selection-basic-for-2-filters'>
                            <div className="filter-selection-basic__difficulty">
                                <div className="light large">
                                    <h4 className="light normal">Difficulté</h4>
                                </div>
                                <div className="filter-selection__all-colors">
                                    <div className="all-filter" >
                                        <CheckMarkButton 
                                            isChecked={checkDifficultyAllFilters}
                                            text={'Tout'}
                                            onToggle={()=>{
                                                if (!disableClick) {
                                                    handleCheckDifficultyToggle(!checkDifficultyAllFilters)
                                                }}}
                                            customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                        />
                                    </div>
                                    <div className="boulders-color" data-category="color">      
                                    {
                                        gymsFilterInfo && gymsFilterInfo.length > 0 
                                        && gymsFilterInfo.flatMap(gym => 
                                            gym.difficultySystem.map(difficulty => (
                                                <DifficultySvg
                                                    key={difficulty.id}
                                                    isClicked={!!clickedDifficulties[difficulty.id]}
                                                    text={difficulty.id.toString()}
                                                    onClickProp={() =>{
                                                        if (!disableClick) {
                                                            handleDifficultyClick(difficulty.id)
                                                        }}}
                                                    svg={difficulty.svg}
                                                    hasDifficultySystem={additionnalDifficultySystem}
                                                    customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                                />
                                            ))
                                        )
                                        
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="filter-selection-basic-2">
                                <div className='title-checkmark'>
                                    <div className="light large">
                                    <h4 className="light normal">Niveau</h4>
                                    </div>
                                    <div className="all-filter" >
                                        <CheckMarkButton 
                                            isChecked={checkLevelAllFilters}
                                            text={'Tout'}
                                            onToggle={()=>{
                                                if (!disableClick) {
                                                    handleCheckLevelToggle(!checkLevelAllFilters)
                                                }}}
                                            customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                        />
                                        </div>
                                </div>
                                <div className={`levels-display ${disableClick ? 'bo-opacity' : 'selection-active'}`} data-category="level">      
                                {
                                    levelList.map(level => (
                                        <LevelSelector
                                            key={level.id}
                                            isClicked={!!clickedLevels[level.id]}
                                            text={level.id.toString()}
                                            onClickProp={() =>{
                                                if (!disableClick) {
                                                    handleLevelClick(level.id)
                                                }}}
                                            shouldShowFigure={true}
                                            customSize={[28,20]}
                                            
                                        />
                                    ))  
                                }
                                </div>   
                            </div>
                        </div>
                        <div className="filter-selection-extended"> 
                            <div className="light large">
                            <h4 className="light normal">Tags</h4>
                            </div>
                            <div className="filter-selection-extended__all-columns">
                                <div className="filter-selection-extended__column">
                                    <div className="lower2 bold">
                                        Inclinaison
                                    </div>
                                    <div className="all-filter" >
                                        <CheckMarkButton 
                                            isChecked={checkTagsInclinaisonAllFilters}
                                            text={'Tout'}
                                            onToggle={()=>{
                                                if (!disableClick) {
                                                    handleCheckSteepnessToggle(!checkTagsInclinaisonAllFilters)
                                                }}}
                                            customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                        />
                                    
                                    </div>
                                    <div className="vertical-toggles" data-category="steepness">
                                    {
                                        tagsList?.tagSteepness?.length > 0 
                                        && tagsList.tagSteepness.map(tag => (
                                            <CustomButtonBO 
                                                key={tag.id}
                                                isClicked={!!clickedTagsSteepness[tag.id]}
                                                text={tag.name}
                                                onClickProp={() =>{
                                                    if (!disableClick) {
                                                        handleTagSteepnessClick(tag.id)
                                                    }}}
                                                customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                            />
                                        ))
                                        
                                    }
                                    </div>
                                </div>
                                <div className="filter-selection-extended__column" >
                                    <div className="lower2 bold">
                                        Prises
                                    </div>
                                    <div className="all-filter">
                                        <CheckMarkButton 
                                            isChecked={checkTagsPrisesAllFilters}
                                            text={'Tout'}
                                            onToggle={()=>{
                                                if (!disableClick) {
                                                    handleCheckGripsToggle(!checkTagsPrisesAllFilters)
                                            }}}
                                            customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                        />
                                    </div>
                                    <div className="vertical-toggles" data-category="holds">
                                    {
                                        tagsList?.tagGrips?.length > 0 
                                        && tagsList.tagGrips.map(tag => (
                                            <CustomButtonBO 
                                                key={tag.id}
                                                isClicked={!!clickedTagsGrips[tag.id]}
                                                text={tag.name}
                                                onClickProp={() =>{
                                                    if (!disableClick) {
                                                        handleTagGripsClick(tag.id)
                                                    }}}
                                                customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                            />
                                        ))
                                    
                                    }
                                    </div>
                                </div>
                                <div className="filter-selection-extended__column">
                                    <div className="lower2 bold">
                                        Mouvement
                                    </div>
                                    <div className="all-filter" >
                                        <CheckMarkButton 
                                            isChecked={checkTagsMovementAllFilters}
                                            text={'Tout'}
                                            onToggle={()=>{
                                                if (!disableClick) {
                                                    handleCheckStyleToggle(!checkTagsMovementAllFilters)
                                                }}}
                                            customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                        />
                                    </div>
                                    <div className="vertical-toggles" data-category="movement">
                                    {
                                        tagsList?.tagStyle?.length > 0 
                                        && tagsList.tagStyle.map(tag => (
                                            <CustomButtonBO 
                                                key={tag.id}
                                                isClicked={!!clickedTagsStyle[tag.id]}
                                                text={tag.name}
                                                onClickProp={() =>{
                                                    if (!disableClick) {
                                                        handleTagStyleClick(tag.id)
                                                    }}}
                                                customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                            />
                                        ))
                                    
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="filter-selection-basic__sector">
                            <div className="light large">
                            <h4 className="light normal">Secteurs</h4>
                            </div>
                            <div className="filter-selection__all-toggles__sector">
                                <div className="all-filter" >
                                    <CheckMarkButton 
                                        isChecked={checkSectorsAllFilters}
                                        text={'Tout'}
                                        onToggle={()=>{
                                            if (!disableClick) {
                                                handleCheckSectorsToggle(!checkSectorsAllFilters)
                                            }}}
                                        customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                    />
                                </div>
                                <div className="vertical-toggles__sector" data-category="sectors">
                                    { 
                                        sectorsList.map(obj => (
                                            <CustomButtonBO 
                                                key={obj.id}
                                                isClicked={!!clickedSectors[obj.id]}
                                                text={obj.name}
                                                onClickProp={() => {
                                                    if (!disableClick) {
                                                        handleSectorClick(obj.id)
                                                    }}}
                                                customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='filter-selection-basic-for-2-filters'>
                            {/*
                            <div className="filter-selection-basic__gym">
                                <div className="light large">
                                    <h4>Mes salles</h4> 
                                </div>
                                <div className={`filter-selection__all-toggles`}>
                                    <CheckMarkButton 
                                        isChecked={checkGymAllFilters}
                                        text={'Tout'}
                                        onToggle={()=>{
                                            if (!disableClick) {
                                            handleCheckGymsToggle(!checkGymAllFilters)
                                        }}}
                                        customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                    />
                                    <div className="vertical-toggles" data-category="gym">
                                    {
                                        gymsFilterInfo.length > 0 
                                        ? gymsFilterInfo.map(gym => (
                                            <CustomButtonBO 
                                                key={gym.id}
                                                isClicked={!!clickedGyms[gym.id]}
                                                text={gym.name}
                                                onClickProp={() =>{
                                                    if (!disableClick) {
                                                        handleGymClick(gym.id)
                                                    }}}
                                                customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                            />
                                        ))
                                        : <div className="bold normal">No gyms found for this route setter.</div>
                                    }
                                    </div>
                                </div>
                            </div>
                            */}
                            <div className="filter-selection-basic-2">
                                <div className='title-checkmark'>
                                    <div className="light large">
                                    <h4 className="light normal">Statuts</h4>
                                    </div>
                                    <div className="all-filter" >
                                        <CheckMarkButton 
                                            isChecked={checkStatusAllFilters}
                                            text={'Tout'}
                                            onToggle={()=>{
                                                if (!disableClick) {
                                                    handleCheckStatusToggle(!checkStatusAllFilters)
                                                }
                                            }}
                                            customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                        />
                                    </div>
                                </div>
                                <div className={`filter-selection__all-toggles`}>
                                    <div className="vertical-toggles" data-category="status">
                                        { statusList.map(obj => (
                                                <CustomButtonBO 
                                                    key={obj.id}
                                                    isClicked={!!clickedStatus[obj.id]}
                                                    text={obj.status}
                                                    onClickProp={() => {
                                                        if (!disableClick) {
                                                            handleStatusClick(obj.id)
                                                          
                                                        }}}
                                                    customStyle={`${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
            </div>
            <div className='pannels-filter__filters' style={{gap:0, marginTop:'12px'}}>
                {/* */}
                <div className='filter-toggle bold large2'>{clickedStatus[2] === true ? totalRoutesCount : (routesFiltered ? routesFiltered.count : 0)} blocs à parcourir</div>
                <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Clique sur un bloc pour accéder à ses statistiques</div>
                <div className={areFiltersOpened ? "main-content__boulders-library" : "main-content__boulders-library-filters-closed"}>
                    <div className={`boulders-library__table-header` }>
                        {/*
                            <CustomButtonSort 
                                text='Salle'
                                Svg={sortGyms == 0 ? null : sortGyms == 1 ? SVGs.chevronDown : SVGs.chevronUp}
                                customStyle={`table-header__gyms large bold  ${disableClick ? 'bo-opacity' : 'selection-active'}`}
                                onClickProp={() => {
                                    if (!disableClick) {
                                    handleGymSorting()
                                    }
                                }}
                            />
                        */}
                        <CustomButtonSort 
                            text='Nom'
                            Svg={sortName == 0 ? null : sortName == 1 ? SVGs.chevronDown : SVGs.chevronUp}
                            customStyle={additionnalDifficultySystem ? `table-header__boulders lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`:`table-header__boulders2 lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`}
                            onClickProp={() =>{
                                if (!disableClick) {
                                handleNameSorting()
                                }
                            }}
                        />  
                        <CustomButtonSort 
                            text='Secteur'
                            Svg={sortSectors == 0 ? null : sortSectors == 1 ? SVGs.chevronDown : SVGs.chevronUp}
                            customStyle={`table-header__sectors lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`}
                            onClickProp={() =>{
                                if (!disableClick) {
                                handleSectorSorting()
                                }
                            }}
                        />   
                        
                        {additionnalDifficultySystem &&
                            <div className={`table-header__colors1 lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`} >
                                Couleur
                            </div>
                        }   
                        <CustomButtonSort 
                            text='Difficulté'
                            Svg={sortDifficulties == 0 ? null : sortDifficulties == 1 ? SVGs.chevronDown : SVGs.chevronUp}
                            customStyle={additionnalDifficultySystem ? `table-header__colors lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}` : `table-header__colors2 lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`}
                            onClickProp={() =>{
                                if (!disableClick) {
                                handleDifficultySorting()
                                }
                            }}
                        />
                        <CustomButtonSort 
                            text='Niveau'
                            Svg={sortLevels == 0 ? null : sortLevels == 1 ? SVGs.chevronDown : SVGs.chevronUp}
                            customStyle={additionnalDifficultySystem ? `table-header__difficulty lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`: `table-header__difficulty2 lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`}
                            onClickProp={() =>{
                                if (!disableClick) {
                                handleLevelSorting()
                                }
                            }}
                        />
                    <CustomButtonSort 
                            text='Ouverture'
                            Svg={sortDate == 0 ? null : sortDate == 1 ? SVGs.chevronDown : SVGs.chevronUp}
                            customStyle={`table-header__setting lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`}
                            onClickProp={() =>{
                                if (!disableClick) {
                                handleDateSorting()
                                }
                            }}
                        />
                        <div className={additionnalDifficultySystem ? `table-header__tags lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}` : `table-header__tags2 lower bold ${disableClick ? 'bo-opacity' : 'selection-active'}`} data-sorted="-">
                            Tags
                        </div>
                        <div className={`table-header__status-info ${disableClick ? 'bo-opacity' : 'selection-active'}`} data-sorted="-">
                            <div className="lower bold">
                                Statut
                            </div>
                            {/*
                            <div className="info">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="#1D5073"/>
                                    <path d="M8.9307 6.58789L6.63969 6.875L6.55766 7.25586L7.00883 7.33789C7.3018 7.4082 7.36039 7.51367 7.29594 7.80664L6.55766 11.2754C6.3643 12.1719 6.66313 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3418 8.83109 11.9961L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6328 7.92875 11.293L8.9307 6.58789Z" fill="#1D5073"/>
                                    <path d="M9 4.5C9 5.05228 8.55229 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5Z" fill="#1D5073"/>
                                </svg>      
                            </div>
                            */}
                        </div>
                        <div className='table-header__empty-picture' ></div>
                    </div>
                    <div className="boulders_library__table-body" style={disableClick ? {opacity:0.3}:{opacity:1}}>
                        {  (clickedStatus[1] === true && clickedStatus[2] ===  false) && routesFiltered && routesFiltered.routes ?(
                            <>
                            {routesFiltered.routes.map(route => (
                                <RouteRow
                                key={route.id}
                                route={route}
                                difficultySystem={additionnalDifficultySystem}
                                onClickProp={() => setAreFiltersOpened(false)}
                                />
                            ))}
                            {disableClick && (
                                <div className="loading-overlay">
                                <TailSpin color="#0099CC" height={80} width={80} />
                                </div>
                            )}
                            </>
                            )
                            :
                            routesInfo && routesInfo.routes ? (
                                <>
                                {routesInfo.routes.map(route => (
                                    <RouteRow
                                    key={route.id}
                                    route={route}
                                    difficultySystem={additionnalDifficultySystem}
                                    onClickProp={() => setAreFiltersOpened(false)}
                                    />
                                ))}
                                {disableClick && (
                                    <div className="loading-overlay">
                                    <TailSpin color="#0099CC" height={80} width={80} />
                                    </div>
                                )}
                                </>
                            )
                            :
                            (!checkStatusAllFilters && clickedStatus[1]===false) ?
                                <div className='no-routes-msg light large2'>
                                    <div>Les filtres sélectionnés ne correspondent à aucun bloc</div>
                                </div>
                            :
                            ((filteredGyms[0] == 0 && checkGymAllFilters == true) && (filteredTagsSteepness[0] == 0 && checkTagsInclinaisonAllFilters == true) && (filteredTagsGrips[0] == 0  && checkTagsPrisesAllFilters == true) && (filteredTagsStyle[0] == 0 && checkTagsMovementAllFilters == true ) && (filteredDifficulties[0] == 0 && checkDifficultyAllFilters) && (filteredLevels[0] == 0 && checkLevelAllFilters) && (filteredSectors[0] == 0 &&  checkSectorsAllFilters) ) ?
                            
                            <div className='no-routes-msg light large2'>
                                <div>Blocs en cours de chargement</div>
                            </div>
                            
                            :startPositionRoute == 0 && (!checkGymAllFilters || !checkTagsInclinaisonAllFilters  || !checkTagsPrisesAllFilters  || !checkTagsMovementAllFilters || !checkDifficultyAllFilters || !checkLevelAllFilters || !checkSectorsAllFilters ) &&
                            
                            <div className='no-routes-msg light large2'>
                                <div>Les filtres sélectionnés ne correspondent à aucun bloc</div>
                            </div>

                        }
                        <div className='more-detail-button-242px__container' >
                        {
                            (!initialListSorted)?
                            <div className='no-routes-msg light large2'>
                                <div>Blocs en cours de chargement</div>
                            </div>
                            :
                        (routesInfo && routesInfo.count == 0 || routesFiltered && routesFiltered.count ==0)?
                            <div className='no-routes-msg light large2'>
                                <div>Les filtres sélectionnés ne correspondent à aucun bloc</div>
                            </div>
                            :
                            clickedStatus[2] === false ? 
                            <></>
                            :routesInfo && routesInfo.count > endPositionRoute ?
                            <CustomButtonBO 
                                text={`Charger ${adder} blocs supplémentaires`}
                                onClickProp={loadMoreRoutes}
                                customStyle={'more-detail-button-242px'}
                            />
                            : routesInfo && routesInfo.count < endPositionRoute+adder ?

                                <div></div>
                            
                            :  routesInfo && routesInfo.count &&
                            <CustomButtonBO 
                            text={`Charger ${adder} blocs supplémentaires`}
                            onClickProp={loadMoreRoutes}
                            customStyle={'more-detail-button-242px'}
                            />
                            

                        }
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    </div>

    );
}

export default BackOfficeMainPage;