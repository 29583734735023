import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { DifficultyHeader } from '../../constants/types';
import { Colors } from '../../constants/Consts';
import DifficultySummaryHomeItem from './DifficultySummaryHomeItem';

interface DifficultySummaryHomeProps {
    difficultyheaders :DifficultyHeader []
    totalBoulderCount : number
        
}


const DifficultySummaryHome : React.FC<DifficultySummaryHomeProps> = ({  difficultyheaders,totalBoulderCount }) => {
    const maxRatio = difficultyheaders.reduce((max, header) => {
        const currentRatio = header.countBoulder / totalBoulderCount;
        return currentRatio > max ? currentRatio : max;
    }, 0);
    
    useEffect(() => {
        // Find the maximum width of elements with the class "difficulty-summary-item-container-name-container"
        const nameContainers = document.querySelectorAll('.difficulty-summary-item-container-name-container');
        let maxWidth = 0;

        nameContainers.forEach(container => {
            const width = container.getBoundingClientRect().width;
            if (width > maxWidth) {
                maxWidth = width;
            }
        });

        // Apply the maximum width to all elements with the class "difficulty-summary-item-container-name-container"
        nameContainers.forEach(container => {
            (container as HTMLElement).style.width = `${maxWidth}px`;
        });
    }, [difficultyheaders]); // Ensure this runs whenever difficultyheaders changes

   
   
    return (
        <div className='difficulty-summary-item-container'>
            <div className='difficulty-summary-header' style={{marginBottom:'4px'}}> 
                <div className='difficulty-summary-item-container-name-container'/>
                <div className='bold large3' style={{color:Colors.colorgrey600}}>{totalBoulderCount >1 ? `${totalBoulderCount} blocs` : `${totalBoulderCount} bloc`}</div>
            </div>  

            {difficultyheaders && difficultyheaders
                .sort((a, b) => a.difficultyGrade - b.difficultyGrade) // Sort by difficultyGrade
                .map((difficultyheader, index) => {
                 const widthPercentage = ((difficultyheader.countBoulder / totalBoulderCount) / maxRatio) * 75;
                 return (
                <div className='difficulty-summary-header'>
                    <div className='difficulty-summary-item-container-name-container' >{difficultyheader.difficultyName}</div>
                    {difficultyheader.countBoulder !==0 ? 
                        <DifficultySummaryHomeItem 
                            key={index}
                            difficultyheader={difficultyheader}
                            totalRoutes={totalBoulderCount}
                            width={`${widthPercentage.toFixed(2)}%`}
                        />
                        :
                        <div >Aucun bloc dans ce niveau</div>
                    }
                </div>
            )})}
            
        </div>
        

   
    )
}


export default DifficultySummaryHome