import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';

interface StatisticRowProps {
    label: string;
    customComponent: React.ReactNode;
}

const StatisticRow: React.FC<StatisticRowProps> = ({ label, customComponent }) => {
    return (
        <div className="statistic-row">
            <div className="statistic-label regular lower">{label}</div>
            <div className="statistic-line"></div>
            <div className="statistic-component">{customComponent}</div>
        </div>
    );
};

export default StatisticRow;