import React, { useEffect } from 'react';


// Type for the response from Django (customize this based on your API)






const RedirectStorePage: React.FC = () => {
    
    useEffect(() => {
        const userAgent = navigator.userAgent

        // Android detection
        if (/android/i.test(userAgent)) {
            window.location.href = 'market://details?id=com.gastonclimbing';
            return;
        }

        // iOS detection
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            window.location.href = 'itms-apps://itunes.apple.com/app/id6476766268';
            return;
        }

        // Fallback for computers and other devices
        window.location.href = 'https://play.google.com/store/apps/details?id=com.gastonclimbing';
    }, []);

  

    

    return (
        <div >
            
        </div>
    );
}

export default RedirectStorePage;

