import { GRADES } from "./Consts";
import { RankingLineProps } from "./types";

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export const csrftoken = getCookie('csrftoken');

export function formatRate(rate) {
    // Convert to percentage, round it, and add the '%' sign
    return `${Math.round(rate * 100)}%`;
}

export function formatDate(isoString) {
    const date = new Date(isoString);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const yy = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year
    return `${dd}/${mm}/${yy}`;
}

export function formatDateRange() {
    const months = [
        "janvier", "février", "mars", "avril", "mai", "juin",
        "juillet", "août", "septembre", "octobre", "novembre", "décembre"
    ];

    const today = new Date();

    // Function to subtract one month while handling edge cases like month boundaries
    function getLastMonthSameDay(date) {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const lastMonthDate = new Date(year, month - 1, day);
        if (lastMonthDate.getMonth() !== ((month + 11) % 12)) {
            lastMonthDate.setDate(0); // Set to the last day of the previous month
        }

        return lastMonthDate;
    }

    const lastMonthDate = getLastMonthSameDay(today);

    const formatDate = (date) => {
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const todayFormatted = formatDate(today);
    const lastMonthFormatted = formatDate(lastMonthDate);

    return `${lastMonthFormatted} - ${todayFormatted}`;
}

export function daysBetween(isoString) {
    const date = new Date(isoString);
    const today = new Date();

    // Set the time of both dates to midnight to compare just the dates without the time component
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    // Calculate the difference in milliseconds
    const diff = today.getTime() - date.getTime();

    // Convert milliseconds to days
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

    // Ensure that the difference is at least 1
    return diffDays >= 0 ? diffDays + 1 : 1;
}

export const handleItemClick = (itemId, itemList, clickedItems, setClickedItems, checkAllFilters, setCheckAllFilters, setFilteredItems, setRoutesInfo,shouldResetRoutesInfo, shouldForceReset?) => {
    const currentState = { ...clickedItems };

    const noOtherTrue = itemList?.every(item => !currentState[item.id] || item.id === itemId)
    if (noOtherTrue && currentState[itemId]) {
        currentState[itemId] = !currentState[itemId];
        setClickedItems(currentState);

        if ((setRoutesInfo && shouldResetRoutesInfo || shouldForceReset)) console.log('do this'); setRoutesInfo(undefined);
        return;
    }

    if (itemList.length > 1) {
        const allTrue = itemList?.every(item => !!currentState[item.id]);
        if (allTrue) {
            for (const item of itemList) {
                currentState[item.id] = item.id === itemId;
            }
            setCheckAllFilters(false);
            setClickedItems(currentState);
            if (setFilteredItems) setFilteredItems(itemList.filter(item => currentState[item.id]).map(item => item.id));
            return;
        }

        const allOthersTrue = itemList?.every(item => item.id === itemId || !!currentState[item.id]);
        if (allOthersTrue) {
            setCheckAllFilters(true);
            currentState[itemId] = true;
            setClickedItems(currentState);
            if (setFilteredItems) setFilteredItems([0]);
            return;
        }
    }

    if (itemList.length === 1) {
        setCheckAllFilters(!currentState[itemId]);
    }

    currentState[itemId] = !currentState[itemId];
    setClickedItems(currentState);
    if (setFilteredItems) setFilteredItems(itemList.filter(item => currentState[item.id]).map(item => item.id));
};

export const handleCheckToggle = (status, itemList, setClickedItems, checkAllFilters, setCheckAllFilters, setFilteredItems, setRoutesInfo,shouldResetRoutesInfo,shouldForceReset?) => {
    const newState = {};
    itemList?.forEach(item => {
        newState[item.id] = status;
    });

    setClickedItems(newState);
    if (!checkAllFilters && setFilteredItems) setFilteredItems([0]);
    else if ((setRoutesInfo && shouldResetRoutesInfo || shouldForceReset)) setRoutesInfo(undefined);
    setCheckAllFilters(!checkAllFilters);
};

export const handleSorting = (
    currentSortValue: number,
    setSort: React.Dispatch<React.SetStateAction<number>>,
    allSortSetters: { [key: string]: React.Dispatch<React.SetStateAction<number>> }
) => {
    // Toggle the value of the current sort between 1 and 2
    if (currentSortValue === 1) {
        setSort(2);
    } else {
        setSort(1);
    }

    // Reset all other sort states to 0
    for (let key in allSortSetters) {
        if (allSortSetters[key] !== setSort) {
            allSortSetters[key](0);
        }
    }
    
}

export function getGradeRange(index) {
    // Adjust index to be zero-based and account for the -2 offset
    const startIndex = index - 3; // Because we're given an index as if the array starts at 1, and we need -2 position
    const endIndex = index - 1; // Adding +1 to the given index considering it's 1-based

    // Ensure indices are within bounds
    const safeStartIndex = Math.max(0, startIndex);
    const safeEndIndex = Math.min(GRADES.length - 1, endIndex);

    return `${GRADES[safeStartIndex]} - ${GRADES[safeEndIndex]}`;


}

export const getGradeFromIndex = (index: number): string => {
    return GRADES[index]}

export const processClimbers = (climbers, scoreProperty: string): RankingLineProps[] => {
    // Sort climbers by the scoreProperty in descending order
    climbers.sort((a, b) => b[scoreProperty] - a[scoreProperty]);

    // Map climbers to RankingLineProps with ranks
    return climbers.map((climber, index) => ({
        rank: index + 1,
        firstName: climber.first_name,
        lastName: climber.last_name,
        points: climber[scoreProperty]
    }));
}

export const getDaysDifferenceMessage = (dateString: string): string => {
    const date = new Date(dateString);
    const today = new Date();
    const timeDifference = date.getTime() - today.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Difference in days

    if (daysDifference > 0) {
        // Future date
        return `Dans ${daysDifference} jour${daysDifference > 1 ? 's' : ''}`;
    } else {
        // Past date
        const daysAgo = Math.abs(daysDifference);
        return `Il y a ${daysAgo} jour${daysAgo > 1 ? 's' : ''}`;
    }
};

export const getDaysDifferenceMessageWithUTC = (dateString: string): string => {
    const date = new Date(dateString);
    const today = new Date();
    
    // Convert both dates to UTC to avoid time zone issues
    const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    const utcToday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    
    const timeDifference = utcDate - utcToday;
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Difference in days

    if (daysDifference > 0) {
        // Future date
        return `Dans ${daysDifference} jour${daysDifference > 1 ? 's' : ''}`;
    } else {
        // Past date
        const daysAgo = Math.abs(daysDifference);
        return `Il y a ${daysAgo} jour${daysAgo > 1 ? 's' : ''}`;
    }
};