import React, { useState, FormEvent, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { ApiLoginResponse } from '../constants/types';
import {addEmailAdressReminder, ipAdress, loginUser} from '../data/getAxiosRequests'
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButton';
import { useFormik } from 'formik';
import { csrftoken } from '../constants/helpers';
import axios from 'axios';
import Select from 'react-select';
import { gyms } from '../constants/Consts';
import AutocompleteInput from '../components/UI/Autocomplete';

// Type for the response from Django (customize this based on your API)






const LandingPage: React.FC = () => {
    useEffect(() => {
        document.title = "Gastón"},[])

    const navigate = useNavigate();
    const gymOptions = gyms.map(gym => ({ value: gym.id, label: gym.name }));
    
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [emailFocused, setEmailFocused] = useState(false); // New state to track focus
    const [emailAdded, setEmailAdded] = useState(false)

    
    const formik = useFormik({
        initialValues: {
          selectedGym: '',
          emailAddress: ''
        },
        validationSchema: Yup.object({
          selectedGym: Yup.string().required("Tu n'as pas renseigné de salle"),
          emailAddress: Yup.string().email("Le format de l'adresse email est incorrect").required("Tu n'as pas renseigne ton adresse email")
        }),
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            setSubmitting(true)
            
            addEmailAdressReminder(values.selectedGym,values.emailAddress)
               .then(response => {
      if (response.status === 201) {
        setEmailAdded(true); // Only set to true if status code is 201
        // Optionally, redirect or perform other actions on success
      }
    })
    .catch(error => {
      console.error('Submission error', error);

      // Check if the error message is the custom one we set for duplicate emails
      if (error.response && error.response.status === 400 ) {
       
        const emailErrors = error.response.data.email;
        if (emailErrors) {
        setErrorMsg(emailErrors[0]); // Set the first error message found for the email
        } else {
        setErrorMsg("quelque chose ne s'est pas passé comme prévu, contacte-nous sur insta.");
        }
    } else {
        setErrorMsg("quelque chose ne s'est pas passé comme prévu, contacte-nous sur insta.");
    }
    })
    .finally(() => {
      setSubmitting(false); // Set formik submitting state to false
    });
}})
    const handleEmailFocus = () => setEmailFocused(true);

    const handleEmailBlur = (e) => {
        setEmailFocused(false);
        formik.handleBlur(e); // Ensure Formik's handleBlur is called to manage touched state
    };
  

    

    return (
        <div className='Landing-page-background'>
            <div className='Landing-page-header'>
            
                <CustomButton
                    customStyle={'check-challenges-button'}
                    customTextStyle={'check-challenges-button-text'}
                    text='Les défis de Gaston'
                    onClickProp={()=>navigate(`/defi-gaston-corti`)}
                    
                />
            
                <div className='logo-gaston-landing-page'>
                    <IconButton 
                        Svg={SVGs.gastonLogo}
                    />
                </div>
                <CustomButton
                    customStyle={'Landing-page-route-setter-button'}
                    customTextStyle={'Landing-page-route-setter-button-text'}
                    text='Mon espace ouvreur'
                    onClickProp={()=>navigate(`/login-pro`)}
                    
                />
            </div>
            <div className='Landing-page-presentation-container' >
                <div className='Landing-page-presentation-description'>
                    <p style={{verticalAlign:'bottom'}} className='Landing-page-presentation-description-focus-text'>Créer la communauté des grimpeurs</p>
                    <div className='Landing-page-presentation-description-standard-text'>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <p><span style={{fontWeight:900}}>PARTAGER</span> tes séances, y retrouver des astuces, et en donner surtout !</p>
                        </div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <p><span style={{fontWeight:900}}>DECOUVRIR</span> nos défis, se mesurer, progresser ou juste en profiter</p>
                        </div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <p><span style={{fontWeight:900}}>S’ENCOURAGER</span>, y prendre goût et revenir !</p>
                        </div>
                    </div>
                    <div className='Landing-page-presentation-description-last-text-box'>
                        <p className='Landing-page-presentation-description-standard-text-last'>Dans ta salle<span> </span>  
                        <span className='Landing-page-presentation-description-focus-text '>à partir de l’été 2024</span></p>
                    </div>
                </div>
                <div className='app-demo-container'>
                    <img  src="https://gastonclimbing.s3.eu-central-1.amazonaws.com/images/web-front/appdemo.png"/>
                </div>
            </div>
            <div className='Landing-page-gym-finder-container'>
                <div className='Landing-page-gym-finder-header'>
                    <p className='Landing-page-gym-finder-header-title'>Je grimpe</p>
                    <div className='Landing-page-gym-finder-header-condensed'>
                        <p className='Landing-page-gym-finder-header-description'>Je veux être prévenu de l’arrivée de Gastón dans ma salle
                        <span className='Landing-page-gym-finder-header-subtitle'> (aucun autre mail ni newsletter ne te seront envoyés)</span></p>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} className='Landing-page-gym-finder-formik-container'>
                    <div className='Landing-page-gym-finder-formik-input'>
                        <div className='Landing-page-gym-finder-formik-input-inner-container'>
                            <AutocompleteInput
                                items={gyms} 
                                placeholder="Sélectionner ma salle" 
                                name="selectedGym"
                                onSelect={value => formik.setFieldValue('selectedGym', value)}
                                
                            />
                            {formik.touched.selectedGym && formik.errors.selectedGym ? (
                                <div className='Landing-page-gym-finder-formik-error'>{formik.errors.selectedGym}</div>
                            ) : null}
                        </div>
                        <div className='Landing-page-gym-finder-formik-input-inner-container'>
                            <input 
                               className='input-select-gym'
                               type="email"
                               name="emailAddress"
                               onChange={formik.handleChange}
                               onFocus={handleEmailFocus}
                               onBlur={handleEmailBlur}
                               value={formik.values.emailAddress}
                               placeholder='Mon email'
                            />
                            {formik.touched.emailAddress && formik.errors.emailAddress && !emailFocused && (
                                <div className="Landing-page-gym-finder-formik-error">{formik.errors.emailAddress}</div>
                            )}
                        </div>
                    </div>
                    <CustomButton 
                        customStyle={'Landing-page-gym-finder-button'}
                        customTextStyle={'Landing-page-gym-finder-button-text'}
                        customDisableStyle={'Landing-page-gym-finder-button'}
                        customDisableTextStyle={'Landing-page-gym-finder-button-text'}
                        text='Me tenir au courant'
                        disabled={!(formik.isValid && formik.dirty )|| formik.isSubmitting}
                        onClickProp={formik.handleSubmit}
                    />
                    {emailAdded && 
                        <p style={{marginTop:'16px'}} className='Landing-page-gym-finder-header-description'>C'est noté ! On te préviendra lorsque Gastón sera disponible dans ta salle</p>          
                    }
                    {errorMsg &&
                        <p style={{marginTop:'16px'}} className='Landing-page-gym-finder-header-description-error'>{errorMsg}</p>
                    }
                    

                </form>



            </div>
            <div className='Landing-page-gym-finder-container' style={{marginBottom:'88px'}}>
                <div className='Landing-page-gym-finder-header'>
                    <div className='Landing-page-gym-finder-header-condensed'>
                        <p className='Landing-page-gym-finder-header-title'>Je suis un pro</p>
                        <p style={{lineHeight:'34px'}} className='Landing-page-gym-finder-header-subtitle should-be-displayed'>(gérant ou directeur de salle, ouvreur, professionel du secteur...)</p>
                    </div>
                    <p className='Landing-page-gym-finder-header-description'>Je veux en savoir plus sur la solution</p>
                </div>
                <div className='Landing-page-gym-finder-pro'>
                    <a style={{textDecoration:'none', width:'100%'}} target="_blank" rel="noopener noreferrer" href='https://calendly.com/jeremy-gaston/un-coup-d-oeil-chez-gaston'>
                        <CustomButton
                            text='Se rencontrer'
                            customStyle={'Landing-page-gym-finder-header-professional-button'}
                            customTextStyle={'Landing-page-gym-finder-header-professional-button-text'}
                        />
                    </a>
                    <a style={{textDecoration:'none', width:'100%'}} target="_blank" rel="noopener noreferrer" href='https://instagram.us12.list-manage.com/subscribe?u=ba53c95a14502d2315710bfef&id=fb9263572a'>
                        <CustomButton
                            text={'S’inscrire à notre newsletter'}
                            customStyle={'Landing-page-gym-finder-header-professional-button'}
                            customTextStyle={'Landing-page-gym-finder-header-professional-button-text'}
                            
                        />
                    </a>
                </div>
            </div>
            <div className='app-demo-container-mobile'>
                <img  src="https://gastonclimbing.s3.eu-central-1.amazonaws.com/images/web-front/appdemo.png"/>
            </div>
            <div className='landing-page-footer'>
                <div className='landing-page-footer-left-side'>
                    <p className='landing-page-footer-title'>Notre partenaire de confiance</p>
                    <a href="http://www.corti-grimpe.fr/" target="_blank" rel="noopener noreferrer" className='landing-page-footer-left-side-button-container'>
                        <CustomButton 
                            text='Cortigrimpe, Annecy 74'
                            customTextStyle={'landing-page-footer-partner-button-text'}   
                        />
                        <CustomButton 
                            Svg={SVGs.cortigrimpeLogo}  
                        />
                    </a>
                </div>
                <div className='landing-page-footer-right-side'>
                    <p className='landing-page-footer-title'>Nous suivre, nous soutenir</p>
                    <div className='landing-page-footer-right-side-socials-container'>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/gaston_grimpe/?hl=fr'>
                            <CustomButton 
                                Svg={SVGs.instagramBlackLogo}         
                            />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.tiktok.com/@gaston_grimpe'>
                            <CustomButton 
                                Svg={SVGs.tiktokBlackLogo}  
                            />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.youtube.com/@Gaston-climbing'>
                            <CustomButton 
                                Svg={SVGs.youTubeBlackLogo}  
                            />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/company/gast%C3%B3n/'>
                            <CustomButton 
                                Svg={SVGs.linkedinBlackLogo}  
                            />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.facebook.com/profile.php?id=61551677716289'>
                            <CustomButton 
                                Svg={SVGs.facebookBlackLogo}  
                            />
                        </a>
                    </div>
                </div>
            </div>

       
        </div>
    );
}

export default LandingPage;
