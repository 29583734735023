import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { getDifficultyCircuitHeaders, getGymGeneralStats, getRouteSetterGymFilterInfo, getRouteSetterUserInfo, getSectorDetails, getSectorDetailsById, getSectorsOverview, ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken, formatDate, getDaysDifferenceMessage, getGradeFromIndex } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButton';

import NavBarBackOffice from '../components/backOffice/Navbar';
import { DifficultyCircuitHeader, DifficultySplit, GymMinimalProps, GymStats, RouteSetterProps, SectorBasisChoices, SectorCategory, SectorDetailsProps, SectorsOverview } from '../constants/types';
import { GlobalContext } from '../context/GlobalStateProvider';
import Modal from '../components/UI/ModalRoute';
import { Colors, pages } from '../constants/Consts';
import StatBox from '../components/backOffice/StatBox';
import DifficultySummaryHome from '../components/HomePage/DifficultySummaryHome';
import SectorSummaryRow from '../components/backOffice/SectorSummaryRow';
import SectorItem from '../components/UI/SectorItem';
import StatBoxSector from '../components/backOffice/StatBoxSector';
import StatisticRow from '../components/backOffice/StatisticRow';
import StarRating from '../components/UI/StarRating';
import DifficultySplitComponent from '../components/UI/DifficultySplit';
import SectorDetailsRow, { SectorDetailsRowProps } from '../components/backOffice/SectorDetailsRow';
import CommentsCard from '../components/backOffice/CommentsCard';
import { TailSpin } from 'react-loader-spinner';
import DifficultySummaryCicuit from '../components/HomePage/DifficultySummaryCircuitHome';
import DifficultySummaryCircuitItemStats from '../components/HomePage/DifficultySummaryCircuitStats';





const CircuitsOverviewPage: React.FC = () => {
    const navigate  = useNavigate()
    
    const [sectorsInitialDetails, setSectorsInitialDetails] = useState<SectorBasisChoices[]>([]);
    const [sectorsDetails, setSectorsDetails] = useState<SectorDetailsProps[]>([]);  
    const [selectedSector, setSelectedSector] = useState<SectorDetailsProps>(null) 
    const [difficultySplit, setDifficultySplit] = useState<DifficultySplit[]>([]);
    const [sectorDetailsRows, setSectorDetailsRows] = useState<SectorDetailsRowProps[]>([]);
    const globalContext = useContext(GlobalContext);
    const [shouldBeLoading, setShouldBeLoading] = useState(true)
    const [difficultyCircuitHeaders, setDifficultyCircuitHeaders] = useState<DifficultyCircuitHeader[]>([]);
    const [routesCount, setRoutesCount ] = useState(null)


    

    

    
    

    const { currentGym, setCurrentGym,setCurrentPage,currentPage,setGymsFilterInfo,gymsFilterInfo,user,setUser,routeSetterInfo, setRouteSetterInfo } = globalContext;


    useEffect(() => {
        document.title = "Circuits"
    },[])

    useEffect(() => {
        if (!currentGym) {
            const favoriteGym = localStorage.getItem('favoriteGymInfo');
            setCurrentGym(JSON.parse(favoriteGym))
        }
        if (!user) {
            const loggedUser = localStorage.getItem('user');
            if (loggedUser) {
                setUser(JSON.parse(loggedUser));
            } else {
                navigate('/login-pro');
            }
        }
    }, []);

    useEffect(() => {
        setCurrentPage(pages.circuits)
    }, []);

    useEffect(() => {
        if (user && currentGym) {
            getRouteSetterUserInfo(user).then(data => {
                setRouteSetterInfo(data);
            });
            getDifficultyCircuitHeaders(currentGym.id).then((data) => {
                setDifficultyCircuitHeaders(data);
                const totalBoulderCount = data.reduce((sum, header) => sum + header.boulderCount, 0);
                setRoutesCount(totalBoulderCount);
                setShouldBeLoading(false)
            });
           
        } else {
            console.error("user not provided again");
        }
    }, [user, currentGym]);

    return (
        <div className='outerContainer'>
            <NavBarBackOffice
                profilePicture={routeSetterInfo?.picture}
                firstName={routeSetterInfo?.firstName}
                lastName={routeSetterInfo?.lastName}
                gyms={currentGym && currentGym.name}
            />
            <div className="bo-main-content">
                {shouldBeLoading && 
                    <div className="loading-overlay">
                        <TailSpin color="#0099CC" height={80} width={80} />
                    </div>
                }   
                <div className="bo-main-content__title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 2H22.3333M1.5 5.33333H16.7244M1.5 8.66667H19.1282M1.5 12H9.51282M1.5 15.3333H15.1218M1.5 18.6667H22.3333M1.5 22H18.3269" stroke="#344054" stroke-linecap="round"/>
                </svg>
                    <div className="bold large3" >
                        <p>Vue par circuit / difficulté</p>
                    </div>
                </div>
                <div className="pannels-filter__filters">
                        <div className='header-tips'>
                            <CustomButton 
                                Svg={SVGs.brilliantIdea}
                            />
                            <div className='bold normal'>Astuce Gaston : A quoi sert cette page ?</div>
                        </div>
                        <div className='regular lower'>
                            Cette page permet d’analyser l’harmonie de l’ouverture en termes de difficultés et le succès des circuits rencontré auprès des grimpeurs. Clique sur un circuit pour accéder aux blocs et avoir plus de détail !
                        </div>
                    </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Répartition des blocs par difficulté</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Les blocs sont détaillés par niveau et sous-niveau</div>
                    </div>
                    {difficultyCircuitHeaders &&
                        <div className='difficulty-headers-visual-container'>
                        <DifficultySummaryCicuit 
                            difficultyheaders={difficultyCircuitHeaders}
                            totalBoulderCount={routesCount}
                        />
                        </div>
                    }
                </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Performance des blocs selon Gaston</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Ces statistiques aident à mesurer le succès des blocs auprès des grimpeurs et l’évolution de leur niveau de difficulté</div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', gap: '12px'}}>
                        {difficultyCircuitHeaders &&
                            difficultyCircuitHeaders.map((difficultyCircuitHeader =>
                                difficultyCircuitHeader.boulderCount > 0 &&
                                <DifficultySummaryCircuitItemStats 
                                    difficultyheader={difficultyCircuitHeader}
                                />
                            ))
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default CircuitsOverviewPage;

