import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { DifficultyHeader } from '../../constants/types';
import { Colors } from '../../constants/Consts';

interface DifficultySummaryHomeItemProps {
    difficultyheader: DifficultyHeader;
    width: string;
    totalRoutes: number;
}

const DifficultySummaryHomeItem: React.FC<DifficultySummaryHomeItemProps> = ({ difficultyheader, totalRoutes, width }) => {
    const gradientBackground = difficultyheader.difficultyColor1 === null
        ? Colors.colorgrey300
        : difficultyheader.difficultyColor2
            ? `linear-gradient(to bottom, ${difficultyheader.difficultyColor1} 50%, ${difficultyheader.difficultyColor2} 50%)`
            : difficultyheader.difficultyColor1;

    const isSpecialColor = (color: string | undefined) => {
        return color === '#F4F443' || color === '#FFFFFF';
    };

    const fontColor = difficultyheader.difficultyColor1 === null
        ? Colors.colorgrey800
        : isSpecialColor(difficultyheader.difficultyColor1) || isSpecialColor(difficultyheader.difficultyColor2)
            ? Colors.colorgrey800
            : '#FFFFFF';

    return (
        <div
            className='difficulty-summary-item-container-visual'
            style={{
                width: width,
                background: gradientBackground,
                minWidth: 'fit-content',
                color: fontColor
            }}
        >
            <div className='difficulty-summary-item-container-visual-description bold normal'>
                {difficultyheader.countBoulder > 0 && (
                    <>
                        {difficultyheader.countBoulder} {difficultyheader.countBoulder <= 1 ? 'bloc ' : 'blocs '}
                        ({totalRoutes ? ((difficultyheader.countBoulder / totalRoutes) * 100).toFixed(0) + ' %' : '0 %'})
                    </>
                )}
            </div>
        </div>
    );
};

export default DifficultySummaryHomeItem;