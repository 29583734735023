import React, { useContext, useState } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { csrftoken, formatDate } from '../../constants/helpers';
import Modal from '../UI/ModalRoute';
import axios from 'axios';
import {  Colors, roleMapping, roleMappingReverse,} from '../../constants/Consts';
import { GymExtraMinimalProps, RouteSetters } from '../../constants/types';
import { GlobalContext } from '../../context/GlobalStateProvider';

interface ParameterRouteSetterRowProps extends RouteSetters {
    listOfGyms: GymExtraMinimalProps[];
    currentUserUid: string;
}

export const rolesConst = Object.values(roleMapping);

const ParameterRouteSetterRow: React.FC<ParameterRouteSetterRowProps> = ({ firstName, gyms, lastName, roles, uid, listOfGyms, currentUserUid }) => {
    const [changeGyms, setChangeGyms] = useState(false)
    const [selectedRoles, setSelectedRoles] = useState(roles.map(role => roleMapping[role]));
    const [selectedGyms, setSelectedGyms] =useState(gyms)
    const globalContext = useContext(GlobalContext);
    const filteredGyms = listOfGyms.filter(gym => gyms.some(g => g.id === gym.id));
    const showRouteSetter = uid !== currentUserUid;

    const {user } = globalContext;

    
    const handleCheckboxChange = (role) => {
        setSelectedRoles((prevSelectedRoles) =>
            prevSelectedRoles.includes(role)
                ? prevSelectedRoles.filter((r) => r !== role)
                : [...prevSelectedRoles, role]
        );
    };

    const handleCheckboxGymChange = (gym) => {
        setSelectedGyms((prevSelectedGyms) =>
            prevSelectedGyms.some((selectedGym) => selectedGym.id === gym.id)
                ? prevSelectedGyms.filter((selectedGym) => selectedGym.id !== gym.id)
                : [...prevSelectedGyms, gym]
        );
    };

    
    const toggleChangeGyms = () => {
        setChangeGyms(prev => !prev); // Toggle the state of extendedRow
    };

    const saveChanges = async () => {
        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
            const response = await axios.post(`/api/update-route-setter-infos/${user}/`, {
                userId: uid,
                roles: selectedRoles.map(role => roleMappingReverse[role]),
                gyms: selectedGyms.map((gym) => gym.id),
            });

            if (response.status === 200) {
                window.location.reload()
            }
        } catch (error) {
            console.error('Error updating roles and gyms:', error);
        }
    };

    
    
    

    return (
        showRouteSetter && (
            <div className='table-body__row__outer-container'>
                <div className='sector-summary-row'>  
                    <div className='parameter-row-name regular normal'>
                        {firstName} { lastName}
                    </div>
                    <div className='parameter-row-role regular normal'>
                        {selectedRoles && selectedRoles.length > 0 && selectedRoles.map((role, index) => (
                            <p key={index}>{role}{index < selectedRoles.length - 1 ? ',' : ''}</p>
                        ))}
                    </div>   
                    <div className='parameter-row-gyms regular normal'>
                        <div className='bold normal' style={{color:Colors.colorbackoffice700}}>{selectedGyms.length} {selectedGyms.length >1 ? ' salles' : ' salle'}</div>
                        <div className='parameters-gyms-list regular lower'>
                            {selectedGyms.map((gym=>
                                <div>
                                    {gym.name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='parameter-row-modify regular normal'>
                    <CustomButton
                        text='Modifier'
                        Svg={SVGs.modifyBlue}
                        customStyle='parameter-modify bold normal'
                        onClickProp={()=>setChangeGyms(true)}
                        
                    />
                    </div>
                </div>
                
                <Modal isOpen={changeGyms} onClose={toggleChangeGyms} titleModal={`${firstName} ${lastName} - Droits d'ouverture`} width='fit-content' position='relative'>
                <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '24px 16px 24px 16px', alignItems:'flex-start'}}>
                    <div className='modal-change-route-setter'>
                        <div className="form-change-route-setter">
                            <div className='bold normal' style={{color:Colors.colorgrey700, width:'120px'}}>
                                Types d'accès
                            </div>
                            <div style={{display:'flex', flexDirection: 'column', gap:'12px'}} >
                                {rolesConst.map((role) => {
                                    return (
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }} key={role}>
                                            <input
                                                type="checkbox"
                                                id={role}
                                                checked={selectedRoles.includes(role)}
                                                onChange={() => handleCheckboxChange(role)}
                                            />
                                            <label htmlFor={role}>{role}</label>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                        <div className='form-change-route-setter'>  
                            <div className='bold normal' style={{color:Colors.colorgrey700,width:'120px'}}>
                                Salles
                            </div> 
                            <div style={{display:'flex', flexDirection: 'column', gap:'12px',flexWrap:'wrap', height:'132px'}} >
                                {listOfGyms.map((gym) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }} key={gym.id}>
                                        <input
                                            type="checkbox"
                                            id={gym.id.toString()}
                                            checked={selectedGyms.some((selectedGym) => selectedGym.id === gym.id)}
                                            onChange={() => handleCheckboxGymChange(gym)}
                                        />
                                        <label htmlFor={gym.name}>{gym.name}</label>
                                    </div>
                                ))}
                            </div>                     
                        </div>
                        <div className="modal-change-closing-date-button">
                            <CustomButton 
                                customStyle='modal-change-closing-date-button-confirm bold normal'
                                text='Confirmer'
                                
                                onClickProp={saveChanges}
                                customDisableStyle='modal-change-closing-date-button-confirm-disabled bold normal'
                            />
                                <CustomButton 
                                customStyle='modal-change-closing-date-button-cancel bold normal'
                                text='Retour'
                                onClickProp={()=>setChangeGyms(false)}
                                
                            />
                            
                        </div>
                        
                    </div>
                </div>

            </Modal>
            
        </div>
    )
    )

}

export default ParameterRouteSetterRow