import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';

interface StatBoxSectorProps {
    text:string
    currentMonthStat : number | string,
    lastMonthStat : number | string,
    
}


const StatBoxSector : React.FC<StatBoxSectorProps> = ({  text, currentMonthStat,lastMonthStat }) => {
   
    
   
   
    return (
        <div className='box-stat-container'>  
            <div className='box-stat-actual-stat-sector-container bold large2'>
                {currentMonthStat}
                <p className='bold normal'>({lastMonthStat})</p>
                

            </div>
            <div className='regular normal'>
                {text}
            </div>
            
        </div>
        

   
    )
}


export default StatBoxSector