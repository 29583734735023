import { Colors } from "../../constants/Consts";
import { BestBoulderCard, SectorBasisChoices, SectorDetailsProps } from "../../constants/types";
import LevelDisplayerDirect from "../backOffice/LevelDisplayerDirect";
import StatisticRow from "../backOffice/StatisticRow";
import DifficultySvg from "./DifficultySvg";
import GripIcon from "./GripIcon";
import StarRating from "./StarRating";

const BestBoulderCardComponent: React.FC<BestBoulderCard & { hasDifficultySystem: boolean }> = ({averageTriesBeforeTop,difficultySvg,gripColor,id,level,name,picture,sectorName,totalTries,triesPerDay,ranking,rankingCount,hasDifficultySystem,difficultyColor}) => {
    

    return (
       <div className="best-boulder-card-component-container">
            <div className="best-boulder-card-component-visuals-container">
                <div className="bold normal">{name}</div>
                <div className="best-boulder-card-component-visuals">
                    <img src={picture}/>
                    <div className="best-boulder-card-component-visuals-description">
                        <div>{sectorName}</div>
                        <DifficultySvg 
                            hasDifficultySystem={hasDifficultySystem}
                            svg={difficultySvg}
                            text=""
                        />
                        <LevelDisplayerDirect 
                            level={level}
                        />
                        <GripIcon 
                            colorCode={gripColor ? gripColor : difficultyColor}
                        />
                    </div>
                </div>
            </div>
            <div className="vertical-best-boulder-component-line"
            />
            <div className="best-boulder-card-component-stats-container">
                <div className="best-boulder-card-component-essential-stat">
                    <div className="bold larger2">{triesPerDay ? triesPerDay.toFixed(2) : 0}</div>
                    <div className="regular normal" style={{color:Colors.colorbackoffice500}}>Essais / jour</div>
                </div>
                <div className="best-boulder-card-component-seconday-stat-container">
                    <StatisticRow 
                        customComponent={
                            <div>{totalTries}</div>
                        }
                        label="Essais"
                    />
                    <StatisticRow 
                        customComponent={
                            <div>{averageTriesBeforeTop ? averageTriesBeforeTop.toFixed(1) : 0}</div>
                        }
                        label="Essais pour la croix"
                    />
                    <StatisticRow 
                        customComponent={
                            <div style={{display:'flex',flexDirection:'row', gap:'4px'}}>
                                <StarRating 
                                    review={ranking}
                                    styleText="Regular lower"
                                />
                                {rankingCount && rankingCount >0 &&
                                    <div>({rankingCount})</div>    
                                }
                            </div>
                        }
                        label="Les étoiles"
                    />
                </div>
            </div>


       </div>
    );
};

export default BestBoulderCardComponent