import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { getGymComments, getGymGeneralStats, getLatestGymRouteComments, getRouteSetterGymFilterInfo, getRouteSetterUserInfo, getSectorsOverview, getTopBoulders, ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken, formatDateRange } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButton';

import NavBarBackOffice from '../components/backOffice/Navbar';
import { BestBoulderCard, CommentBouldersSector, GymMinimalProps, GymStats, RouteSetterProps, SectorCategory, SectorsOverview } from '../constants/types';
import { GlobalContext } from '../context/GlobalStateProvider';
import Modal from '../components/UI/ModalRoute';
import { pages } from '../constants/Consts';
import StatBox from '../components/backOffice/StatBox';
import DifficultySummaryHome from '../components/HomePage/DifficultySummaryHome';
import SectorSummaryRow from '../components/backOffice/SectorSummaryRow';
import { TailSpin } from 'react-loader-spinner';
import BestBoulderCardComponent from '../components/UI/BestBoulderCard';
import CommentsCard from '../components/backOffice/CommentsCard';






const CommentsPage: React.FC = () => {

    const [shouldBeLoading, setShouldBeLoading] = useState(true)
    const [comments, setComments] = useState<CommentBouldersSector[]>([]);
    const navigate = useNavigate()
    const globalContext = useContext(GlobalContext);
    const { currentGym, setCurrentGym,setCurrentPage,currentPage,setGymsFilterInfo,gymsFilterInfo,routeSetterInfo,setRouteSetterInfo,user } = globalContext;

    useEffect(() => {
        document.title = "Commentaires"
    },[])

    useEffect(() => {
        const favoriteGym = localStorage.getItem('favoriteGymInfo');
        if (favoriteGym) {
            setCurrentGym(JSON.parse(favoriteGym));
        } 

        else {
            console.error("user not provided");
        }
    }, [user, setCurrentGym]);

    useEffect(() => {
        setCurrentPage(pages.commentaires)
    }, []);





    useEffect(() => {
        if ((user) && currentGym) {
            getRouteSetterUserInfo(user).then(data => {
                setRouteSetterInfo(data);
            });
            getGymComments(currentGym.id).then(data =>{
                setComments(data)
                setShouldBeLoading(false)
            })
            
        } else {
            // Handle the case where user is not provided
            if (!localStorage.getItem('user')){
                navigate('/login-pro')
            }
            
            console.error("user not provided");
           
        }
    }, [user, currentGym]);


    

    
    return (
        
        <div className='outerContainer'>
            <NavBarBackOffice
                profilePicture={routeSetterInfo?.picture}
                firstName={routeSetterInfo?.firstName}
                lastName={routeSetterInfo?.lastName}
                gyms={currentGym && currentGym.name}
                
            />  {shouldBeLoading && 
                    <div className="loading-overlay">
                        <TailSpin color="#0099CC" height={80} width={80} />
                    </div>
                }   
                <div className="bo-main-content">
                <div className="bo-main-content__title">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 4V17.17L18.83 16H4V4H20ZM20 2H4C2.9 2 2 2.9 2 4V16C2 17.1 2.9 18 4 18H18L22 22V4C22 2.9 21.1 2 20 2ZM18 12H6V14H18V12ZM18 9H6V11H18V9ZM18 6H6V8H18V6Z" fill="#1D5073"/>
                    </svg>

                    <div className="bold large3" >
                        <p>Vue Commentaires</p>
                    </div>
                </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Commentaires</div>
                    </div>
                    <div style={{display: 'flex', flexDirection:'column',gap :'16px', width:'100%'}}> 
                        {(comments && comments.length > 0) ?
                            comments.map((comment)=>
                                <CommentsCard 
                                    profilePictureUser={comment.profilePictureUser}
                                    boulderId={comment.boulderId}
                                    boulderName={comment.boulderName}
                                    boulderPicture={comment.boulderPicture}
                                    comment={comment.comment}
                                    commentId={comment.commentId}
                                    createdAt={comment.createdAt}
                                    difficultySvg={comment.difficultySvg}
                                    firstNameUser={comment.firstNameUser}
                                    lastNameUser={comment.lastNameUser}
                                    level={comment.level}
                                    sectorName={comment.sectorName}
                                />
                            )
                            :
                            <div className='no-routes-msg light large2'>
                                Pas encore de commentaire  
                            </div>
                        }
                    </div>
                </div>
                
                    

                
                
            </div>

            
        </div>
    );
}

export default CommentsPage;

