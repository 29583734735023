import React, { useState } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import LevelDisplayerDirect from './LevelDisplayerDirect';
import DifficultySvg from '../UI/DifficultySvg';
import { csrftoken, daysBetween, formatDate, formatRate } from '../../constants/helpers';
import { ColorsMinimalProps, CommentBouldersSectorSimple, GymDifficultySystemMinimalProps, GymExtraMinimalProps, SectorExtraMinimalProps } from '../../constants/types';
import Modal from '../UI/ModalRoute';
import StatisticRow from './StatisticRow';
import ColorSquare from '../UI/ColorSquare';
import LevelDisplayer from './LevelDisplayer';
import StarRating from '../UI/StarRating';
import CommentsCardSimple from './CommentsCardSimple';
import { Colors } from '../../constants/Consts';
import axios from 'axios';

export interface SectorDetailsRowProps {
    id: number
    name: string,
    difficultySvg : string
    difficultyId : number
    level : number
    tagSteepness : string
    tagGrips : string
    tagStyle : string
    openingDate : string,
    plannedDismantling  : string,
    difficultySystem : boolean
    sector: SectorExtraMinimalProps,
    color? :ColorsMinimalProps,
    gym : GymExtraMinimalProps,
    difficultyGrading : number,
    difficultyGymSystem : GymDifficultySystemMinimalProps,
    status : boolean,
    picture? : string,
    difficultyDisplay: string,
    closedDate:string
    averageRanking?: number | null
    averageGrading?: number | null
    successRate?: number | null
    successRateBySameLevelClimbers?: number | null
    successFlashRate?: number | null
    successFlashRateBySameLevelClimbers?: number | null
    totalTries?: number | null
    averageTriesBeforeTop ?:number | null
    averageTriesBeforeTopBySameLevelClimber?: number | null
    routeSetter : string
    averageRankingCount: number
    comments?: CommentBouldersSectorSimple[]
    

    
}


const SectorDetailsRow : React.FC<SectorDetailsRowProps> = ({ id,name,openingDate,plannedDismantling,difficultySystem,difficultyId,difficultySvg,level,tagGrips,tagSteepness,tagStyle,averageRankingCount,closedDate,difficultyDisplay,difficultyGrading,difficultyGymSystem,gym,routeSetter,sector,status,averageGrading,averageRanking,averageTriesBeforeTop,averageTriesBeforeTopBySameLevelClimber,color,comments,picture,successFlashRate,successFlashRateBySameLevelClimbers,successRate,successRateBySameLevelClimbers,totalTries }) => {
 
    const [extendedRow, setExtendedRow] = useState(false)

    const toggleExtendedRow = () => {
        setExtendedRow(prev => !prev); // Toggle the state of extendedRow
    };
    const [changeDate, setChangeDate] = useState(false)

    const toggleChangeDate = () => {
        setChangeDate(prev => !prev); // Toggle the state of extendedRow
    };
    const [date, setDate] = useState('');
    const [modifyBlock, setModifyBlock] = useState(false);
    const [modifySector, setModifySector] = useState(true);
    const [error, setError] = useState('');


    const handleDateChange = (e) => {
        const value = e.target.value;
        setDate(value);
    
        // Validate the date format (DD/MM/YYYY)
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!datePattern.test(value)) {
          setError('La date doit être saisie au format JJ/MM/AAAA');
        } else {
          setError('');
        }
    };

    const handleConfirm = async () => {
        if (error) {
            alert('Veuillez corriger les erreurs avant de confirmer.');
            return;
        }

        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
            const response = await axios.post(`/api/update-route/${id}/`, {
                date,
                modifyBlock,
                modifySector
            });

            if (response.status === 200) {
                window.location.reload(); // Refresh the page on success
            }
        } catch (error) {
            console.error('Error updating route:', error);
        }
    };


    return (
        <div className='table-body__row__outer-container'>
        <div className='sector-details-row'
        onClick={()=>{
            toggleExtendedRow()}}
        >  
            <div className='sector-details-row-name regular normal'>
                {name}
            </div>
            <div className='sector-details-row-difficulty regular normal'>
            <DifficultySvg
                text={difficultyId.toString()}
                svg={difficultySvg}
                hasDifficultySystem={difficultySystem}
                customStyle={'custom-extended-route-row-description-difficulty'}

            />
            </div>   
           
                <LevelDisplayerDirect 
                    level={level}
                />
                
            
            <div className='sector-details-row-tags regular normal'>
                {tagSteepness}, {tagGrips}, {tagStyle}
            </div>
            <div className='sector-details-row-opening regular normal'>
                {formatDate(openingDate)}
            </div>
            <div className='sector-details-row-closing regular normal'>
                {formatDate(plannedDismantling)}
            </div>
            <div className='sector-details-row-modify' onClick={(e) => e.stopPropagation()}>
                <CustomButton
                    Svg={SVGs.modifySector}
                    
                    onClickProp={() => setChangeDate(true)}
                />
            </div>

            
        </div>
        <Modal isOpen={extendedRow} onClose={()=>toggleExtendedRow()} titleModal='Détail bloc' >
            <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '32px 40px 32px 40px'}}>
                <div className='internal-modal-routes-container'>
                    
                        <div className='detail-routes-modal-box'>
                            <div className='extended-row-title bold large2 '>
                                <p>{name}</p>
                            </div>
                            <div className='extended-row-title-container-route-summary'>
                                <img src={picture} />
                                <div className='right-side-stats-container'>
                                    <div className='extended-row-description-tags-container'>
                                        <div className='extended-row-description-tag-content light lower'>
                                            <p >{tagSteepness}</p> 
                                        </div>    
                                        <div className='extended-row-description-tag-content light lower'>
                                            <p>{tagGrips}</p> 
                                        </div>
                                        <div className='extended-row-description-tag-content light lower'>
                                            <p>{tagStyle}</p> 
                                        </div>
                                    </div>
                                    <div className='statistic-row-container'>
                                        {color ?
                                            <StatisticRow 
                                                label='Prises'
                                                customComponent={
                                                    <ColorSquare
                                                    text={color.id.toString()}
                                                    customStyle={ color.colorCode}
                                                    isWhite={(color.id == 8|| color.id ==11 )? true:false}
                                                    additionalCustomStyle={'custom-extended-route-row-description-difficulty'}
                                                    isClicked={false}
                                                
                                                    />
                                                }
                                            />
                                            :
                                            <StatisticRow 
                                                label='Prises'
                                                customComponent={
                                                    <ColorSquare
                                                    text={difficultyId.toString()}
                                                    customStyle={ difficultyGymSystem.colorCode}
                                                    isWhite={false}
                                                    additionalCustomStyle={'custom-extended-route-row-description-difficulty'}
                                                    isClicked={false}
                                                
                                                    />
                                                }
                                            />
                                        }
                                        
                                        <StatisticRow 
                                            label='Niveau'
                                            customComponent={
                                                <DifficultySvg 
                                                    text={difficultyId.toString()}
                                                    svg={difficultySvg}
                                                    hasDifficultySystem={difficultySystem}
                                                    customStyle={'custom-extended-route-row-description-difficulty'}
                                                    isClicked={false}
                                                    customTextStyle=''

                                                />
                                            }
                                        />
                                        <StatisticRow 
                                            label='Sous-niveau'
                                            customComponent={
                                                <LevelDisplayer
                                                    difficultyGrading={difficultyGrading} 
                                                    difficultyGymSystemGrade={difficultyGrading} 
                                                />
                                            }
                                        />
                                        <StatisticRow 
                                            label='Ouverture'
                                            customComponent={
                                                <p className='bold lower'>{formatDate(openingDate)}</p>
                                            }
                                        />
                                        <StatisticRow 
                                            label='Démontage'
                                            customComponent={
                                                <p className='bold lower'>{ status ? formatDate(plannedDismantling) : formatDate(closedDate)}</p>
                                            }
                                        />
                                        <StatisticRow 
                                            label='Ouvreur'
                                            customComponent={
                                                <p className='bold lower'>{routeSetter}</p>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='detail-routes-modal-box-2'>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion'>
                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-title bold large2'>Statistiques du bloc</p>
                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Les statistiques sont détaillées pour les grimpeurs dont le niveau est celui du bloc</p>
                            </div>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-2'>
                                <div className='box-stats-container'>
                                    <div className='extended-row-description-detailed-caracteristics-box '>
                                        <p className='bold larger'>{totalTries ? totalTries : 0}</p>
                                        <p className='regular lower2'>Essais</p>
                                    </div>
                                    <div className='extended-row-description-detailed-caracteristics-box'>
                                        <p className='bold larger'>
                                            {
                                                totalTries ? (
                                                    // Calculate the tries per day as a floating point number
                                                    (totalTries / daysBetween(openingDate))
                                                ).toFixed(2).endsWith('.0') ? 
                                                    // If the string representation ends with '.0', remove the decimal part
                                                    (totalTries / daysBetween(openingDate)).toFixed(0) : 
                                                    // Otherwise, display one decimal place
                                                    (totalTries / daysBetween(openingDate)).toFixed(1)
                                                : 0
                                            }
                                        </p>
                                        <p className='regular lower2'>Essais / jour</p>
                                    </div>
                                    <div className='extended-row-description-detailed-caracteristics-box '>
                                        <p className='bold larger'>{difficultyDisplay}</p>
                                        <p className='regular lower2'>Cotation grimpeurs</p>
                                    </div>
                                </div>
                            </div>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3'>
                                <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                    <div>Tous grimpeurs</div>
                                    <div>(Grimpeurs {difficultyGymSystem.name})</div>
                                </div>
                                <div className='statistic-row-container'>
                                    <StatisticRow 
                                        label='Taux de réussite'
                                        customComponent={
                                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{successRate? formatRate(successRate) : '0%'}</p>  
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-2 bold larger'>({successRateBySameLevelClimbers? formatRate(successRateBySameLevelClimbers) : '0%'})</p>    
                                            </div>                                 
                                        }
                                    />
                                    <StatisticRow 
                                        label='Taux de flash'
                                        customComponent={
                                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{successFlashRate? formatRate(successFlashRate) : '0%'}</p>  
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-2 bold larger'>({successFlashRateBySameLevelClimbers? formatRate(successFlashRateBySameLevelClimbers) : '0%'})</p>    
                                            </div>                                 
                                        }
                                    />
                                    <StatisticRow 
                                        label='Essais pour la croix'
                                        customComponent={
                                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{
                                                    averageTriesBeforeTop ? (
                                                        // Calculate the tries per day as a floating point number
                                                        (averageTriesBeforeTop )
                                                    ).toFixed(1).endsWith('.0') ? 
                                                        // If the string representation ends with '.0', remove the decimal part
                                                        averageTriesBeforeTop : 
                                                        // Otherwise, display one decimal place
                                                        (averageTriesBeforeTop).toFixed(1)
                                                    : 0
                                                }</p>  

                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-2 bold larger'>(
                                                    {averageTriesBeforeTopBySameLevelClimber ? (
                                                    // Calculate the tries per day as a floating point number
                                                    (averageTriesBeforeTopBySameLevelClimber )
                                                ).toFixed(1).endsWith('.0') ? 
                                                    // If the string representation ends with '.0', remove the decimal part
                                                    averageTriesBeforeTopBySameLevelClimber : 
                                                    // Otherwise, display one decimal place
                                                    (averageTriesBeforeTopBySameLevelClimber).toFixed(1)
                                                : 0})</p>    
                                            </div>                                 
                                        }
                                    />
                                    <StatisticRow 
                                        label='Les étoiles'
                                        customComponent={
                                            <div className='bold lower' style={{display: 'flex',flexDirection:'row', gap:'4px'}}>
                                                <StarRating 
                                                    review={averageRanking}
                                                    styleText={"Pas d'avis"}
                                                />                                                
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{averageRankingCount !== 0? `(${averageRankingCount})` : ' '}</p>    
                                            </div>                                 
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', gap :'12px', alignItems:'flex-start',justifyContent:'flex-start'}}>
                                <CustomButton 
                                    Svg={SVGs.information}
                                />
                                <div className='regular lower2' style={{color:Colors.colorgrey600}}>Les données de couleur bleue clair permettent d’isoler les statistiques pour les grimpeurs du niveau du bloc</div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='detail-routes-modal-box'>
                        <div className='extended-row-title bold large2 ' style={{marginBottom:'20px'}}>
                            <p>Commentaires</p>
                        </div>
                        {(comments && comments.length>0) ? comments.map(comment => (
                            <CommentsCardSimple
                                key={comment.commentId}
                                boulderId={comment.boulderId}
                                comment={comment.comment}
                                commentId={comment.commentId}
                                createdAt={comment.createdAt}
                                firstNameUser={comment.firstNameUser}
                                lastNameUser={comment.lastNameUser}
                                profilePictureUser={comment.profilePictureUser}
                            />
                        ))
                        :
                        <div className='no-routes-msg light large2'>
                          Pas encore de commentaire  
                        </div>
                        }
                        </div>
                </div>
            </Modal>
            <Modal isOpen={changeDate} onClose={toggleChangeDate} titleModal='Modification date de démontage' width='fit-content' position='relative'>
                <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '32px 40px 32px 40px', alignItems:'center'}}>
                    <div className='modal-change-closing-date-container'>
                        <div className="form-change-closing-date-container">
                            <label htmlFor="demontage-date" className='bold normal' style={{color:Colors.colorbackoffice800}}>Date démontage</label>
                            <input
                            type="text"
                            id="demontage-date"
                            value={date}
                            onChange={handleDateChange}
                            placeholder="JJ/MM/AAAA"
                            
                            />
                            { <div className={error ?"error-change-date-message regular lower2" : "change-date-message regular lower2"}>
                                <CustomButton
                                    Svg={SVGs.information}
                                />
                                <div>La date doit être saisie au format JJ/MM/AAAA</div>
                            </div>}
                        </div>
                        <div className='checkbox-group-container'>
                            <div className='bold normal' style={{paddingLeft:'8px', color:Colors.colorbackoffice800}}>Blocs affectés</div>
                            <div className="checkbox-group regular lower" style={{color:Colors.colorgrey700}}>
                                <label className='checkbox-indiv '>
                                <input
                                    type="checkbox"
                                    checked={modifyBlock}
                                    onChange={() => {
                                        setModifyBlock(!modifyBlock)
                                        setModifySector(false)

                                    }}
                                />
                                Modifier seulement ce bloc
                                </label>
                                <label className='checkbox-indiv'>
                                <input
                                    type="checkbox"
                                    checked={modifySector}
                                    onChange={() => {
                                        setModifySector(!modifySector)
                                        setModifyBlock(false)
                                    }}
                                />
                                Modifier tout le secteur
                                </label>
                            </div>
                        </div>
                        <div className="modal-change-closing-date-button">
                            <CustomButton 
                                customStyle='modal-change-closing-date-button-confirm bold normal'
                                text='Confirmer'
                                disabled={!!error || date == ''}
                                onClickProp={handleConfirm}
                                customDisableStyle='modal-change-closing-date-button-confirm-disabled bold normal'
                            />
                             <CustomButton 
                                customStyle='modal-change-closing-date-button-cancel bold normal'
                                text='Retour'
                                onClickProp={()=>setChangeDate(false)}
                               
                            />
                            
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    )

}

export default SectorDetailsRow