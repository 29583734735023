import React, { useState,useEffect } from 'react';
import { csrftoken, daysBetween, formatDate, formatRate, getGradeRange } from '../../constants/helpers';
import { SVGs } from '../../constants/Svgs';
import { GymMinimalProps, RoutesDisplayInfoProps } from '../../constants/types';
import { fetchRouteImage } from '../../data/getAxiosRequests';
import ColorSquare from '../UI/ColorSquare';
import CustomButton from '../UI/CustomButton';
import DifficultySvg from '../UI/DifficultySvg'
import IconButton from '../UI/IconButton';
import LevelSelector from './LevelSelector';
import StarRating from '../UI/StarRating';
import Modal from '../UI/ModalRoute';
import StatisticRow from './StatisticRow';
import LevelDisplayer from './LevelDisplayer';
import { Colors } from '../../constants/Consts';
import CommentsCardSimple from './CommentsCardSimple';
import axios from 'axios';

interface RouteRowProps {
    route: RoutesDisplayInfoProps
    difficultySystem : boolean,
    isClicked? : boolean,
    customStyle? : string,
    customTextStyle? : string,
    onClickProp?: () => void
}


const RouteRow : React.FC<RouteRowProps> = ({ isClicked, customStyle, customTextStyle,route,onClickProp,difficultySystem }) => {
    const [isClickedStatus, setIsClickedStatus] =useState(isClicked)
    const [fetchedImages, setFetchedImages] = useState({}); // Store {idRoute: imageURL}
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isPositionSet, setIsPositionSet] = useState(false);
    const [extendedRow, setExtendedRow] = useState(false)
    const [changeDate, setChangeDate] = useState(false)

    const toggleChangeDate = () => {
        setChangeDate(prev => !prev); // Toggle the state of extendedRow
    };
    const [date, setDate] = useState('');
    const [modifyBlock, setModifyBlock] = useState(false);
    const [modifySector, setModifySector] = useState(true);
    const [error, setError] = useState('');


    const handleDateChange = (e) => {
        const value = e.target.value;
        setDate(value);
    
        // Validate the date format (DD/MM/YYYY)
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!datePattern.test(value)) {
          setError('La date doit être saisie au format JJ/MM/AAAA');
        } else {
          setError('');
        }
    };

    const handleConfirm = async () => {
        if (error) {
            alert('Veuillez corriger les erreurs avant de confirmer.');
            return;
        }

        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
            const response = await axios.post(`/api/update-route/${route.id}/`, {
                date,
                modifyBlock,
                modifySector
            });

            if (response.status === 200) {
                window.location.reload(); // Refresh the page on success
            }
        } catch (error) {
            console.error('Error updating route:', error);
        }
    };

    const toggleExtendedRow = () => {
        setExtendedRow(prev => !prev); // Toggle the state of extendedRow
    };
    

    const handleMouseOver = async (event) => {
        // Check if image is not already fetched
        if (!fetchedImages[route.id]) {
            try {
                const imageUrl = await fetchRouteImage(route.id);
                setFetchedImages((prevImages) => ({
                    ...prevImages,
                    [route.id]: imageUrl.url
                }));
               
            } catch (error) {
                // Handle any errors that might occur while fetching the image
                console.error('Error fetching the image in RouteRow', error);
            }
           
        }
        setPosition({
            x: difficultySystem ? 1670 : 1534,
            y: event.clientY
        });
        setIsPositionSet(true)
    }

    const handleMouseLeave = () => {
        setPosition({
            x:null,
            y:null
        })
        setIsPositionSet(false);
    }
   
    useEffect(() => {
        setIsClickedStatus(isClicked);
    }, [isClicked]); // This useEffect runs whenever 'isClicked' prop changes


    return (
        <div className='table-body__row__outer-container'>
            <div className="table-body__row"  onClick={()=>{
                toggleExtendedRow()
                onClickProp()
                
                }} >
                {/*
                <div className="row__gym lower2 regular">
                    {route.gym.name}
                </div>
                 */}
                <div className={difficultySystem ? "row__route-name lower2 regular": "row__route-name2 lower2 regular"}>
                    {route.name}
                </div>
                <div className={difficultySystem ? "row__sector lower2 regular":"row__sector2 lower2 regular"}>
                    {route.sector.name}
                </div>
               
                {difficultySystem && route.color &&
                <div className="row__color lower2 regular" >
                    <ColorSquare
                        text={route.color.id.toString()}
                        customStyle={ route.color.colorCode}
                        isWhite={(route.color.id == 8|| route.color.id ==11 )? true:false}
                    />
                </div>
                } 
                <div className={difficultySystem ? "row__color lower2 regular" : "row__color2 lower2 regular"} >
                    <DifficultySvg 
                        text={route.difficultyGymSystem.id.toString()}
                        svg={route.difficultyGymSystem.svg}
                        hasDifficultySystem={difficultySystem}
                        customStyle={'custom-extended-route-row-description-difficulty'}

                    />
                </div> 
                
                    {route.difficultyGrading < route.difficultyGymSystem.grade ?
                        <div className={difficultySystem ? "row__difficulty lower2 regular" : "row__difficulty2 lower2 regular"}>
                        <LevelSelector
                            isClicked={true}
                            text={'1'}
                            shouldShowFigure={false}
                        />
                        <LevelSelector
                            isClicked={false}
                            text={'2'}
                            shouldShowFigure={false}
                        />
                        <LevelSelector
                            isClicked={false}
                            text={'3'}
                            shouldShowFigure={false}
                        />
                        </div>
                        : route.difficultyGrading == route.difficultyGymSystem.grade ?
                        <div className={difficultySystem ? "row__difficulty lower2 regular" : "row__difficulty2 lower2 regular"}>
                        <LevelSelector
                            isClicked={false}
                            text={'1'}
                            shouldShowFigure={false}
                        />
                        <LevelSelector
                            isClicked={true}
                            text={'2'}
                            shouldShowFigure={false}
                        />
                        <LevelSelector
                            isClicked={false}
                            text={'3'}
                            shouldShowFigure={false}
                        />
                        </div>
                        :
                        <div className={difficultySystem ? "row__difficulty lower2 regular" : "row__difficulty2 lower2 regular"}>
                        <LevelSelector
                            isClicked={false}
                            text={'1'}
                            shouldShowFigure={false}
                        />
                        <LevelSelector
                            isClicked={false}
                            text={'2'}
                            shouldShowFigure={false}
                        />
                        <LevelSelector
                            isClicked={true}
                            text={'3'}
                            shouldShowFigure={false}
                        />
                        </div>
                    }
                
                <div className={difficultySystem ? "row__created-at lower2 regular": "row__created-at2 lower2 regular"}>
                    {formatDate(route.openingDate)} 
                </div>
                <div className={difficultySystem ? "row__tags lower2 regular":"row__tags2 lower2 regular"}>
                    {route.tagSteepness.name}, {route.tagGrips.name}, {route.tagStyle.name}
                </div>
                <div className={difficultySystem ?'row__status__container':'row__status__container2'}>
                    <div className={`${route.status? "row__status__open" : "row__status__close"} lower2 bold`}>
                        {route.status ? 'Actif' : 'Fermé'}
                    </div>
                    <div onClick={(e) => e.stopPropagation()}>
                        <CustomButton
                            text=''
                            Svg={SVGs.penEdit}
                            onClickProp={()=>setChangeDate(true)}
                        />
                    </div>
                </div> 
                <div className="table-header__empty-picture"></div>               
            </div>
            
           
            <Modal isOpen={extendedRow} onClose={toggleExtendedRow} titleModal='Détail bloc'>
            <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '32px 40px 32px 40px'}}>
                <div className='internal-modal-routes-container'>
                    
                        <div className='detail-routes-modal-box'>
                            <div className='extended-row-title bold large2 '>
                                <p>{route.name}</p>
                            </div>
                            <div className='extended-row-title-container-route-summary'>
                                <img src={route.picture} />
                                <div className='right-side-stats-container'>
                                    <div className='extended-row-description-tags-container'>
                                        <div className='extended-row-description-tag-content light lower'>
                                            <p >{route.tagSteepness.name}</p> 
                                        </div>    
                                        <div className='extended-row-description-tag-content light lower'>
                                            <p>{route.tagGrips.name}</p> 
                                        </div>
                                        <div className='extended-row-description-tag-content light lower'>
                                            <p>{route.tagStyle.name}</p> 
                                        </div>
                                    </div>
                                    <div className='statistic-row-container'>
                                        {difficultySystem &&
                                            <StatisticRow 
                                                label='Prises'
                                                customComponent={
                                                    <ColorSquare
                                                    text={route.color ? route.color.id.toString() : '11'}
                                                    customStyle={ route.color ? route.color.colorCode: 'white'}
                                                    isWhite={(route.color ? route.color.id == 8|| route.color.id ==11 : 11 )? true:false}
                                                    additionalCustomStyle={'custom-extended-route-row-description-difficulty'}
                                                    />
                                                }
                                            />
                                        }
                                        <StatisticRow 
                                            label='Niveau'
                                            customComponent={
                                                <DifficultySvg 
                                                    text={route.difficultyGymSystem.id.toString()}
                                                    svg={route.difficultyGymSystem.svg}
                                                    hasDifficultySystem={difficultySystem}
                                                    customStyle={'custom-extended-route-row-description-difficulty'}
                                                    isClicked={false}
                                                    customTextStyle=''

                                                />
                                            }
                                        />
                                        <StatisticRow 
                                            label='Sous-niveau'
                                            customComponent={
                                                <LevelDisplayer
                                                    difficultyGrading={route.difficultyGrading} 
                                                    difficultyGymSystemGrade={route.difficultyGymSystem.grade} 
                                                />
                                            }
                                        />
                                        <StatisticRow 
                                            label='Ouverture'
                                            customComponent={
                                                <p className='bold lower'>{formatDate(route.openingDate)}</p>
                                            }
                                        />
                                        <StatisticRow 
                                            label='Démontage'
                                            customComponent={
                                                <p className='bold lower'>{ route.status ? formatDate(route.plannedDismantling) : formatDate(route.closedDate)}</p>
                                            }
                                        />
                                        <StatisticRow 
                                            label='Ouvreur'
                                            customComponent={
                                                <p className='bold lower'>{route.routeSetter}</p>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='detail-routes-modal-box-2'>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion'>
                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-title bold large2'>Statistiques du bloc</p>
                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Les statistiques sont détaillées pour les grimpeurs dont le niveau est celui du bloc</p>
                            </div>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-2'>
                                <div className='box-stats-container'>
                                    <div className='extended-row-description-detailed-caracteristics-box '>
                                        <p className='bold larger'>{route.totalTries ? route.totalTries : 0}</p>
                                        <p className='regular lower2'>Essais</p>
                                    </div>
                                    <div className='extended-row-description-detailed-caracteristics-box'>
                                        <p className='bold larger'>
                                            {
                                                route.totalTries ? (
                                                    // Calculate the tries per day as a floating point number
                                                    (route.totalTries / daysBetween(route.openingDate))
                                                ).toFixed(2).endsWith('.0') ? 
                                                    // If the string representation ends with '.0', remove the decimal part
                                                    (route.totalTries / daysBetween(route.openingDate)).toFixed(0) : 
                                                    // Otherwise, display one decimal place
                                                    (route.totalTries / daysBetween(route.openingDate)).toFixed(1)
                                                : 0
                                            }
                                        </p>
                                        <p className='regular lower2'>Essais / jour</p>
                                    </div>
                                    <div className='extended-row-description-detailed-caracteristics-box '>
                                        <p className='bold larger'>{route.difficultyDisplay}</p>
                                        <p className='regular lower2'>Cotation grimpeurs</p>
                                    </div>
                                </div>
                            </div>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3'>
                                <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                    <div>Tous grimpeurs</div>
                                    <div>(Grimpeurs {route.difficultyGymSystem.name})</div>
                                </div>
                                <div className='statistic-row-container'>
                                    <StatisticRow 
                                        label='Taux de réussite'
                                        customComponent={
                                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{route.successRate? formatRate(route.successRate) : '0%'}</p>  
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-2 bold larger'>({route.successRateBySameLevelClimbers? formatRate(route.successRateBySameLevelClimbers) : '0%'})</p>    
                                            </div>                                 
                                        }
                                    />
                                    <StatisticRow 
                                        label='Taux de flash'
                                        customComponent={
                                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{route.successFlashRate? formatRate(route.successFlashRate) : '0%'}</p>  
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-2 bold larger'>({route.successFlashRateBySameLevelClimbers? formatRate(route.successFlashRateBySameLevelClimbers) : '0%'})</p>    
                                            </div>                                 
                                        }
                                    />
                                    <StatisticRow 
                                        label='Essais pour la croix'
                                        customComponent={
                                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-3-title bold lower'>
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{
                                                    route.averageTriesBeforeTop ? (
                                                        // Calculate the tries per day as a floating point number
                                                        (route.averageTriesBeforeTop )
                                                    ).toFixed(1).endsWith('.0') ? 
                                                        // If the string representation ends with '.0', remove the decimal part
                                                        route.averageTriesBeforeTop : 
                                                        // Otherwise, display one decimal place
                                                        (route.averageTriesBeforeTop).toFixed(1)
                                                    : 0
                                                }</p>  

                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-2 bold larger'>(
                                                    {route.averageTriesBeforeTopBySameLevelClimber ? (
                                                    // Calculate the tries per day as a floating point number
                                                    (route.averageTriesBeforeTopBySameLevelClimber )
                                                ).toFixed(1).endsWith('.0') ? 
                                                    // If the string representation ends with '.0', remove the decimal part
                                                    route.averageTriesBeforeTopBySameLevelClimber : 
                                                    // Otherwise, display one decimal place
                                                    (route.averageTriesBeforeTopBySameLevelClimber).toFixed(1)
                                                : 0})</p>    
                                            </div>                                 
                                        }
                                    />
                                    <StatisticRow 
                                        label='Les étoiles'
                                        customComponent={
                                            <div className='bold lower' style={{display: 'flex',flexDirection:'row', gap:'4px'}}>
                                                <StarRating 
                                                    review={route.averageRanking}
                                                    styleText={"Pas d'avis"}
                                                />                                                
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{route.averageRankingCount !== 0? `(${route.averageRankingCount})` : ' '}</p>    
                                            </div>                                 
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', gap :'12px', alignItems:'flex-start',justifyContent:'flex-start'}}>
                                <CustomButton 
                                    Svg={SVGs.information}
                                />
                                <div className='regular lower2' style={{color:Colors.colorgrey600}}>Les données de couleur bleue clair permettent d’isoler les statistiques pour les grimpeurs du niveau du bloc</div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='detail-routes-modal-box'>
                        <div className='extended-row-title bold large2 ' style={{marginBottom:'20px'}}>
                            <p>Commentaires</p>
                        </div>
                        {(route.comments && route.comments.length>0 ) ? route.comments.map(comment => (
                            <CommentsCardSimple
                                key={comment.commentId}
                                boulderId={comment.boulderId}
                                comment={comment.comment}
                                commentId={comment.commentId}
                                createdAt={comment.createdAt}
                                firstNameUser={comment.firstNameUser}
                                lastNameUser={comment.lastNameUser}
                                profilePictureUser={comment.profilePictureUser}
                            />
                        ))
                        :
                        <div className='no-routes-msg light large2'>
                          Pas encore de commentaire  
                        </div>
                        }
                        </div>
                </div>
            </Modal>
            <Modal isOpen={changeDate} onClose={toggleChangeDate} titleModal='Modification date de démontage' width='fit-content' position='relative'>
                <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '32px 40px 32px 40px', alignItems:'center'}}>
                    <div className='modal-change-closing-date-container'>
                        <div className="form-change-closing-date-container">
                            <label htmlFor="demontage-date" className='bold normal' style={{color:Colors.colorbackoffice800}}>Date démontage</label>
                            <input
                            type="text"
                            id="demontage-date"
                            value={date}
                            onChange={handleDateChange}
                            placeholder="JJ/MM/AAAA"
                            
                            />
                            { <div className={error ?"error-change-date-message regular lower2" : "change-date-message regular lower2"}>
                                <CustomButton
                                    Svg={SVGs.information}
                                />
                                <div>La date doit être saisie au format JJ/MM/AAAA</div>
                            </div>}
                        </div>
                        <div className='checkbox-group-container'>
                            <div className='bold normal' style={{paddingLeft:'8px', color:Colors.colorbackoffice800}}>Blocs affectés</div>
                            <div className="checkbox-group regular lower" style={{color:Colors.colorgrey700}}>
                                <label className='checkbox-indiv '>
                                <input
                                    type="checkbox"
                                    checked={modifyBlock}
                                    onChange={() => {
                                        setModifyBlock(!modifyBlock)
                                        setModifySector(false)

                                    }}
                                />
                                Modifier seulement ce bloc
                                </label>
                                <label className='checkbox-indiv'>
                                <input
                                    type="checkbox"
                                    checked={modifySector}
                                    onChange={() => {
                                        setModifySector(!modifySector)
                                        setModifyBlock(false)
                                    }}
                                />
                                Modifier tout le secteur
                                </label>
                            </div>
                        </div>
                        <div className="modal-change-closing-date-button">
                            <CustomButton 
                                customStyle='modal-change-closing-date-button-confirm bold normal'
                                text='Confirmer'
                                disabled={!!error || date == ''}
                                onClickProp={handleConfirm}
                                customDisableStyle='modal-change-closing-date-button-confirm-disabled bold normal'
                            />
                             <CustomButton 
                                customStyle='modal-change-closing-date-button-cancel bold normal'
                                text='Retour'
                                onClickProp={()=>setChangeDate(false)}
                               
                            />
                            
                        </div>
                    </div>
                </div>

            </Modal>
            
            
        </div>
        

   
    )
}


export default RouteRow