import React from 'react';

import { Grip } from '../../constants/Consts';

interface GripIconProps {
    colorCode: string;
    customStyle?: React.CSSProperties;
}

const GripIcon: React.FC<GripIconProps> = React.memo(({ colorCode, customStyle }) => {
    return (
        <div style={{ ...customStyle }}>
            <div className="colorDiv" style={{ ...customStyle }}>
                <Grip colorCode={colorCode} />
            </div>
        </div>
    );
});

export default GripIcon;