import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';

interface CustomButtonBOProps {

    text:string
    isClicked? : boolean,
    customStyle? : string,
    customTextStyle? : string,
    onClickProp?: () => void
    Svg? : React.FC

}


const CustomButtonBO : React.FC<CustomButtonBOProps> = ({ isClicked, customStyle, customTextStyle,text,onClickProp,Svg }) => {
    const [isClickedStatus, setIsClickedStatus] =useState(isClicked)
   
    useEffect(() => {
        setIsClickedStatus(isClicked);
    }, [isClicked]); // This useEffect runs whenever 'isClicked' prop changes
    const baseClasses = ["toggle-filter", isClickedStatus ? "blue" : "grey", "bold", "lower2"];
    if (customStyle) {
        baseClasses.push(customStyle);
    }
    if(customTextStyle) {
        baseClasses.push(customTextStyle)
    }

    return (
        <div className={baseClasses.join(' ')} onClick={onClickProp}>
            {text}
            {Svg && <Svg />}
        </div>
    );

}


export default CustomButtonBO