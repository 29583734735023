import React, { useEffect } from 'react';
import { DifficultyCircuitHeader } from '../../constants/types';
import { Colors } from '../../constants/Consts';
import DifficultySummaryCircuitItem from './DifficultySummaryCircuit';

interface DifficultySummaryCircuitProps {
    difficultyheaders: DifficultyCircuitHeader[];
    totalBoulderCount: number;
}

const DifficultySummaryCicuit: React.FC<DifficultySummaryCircuitProps> = ({ difficultyheaders, totalBoulderCount }) => {
    const maxRatio = difficultyheaders.reduce((max, header) => {
        const currentRatio = header.boulderCount / totalBoulderCount;
        return currentRatio > max ? currentRatio : max;
    }, 0);

    

    return (
        <div className='difficulty-summary-item-container'>
            <div className='difficulty-summary-header' style={{ marginBottom: '4px' }}>
                <div className='difficulty-summary-item-container-name-container' />
                <div className='bold large3' style={{ color: Colors.colorgrey600 }}>
                    {totalBoulderCount ? totalBoulderCount > 1 ? `${totalBoulderCount} blocs` : `${totalBoulderCount} bloc`:''}
                </div>
            </div>

            {difficultyheaders && difficultyheaders
                 // Sort by difficultyGrade
                .map((difficultyheader, index) => {
                    const widthPercentage = ((difficultyheader.boulderCount / totalBoulderCount) / maxRatio) * 100;
                    return (
                        <div className='difficulty-summary-header' style={{width:`${widthPercentage}%`}} key={index}>
                            
                            {difficultyheader.boulderCount !== 0 &&
                                <DifficultySummaryCircuitItem
                                    key={index}
                                    difficultyheader={difficultyheader}
                                    totalRoutes={totalBoulderCount}
                                    width={widthPercentage}
                                />
                               
                            }
                        </div>
                    )
                })}
        </div>
    )
}

export default DifficultySummaryCicuit;