import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';

interface CustomButtonProps {

    text?:string
    isClicked? : boolean,
    customStyle? : string,
    customTextStyle? : string,
    onClickProp?: () => void; // Allow event parameter
    onClickPropEvent?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void; // Allow event parameter

    Svg? : React.FC
    disabled?: boolean
    customDisableStyle?: string
    customDisableTextStyle?: string
    LeftSvg ?: React.FC
    pointerDisabled ? : boolean

}


const CustomButton : React.FC<CustomButtonProps> = ({isClicked,pointerDisabled, customStyle, customTextStyle,text,onClickProp,Svg, disabled,customDisableStyle,customDisableTextStyle,LeftSvg }) => {
    const [isClickedStatus, setIsClickedStatus] =useState(isClicked)
   
    useEffect(() => {
        setIsClickedStatus(isClicked);
    }, [isClicked]); // This useEffect runs whenever 'isClicked' prop changes


    const baseClasses = [ ];
    if (disabled){
        if (customDisableStyle) {
            baseClasses.push(customDisableStyle) 
        }
        if(customDisableTextStyle) {
            baseClasses.push(customDisableTextStyle)
        }
    }else{
        if (customStyle) {
            baseClasses.push(customStyle);
        }
        if(customTextStyle) {
            baseClasses.push(customTextStyle)
        }
    }
    


    const handleClick = () => {
        if (!disabled && onClickProp) {
            setIsClickedStatus(true)
            onClickProp()
            setIsClickedStatus(false)

        }
    };

    return (
        <div className={baseClasses.join(' ')} onClick={handleClick} style={{ cursor: (disabled || pointerDisabled) ? 'default' : 'pointer', opacity: (disabled || isClickedStatus) ? 0.5 : 1}}>
            {LeftSvg && <LeftSvg />}
            {text}
            {Svg && <Svg />}
        </div>
    );
};

export default CustomButton;

