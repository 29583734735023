import React from 'react';
import ReactDOM from 'react-dom';
import CustomButton from './CustomButton';
import { SVGs } from '../../constants/Svgs';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    titleModal : string
    width?: string; // Optional width prop
    position? : string

}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, titleModal,width, position }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="modal-overlay">
            <div className="modal" style={{ width: width || '80%' }}> 
                <div className='modal-title-container' style={position &&{position: 'relative', width:'100%'  }}>
                  <div className='bold large'>{titleModal}</div>
                  <CustomButton 
                    customStyle="modal-close" 
                    onClickProp={onClose}
                    Svg={SVGs.modalCloseCross}
                  
                  />
                </div>  
                {children}
            </div>
        </div>,
        document.getElementById('modal-root') // Make sure you have an element with id 'modal-root' in your HTML
    );
};

export default Modal;