import React, { useState } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async'



const AutocompleteInput = ({ items, placeholder,onSelect,name }) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isFocused, setIsFocused] = useState(false); // Track focus state

    const handleChange = (e) => {
      const value = e.target.value;
      setInputValue(value);
      if (value.length > 0) {
        const filteredSuggestions = items
          .filter(item => item.name.toLowerCase().includes(value.toLowerCase()))
          .slice(0, 5); // get first 5 matches
        setSuggestions(filteredSuggestions);
        onSelect(value)
      } else {
        setSuggestions([]);
        onSelect('')
      }
    };
  
    const handleSuggestionClick = (name) => {
      setInputValue(name);
      setSuggestions([]);
      setIsFocused(false); // Hide suggestions on selection
      onSelect(name)
    };

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      // Use a timeout to delay setting focus state to false
      // This allows click event on suggestions to fire
      setTimeout(() => {
        setIsFocused(false);
      }, 200);
    
    };
  
    return (
      <div className='autocomplete-container'>
        <input
          name={name}
          className='input-select-gym'
          type="text"
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder || 'Start typing...'}
        />
        {isFocused && suggestions.length > 0 && (
          <ul className='autocomplete-dropdown'>
            {suggestions.map((item) => (
              <li key={item.id} onClick={() => handleSuggestionClick(item.name)}>
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
};

export default AutocompleteInput;