import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';

interface CheckMarkButtonProps {
    isChecked : boolean,
    text:string
    onToggle?: (newStatus: boolean) => void,
    customStyle? : string,
    customTextStyle? : string,

}


const CheckMarkButton : React.FC<CheckMarkButtonProps> = ({ isChecked, customStyle, customTextStyle,text,onToggle }) => {

    const baseClasses = [ ];

    if (customStyle) {
        baseClasses.push(customStyle);
    }


    const handleClick = () => {
        if (onToggle) onToggle(!isChecked); // notify parent
    }

    return (
        <div className={`all-filter ${baseClasses.join(' ')}`} onClick={handleClick}>
            {isChecked ? <SVGs.checkMarked /> : <SVGs.checkNotMarked/>}
            <div className="lower regular">
                {text}
            </div>
        </div>
    )
}


export default CheckMarkButton