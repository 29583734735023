import React, { useState,useEffect, useContext } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { DifficultyCircuitHeader, DifficultyHeader } from '../../constants/types';
import { Colors } from '../../constants/Consts';
import DifficultySvg from '../UI/DifficultySvg';
import { GlobalContext } from '../../context/GlobalStateProvider';
import LevelDisplayerDirect from '../backOffice/LevelDisplayerDirect';
import LevelDisplayerDirect2 from '../backOffice/LevelDisplayerDirect2';
import StatBox from '../backOffice/StatBox';
import { useNavigate } from 'react-router-dom';

interface DifficultySummaryCircuitItemStatsProps {
    difficultyheader: DifficultyCircuitHeader;
   

}

const DifficultySummaryCircuitItemStats: React.FC<DifficultySummaryCircuitItemStatsProps> = ({ difficultyheader }) => {
    const globalContext = useContext(GlobalContext);
    const [isHovered, setIsHovered] = useState(false);
    const navigate  = useNavigate()
    const { currentGym,setLastDifficultyChecked } = globalContext;


    
      

    return (
        <div
            className='difficulty-circuit-stats-item-container-visual'
            style={{backgroundColor: isHovered ? Colors.colorbackoffice25 : 'transparent'}}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={()=>{navigate('/circuits-details'); setLastDifficultyChecked(difficultyheader)}}
        >
            <div style={{display:'flex', flexDirection:'row', gap: '8px', minWidth:'160px',maxWidth:'160px'}}>
                <div style={{borderRadius:'4px'}}>
                    <DifficultySvg
                        svg={difficultyheader.difficultySplit.svg}
                        text=''
                        hasDifficultySystem={currentGym.hasCustomDifficultySystem}
                    />
                </div>
                <div className='regular normal' style={{color:Colors.colorbackoffice800,flexWrap:'wrap'}}>{difficultyheader.difficultySplit.difficultyName}</div>
            </div>
            <div className='vertical-best-boulder-component-line'/>
            <div style={{display:'flex', flexDirection:'row', gap: '20px', alignItems:'center'}}>
                <StatBox 
                    currentMonthStat={difficultyheader.triesTotal}
                    lastMonthStat={difficultyheader.triesTotalLastMonth}
                    text='Essais'
                    additionalStyle={!isHovered &&'box-stat-container-hovered'}
                />
                <StatBox 
                    currentMonthStat={difficultyheader.triesPerDay}
                    lastMonthStat={difficultyheader.triesPerDayLastMonth}
                    text='Essais / jour'
                    additionalStyle={!isHovered &&'box-stat-container-hovered'}
                />
                <StatBox 
                    currentMonthStat={difficultyheader.successRate}
                    lastMonthStat={difficultyheader.successRateLastMonth}
                    text='Tx de réussite'
                    isPercentage
                    additionalStyle={!isHovered &&'box-stat-container-hovered'}
                />
                <StatBox 
                    currentMonthStat={difficultyheader.activeClimbers}
                    lastMonthStat={difficultyheader.activeClimbersLastMonth}
                    text='Grimpeurs actifs'
                    additionalStyle={!isHovered &&'box-stat-container-hovered'}
                />
            </div>
            <CustomButton 
                LeftSvg={isHovered ? SVGs.plusBlueButton : SVGs.plusGreyButton}
                text='Détail circuit'
                customStyle={isHovered ? 'detail-circuit-stat-hovered-button regular lower' : 'detail-circuit-stat-button regular lower'}
            />
        </div>
    );
};

export default DifficultySummaryCircuitItemStats;