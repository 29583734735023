import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { DifficultyCircuitHeader, GymExtraMinimalProps, GymMinimalProps, RouteSetterProps } from '../constants/types';
import { pages } from '../constants/Consts';
import { getRouteSetterGymFilterInfo, getRouteSetterUserInfo } from '../data/getAxiosRequests';

interface GlobalContextProps {
    user: string | null;
    setUser: React.Dispatch<React.SetStateAction<string | null>>;
    currentGym : GymMinimalProps | null 
    setCurrentGym : React.Dispatch<React.SetStateAction<GymMinimalProps | null>>;
    currentPage : string | null
    setCurrentPage : React.Dispatch<React.SetStateAction<string | null>>;
    gymsFilterInfo : GymMinimalProps[] | undefined,
    setGymsFilterInfo : React.Dispatch<React.SetStateAction<GymMinimalProps[] | undefined>>;
    routeSetterInfo :  RouteSetterProps |undefined
    setRouteSetterInfo : React.Dispatch<React.SetStateAction< RouteSetterProps |undefined>>;
    lastDifficultyChecked : DifficultyCircuitHeader | undefined
    setLastDifficultyChecked :  React.Dispatch<React.SetStateAction< DifficultyCircuitHeader |undefined>>;
    
    // Add other global state variables and their setters here
}

export const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<string | null>(null);
    const [currentGym, setCurrentGym] = useState <GymMinimalProps | null > (null)
    const [currentPage, setCurrentPage ] = useState<string | null>(null);
    const [gymsFilterInfo, setGymsFilterInfo] = useState<GymMinimalProps[] | undefined >([])
    const [routeSetterInfo, setRouteSetterInfo] = useState<RouteSetterProps | undefined >(undefined)
    const [lastDifficultyChecked, setLastDifficultyChecked] = useState<DifficultyCircuitHeader | undefined>(undefined)




    useEffect(()=>{
        if (user){
            getRouteSetterGymFilterInfo(user).then(data => {
            setGymsFilterInfo(data)})
            getRouteSetterUserInfo(user).then(data => {
            setRouteSetterInfo(data)})
        }
        

    },[user])
    // Add other state variables here

    useEffect(()=>{
        const userUuid = localStorage.getItem('user')
        if(userUuid){
            setUser(JSON.parse(userUuid))
        }
    },[])


    const values ={
        user,
        setUser,
        currentGym,
        setCurrentGym,
        currentPage,
        setCurrentPage,
        gymsFilterInfo,
        setGymsFilterInfo,
        routeSetterInfo,
        setRouteSetterInfo,
        lastDifficultyChecked, 
        setLastDifficultyChecked
    }
    return (
        <GlobalContext.Provider value={values}>
            {children}
        </GlobalContext.Provider>
    );
};