import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { getDifficultyCircuitDetails, getDifficultyCircuitHeaders, getGymGeneralStats, getRouteSetterGymFilterInfo, getRouteSetterUserInfo, getSectorDetails, getSectorDetailsById, getSectorsOverview, ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken, formatDate, formatDateRange, getDaysDifferenceMessage, getGradeFromIndex } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButton';

import NavBarBackOffice from '../components/backOffice/Navbar';
import { DifficultyCircuitFullDetails, DifficultyCircuitHeader, DifficultySplit, GymMinimalProps, GymStats, RouteSetterProps, SectorBasisChoices, SectorCategory, SectorDetailsProps, SectorsOverview } from '../constants/types';
import { GlobalContext } from '../context/GlobalStateProvider';
import Modal from '../components/UI/ModalRoute';
import { Colors, pages } from '../constants/Consts';
import StatBox from '../components/backOffice/StatBox';
import DifficultySummaryHome from '../components/HomePage/DifficultySummaryHome';
import SectorSummaryRow from '../components/backOffice/SectorSummaryRow';
import SectorItem from '../components/UI/SectorItem';
import StatBoxSector from '../components/backOffice/StatBoxSector';
import StatisticRow from '../components/backOffice/StatisticRow';
import StarRating from '../components/UI/StarRating';
import DifficultySplitComponent from '../components/UI/DifficultySplit';
import SectorDetailsRow, { SectorDetailsRowProps } from '../components/backOffice/SectorDetailsRow';
import CommentsCard from '../components/backOffice/CommentsCard';
import { TailSpin } from 'react-loader-spinner';
import DifficultySummaryCicuit from '../components/HomePage/DifficultySummaryCircuitHome';
import DifficultySummaryCircuitItemStats from '../components/HomePage/DifficultySummaryCircuitStats';
import DifficultySummaryCircuitItem from '../components/HomePage/DifficultySummaryCircuit';
import { BestBouldersList } from './HomePageBO';





const CircuitsDetailsPage: React.FC = () => {
    const navigate  = useNavigate()
    
    const globalContext = useContext(GlobalContext);
    const [shouldBeLoading, setShouldBeLoading] = useState(true)
    const [DifficultyFocused, setDifficultyFocused] =useState<DifficultyCircuitFullDetails>(null) 


    

    

    
    

    const { currentGym, setCurrentGym,setCurrentPage,currentPage,setGymsFilterInfo,gymsFilterInfo,user,setUser,routeSetterInfo, setRouteSetterInfo,lastDifficultyChecked,setLastDifficultyChecked } = globalContext;


    useEffect(() => {
        document.title = "Circuits"
    },[])

    useEffect(() => {
        if (!currentGym) {
            const favoriteGym = localStorage.getItem('favoriteGymInfo');
            setCurrentGym(JSON.parse(favoriteGym))
        }
        if (!user) {
            const loggedUser = localStorage.getItem('user');
            if (loggedUser) {
                setUser(JSON.parse(loggedUser));
            } else {
                navigate('/login-pro');
            }
        }
        if(!lastDifficultyChecked){
            navigate('/circuits-overview');
        }
    }, []);

    useEffect(() => {
        setCurrentPage(pages.circuits)
    }, []);

    useEffect(() => {
        if (user && currentGym) {
            getRouteSetterUserInfo(user).then(data => {
                setRouteSetterInfo(data);
            });
        }
        if(user && currentGym && lastDifficultyChecked) {
            getDifficultyCircuitDetails(currentGym.id, lastDifficultyChecked.difficultySplit.id).then((data) => {
                setLastDifficultyChecked(data);
                setDifficultyFocused(data)
                setShouldBeLoading(false)
            });
           
        }else {
            console.error("user not provided again");
        }
    }, [user, currentGym, lastDifficultyChecked]);

    return (
        <div className='outerContainer'>
            <NavBarBackOffice
                profilePicture={routeSetterInfo?.picture}
                firstName={routeSetterInfo?.firstName}
                lastName={routeSetterInfo?.lastName}
                gyms={currentGym && currentGym.name}
            />
            <div className="bo-main-content">
                {shouldBeLoading && 
                    <div className="loading-overlay">
                        <TailSpin color="#0099CC" height={80} width={80} />
                    </div>
                }   
                <div className="bo-main-content__title">
                    <CustomButton
                        Svg={SVGs.bigChevronLeftBack}
                        customStyle='gap-16'
                        onClickProp={()=>navigate('/circuits-overview')}
                    />
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 2H22.3333M1.5 5.33333H16.7244M1.5 8.66667H19.1282M1.5 12H9.51282M1.5 15.3333H15.1218M1.5 18.6667H22.3333M1.5 22H18.3269" stroke="#344054" stroke-linecap="round"/>
                    </svg>
                        <div className="bold large3" >
                            <p>Vue par circuit / difficulté</p>
                        </div>
                    </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Répartition des blocs par difficulté</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Les blocs sont détaillés par niveau et sous-niveau</div>
                    </div>
                    {lastDifficultyChecked &&
                        <DifficultySummaryCircuitItem
                            difficultyheader={lastDifficultyChecked}
                            totalRoutes={lastDifficultyChecked.boulderCount}
                            width={100}
                            activatedByDefault
                        />
                    }
                </div>
                <div className='sectors-presentation-container'>
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Statistiques {lastDifficultyChecked && lastDifficultyChecked.difficultySplit.difficultyName}</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Quelques données sur la fréquentation du circuit comparées au circuit précédent</div>
                    </div>
                    {DifficultyFocused &&
                        <div style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                            
                            <div style={{display: 'flex', flexDirection:'row', gap:'16px'}}>
                                
                                <StatBox
                                    currentMonthStat={DifficultyFocused.triesTotal}
                                    lastMonthStat={DifficultyFocused.triesTotalLastMonth}
                                    text='Essais'
                                />
                                <StatBox
                                    currentMonthStat={parseFloat(DifficultyFocused.triesPerDay.toFixed(1))}
                                    lastMonthStat={parseFloat(DifficultyFocused.triesPerDayLastMonth.toFixed(1))}
                                    text='Essais / jour'
                                />
                                <StatBoxSector
                                    currentMonthStat={DifficultyFocused.averageGrade}
                                    lastMonthStat={DifficultyFocused.averageGradeLastMonth}
                                    text='Cotation moyenne'
                                />
                                
                            </div>
                        
                            <div style={{display:'flex',flexDirection:'column', gap:'8px'}}>
                                <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey700, alignSelf:'flex-end'}}>Circuit ({formatDateRange()}) <p style={{color:Colors.colorbackoffice500}}>(Ancien circuit)</p></div>
                                    <div style={{display:'flex',flexDirection:'column', gap:'4px'}}>
                                        <StatisticRow 
                                            customComponent={
                                            <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey500}}>
                                                {DifficultyFocused.successRate.toFixed(0)}%
                                                <p style={{color:Colors.colorbackoffice500}}>({DifficultyFocused.successRateLastMonth.toFixed(0)}%)</p>
                                            </div>
                                            }
                                            label={'Taux de réussite'}
                                        />
                                        <StatisticRow 
                                            customComponent={
                                            <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey500}}>
                                                {(DifficultyFocused.flashRate*100).toFixed(0)}%
                                                <p style={{color:Colors.colorbackoffice500}}>({(DifficultyFocused.flashRateLastMonth*100).toFixed(0)}%)</p>
                                            </div>
                                            }
                                            label={'Taux de flash'}
                                        />
                                        <StatisticRow 
                                            customComponent={
                                            <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey500}}>
                                                {DifficultyFocused.averageTriesBeforeTop && DifficultyFocused.averageTriesBeforeTop.toFixed(1)}
                                                <p style={{color:Colors.colorbackoffice500}}>{DifficultyFocused.averageTriesBeforeTopLastMonth && (
                                                    `(${DifficultyFocused.averageTriesBeforeTopLastMonth.toFixed(1)})`
                                                )}</p>
                                            </div>
                                            }
                                            label={'essais pour la croix'}
                                        />
                                        <StatisticRow 
                                            customComponent={
                                            <div className='bold lower' style={{display: 'flex',flexDirection:'row', gap:'4px'}}>
                                                <StarRating 
                                                    review={DifficultyFocused.rank}
                                                    styleText={"Pas d'avis"}
                                                />                                                
                                                <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{DifficultyFocused.rankCount !== 0? `(${DifficultyFocused.rankCount})` : ' '}</p>    
                                            </div>                                 
                                            }
                                            label={'Les étoiles'}
                                        />
                                    </div>
                            </div>
                        </div>
                        
                    } 
                </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Les blocs du circuit</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Classés par le nombre d’essais par jour</div>
                    </div>  
                    {DifficultyFocused && DifficultyFocused.boulders && <BestBouldersList  boulders={DifficultyFocused.boulders.sort((a, b) => b.triesPerDay - a.triesPerDay)}  />}
                </div>
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Commentaires</div>
                    </div>
                    <div style={{display: 'flex', flexDirection:'column',gap :'16px', width:'100%'}}> 
                        {(DifficultyFocused && DifficultyFocused.comments && DifficultyFocused.comments.length > 0) ?
                            DifficultyFocused.comments.map((comment)=>
                                <CommentsCard 
                                    profilePictureUser={comment.profilePictureUser}
                                    boulderId={comment.boulderId}
                                    boulderName={comment.boulderName}
                                    boulderPicture={comment.boulderPicture}
                                    comment={comment.comment}
                                    commentId={comment.commentId}
                                    createdAt={comment.createdAt}
                                    difficultySvg={comment.difficultySvg}
                                    firstNameUser={comment.firstNameUser}
                                    lastNameUser={comment.lastNameUser}
                                    level={comment.level}
                                    sectorName={comment.sectorName}
                                />
                            )
                            :
                            <div className='no-routes-msg light large2'>
                                Pas encore de commentaire  
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CircuitsDetailsPage;

