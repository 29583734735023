import React from 'react';
import LevelSelector from './LevelSelector'; // Ensure the correct path to your LevelSelector component

interface LevelDisplayerDirectProps {
    level : number
}

const LevelDisplayerDirect2: React.FC<LevelDisplayerDirectProps> = ({ level}) => {
    const renderLevelSelectors = () => {
        if (level == -1) {
            return (
                <>
                    <LevelSelector isClicked={true} text={'1'} shouldShowFigure={false} customSize={[12,12]} />
                    <LevelSelector isClicked={false} text={'2'} shouldShowFigure={false}  customSize={[12,12]} />
                    <LevelSelector isClicked={false} text={'3'} shouldShowFigure={false} customSize={[12,12]} />
                </>
            );
        } else if (level == 0) {
            return (
                <>
                    <LevelSelector isClicked={false} text={'1'} shouldShowFigure={false}  customSize={[12,12]}/>
                    <LevelSelector isClicked={true} text={'2'} shouldShowFigure={false} customSize={[12,12]} />
                    <LevelSelector isClicked={false} text={'3'} shouldShowFigure={false} customSize={[12,12]} />
                </>
            );
        } else {
            return (
                <>
                    <LevelSelector isClicked={false} text={'1'} shouldShowFigure={false} customSize={[12,12]} />
                    <LevelSelector isClicked={false} text={'2'} shouldShowFigure={false}  customSize={[12,12]}/>
                    <LevelSelector isClicked={true} text={'3'} shouldShowFigure={false}  customSize={[12,12]}/>
                </>
            );
        }
    };

    return (
        <div className={"circuit-level-displayer normal regular"} style={{ justifyContent: 'flex-stard' }}>
            {renderLevelSelectors()}
        </div>
    );
};

export default LevelDisplayerDirect2;