import axios from 'axios';
import React, { useState, FormEvent } from 'react';
import { ApiLoginResponse, BestBoulderCard, CommentBouldersSector, DifficultyCircuitFullDetails, DifficultyCircuitHeader, GymMinimalProps, GymStats, PictureRouteImg, RankingLineProps, RoutesDisplayInfoProps, RouteSetterAllRouteInfoProps, RouteSetterProps, RouteSetters, SectorBasisChoices, SectorDetailsProps, SectorsOverview, TagsList } from '../constants/types';
import { useNavigate } from 'react-router-dom'
import { csrftoken, processClimbers } from '../constants/helpers';

export const IpJerem = '192.168.1.48'
export const vivoIpAdress = '192.168.43.45'
export const DrancyIpAddress = '192.168.1.187'
export const celineIPaddress = '192.168.1.48'
export const marineIPAdress = '192.168.1.45'
export const  ipAdressTestKubernetes = 'rotpunkttest.fr'
export const ipLocalHost = ipAdressTestKubernetes
const devMode = true
const fork8s = true
export const hostedServer = 'rotpunkt-trial.herokuapp.com'
export const localIpAdress = devMode ? ipLocalHost : hostedServer
export const hasAdditionnalDifficultySystem = true
export const httpLocal = fork8s ? 'https':'http'
export const httpSecured ='https'
export const httpToUse = devMode ? httpLocal : httpSecured
export const portLocal = fork8s ? '' : ':8000'
export const portHosted =''
export const portToUse = devMode ? portLocal : portHosted
export const ipAdress= vivoIpAdress

export const addEmailAdressReminder = async (
    climbing_gym: string,
    email:string
    

    ) =>{
        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken
            const response = await axios.post(`${httpToUse}://${localIpAdress}${portToUse}/api/potentialnewuser/`, {climbing_gym,email})
            return response; // Make sure to return the response so that you can use it in the calling function
        } catch (error) {
          console.error(error);
          throw error; // Rethrow the error to be caught by the caller
        }
};

export const passwordForgottenRequest = async (
    username: string,
    onSuccess: () => void,
    onError: (error: string) => void
    ) =>{
        const formattedEmail = username.toLowerCase().trim()
        const payload = {
            email: formattedEmail,
            
          };
        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
            const response = await axios.post(`${httpToUse}://${localIpAdress}${portToUse}/api/sendemailresetpassword/`, payload);

            // Assuming successful login
            if (response) {
                onSuccess()
            }
        } catch (error) {
            if (error.response && error.response.data.error) {
                // If the error is from the backend and contains the expected data
                onError(error.response.data.error);
            } else {
                // For unexpected errors (network issues, server down, etc.)
                onError('Une Erreur est survenue. Veuillez nous contacter sur Instagram');
            }
        
        }
    }

export const loginUser = async (  
    username: string,
    password: string,
    onSuccess: (uuid: string) => void,
    onError: (error: string) => void
  ) => {
    try {
        axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
        const response = await axios.post<ApiLoginResponse>(`${httpToUse}://${localIpAdress}${portToUse}/api/login/`, { username, password });

        // Assuming successful login
        if (response.data.message && response.data.uuid) {
            localStorage.setItem('userUUID', response.data.uuid);
            onSuccess(response.data.uuid);
        }
    } catch (error) {
        if (error.response && error.response.data.error) {
            // If the error is from the backend and contains the expected data
            onError(error.response.data.error);
        } else {
            // For unexpected errors (network issues, server down, etc.)
            onError('Une Erreur est survenue. Veuillez nous contacter sur Instagram');
        }
    }
}

export const getRouteSetterInfo = async (
    routeSetterUuid : string,
    startList : number,
    endList : number,
    sortGym : number,
    sortSectors:number,
    sortDifficulty : number,
    sortLevel : number,
    sortStatus : number,
    sortName : number,
    sortDate : number,
    filterGym?:number[],
    filterDifficulty?:number[],
    filterLevel?:number[],
    filterTagSteepness?:number[],
    filterTagGrips?:number[],
    filterTagStyle?:number[],
    filterStatus?:number[],
    filterSector?:number[],
    
    ) : Promise<RouteSetterAllRouteInfoProps> => {
        try {
            const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/routesettergyminfos/${routeSetterUuid}/${startList}/${endList}/${filterGym}/${filterDifficulty}/${filterLevel}/${filterTagSteepness}/${filterTagGrips}/${filterTagStyle}/${filterStatus}/${filterSector}/${sortGym}/${sortSectors}/${sortDifficulty}/${sortLevel}/${sortStatus}/${sortName}/${sortDate}`)
            
            const routes : RoutesDisplayInfoProps [] = response.data.routes.map((route: any) => ({
                id: route.id,
                name: route.name,
                picture: route.picture,
                sector: {
                    id : route.sector.id,
                    name : route.sector.name
                },
                color: route.color ? {
                    id : route.color.id,
                    colorCode : route.color.color_code
                } : undefined,  
                gym: {
                    id : route.gym.id,
                    name: route.gym.name
                }, 
                difficultyDisplay: route.difficulty_display,
                difficultyGrading: route.difficulty,
                difficultyGymSystem: {
                    id : route.gym_difficulty.id,
                    svg: route.gym_difficulty.svg,
                    grade: route.gym_difficulty.corresponding_difficulty,
                    name : route.gym_difficulty.name

                },  
                tagSteepness: {
                    id : route.tag_steepness.id,
                    name: route.tag_steepness.name
                },   
                tagGrips: {
                    id : route.tag_grips.id,
                    name: route.tag_grips.name
                },            
                tagStyle: {
                    id : route.tag_style.id,
                    name: route.tag_style.name
                },            
                status: route.route_active,
                openingDate :route.created_at,
                plannedDismantling: route.planned_dismantling,
                closedDate :route.closed_at,
                averageRanking: route.average_ranking,
                averageRankingCount : route.average_ranking_count,
                averageGrading: route.average_grading,
                successRate: route.success_rate,
                successRateBySameLevelClimbers: route.success_rate_by_same_level_climbers,
                successFlashRate: route.success_flash_rate,
                successFlashRateBySameLevelClimbers: route.success_flash_rate_by_same_level_climbers,
                totalTries: route.total_tries,
                averageTriesBeforeTop : route.average_tries_before_top,
                averageTriesBeforeTopBySameLevelClimber: route.average_tries_before_top_by_same_level_climbers,
                routeSetter : route.route_setter,
                comments: route.comments ? route.comments.map((comment: any) => ({
                    firstNameUser: comment.user.first_name,
                    lastNameUser: comment.user.last_name,
                    profilePictureUser: comment.user.profile_picture,
                    commentId: comment.id,
                    comment: comment.comment,
                    createdAt: comment.created_at,
                    boulderId: comment.route.id
                })) : [],
                level : route.level

                     
            }));
    
            return { 
                count : response.data.count,
                routes 
            };
        } catch (error) {
            console.log('Error fetching info : ', error)
        }
}


export const getRouteSetterInitialInfo = async (
    routeSetterUuid: string,
    sortGym: number
): Promise<RouteSetterAllRouteInfoProps> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/routesetterinitialgyminfos/${routeSetterUuid}/${sortGym}`);
        
        const routes: RoutesDisplayInfoProps[] = response.data.routes.map((route: any) => ({
            id: route.id,
            name: route.name,
            picture: route.picture,
            sector: {
                id: route.sector.id,
                name: route.sector.name
            },
            color: route.color ? {
                id: route.color.id,
                colorCode: route.color.color_code
            } : undefined,
            gym: {
                id: route.gym.id,
                name: route.gym.name
            },
            difficultyDisplay: route.difficulty_display,
            difficultyGrading: route.difficulty,
            difficultyGymSystem: {
                id: route.gym_difficulty.id,
                svg: route.gym_difficulty.svg,
                grade: route.gym_difficulty.corresponding_difficulty,
                name: route.gym_difficulty.name
            },
            tagSteepness: {
                id: route.tag_steepness.id,
                name: route.tag_steepness.name
            },
            tagGrips: {
                id: route.tag_grips.id,
                name: route.tag_grips.name
            },
            tagStyle: {
                id: route.tag_style.id,
                name: route.tag_style.name
            },
            status: route.route_active,
            openingDate: route.created_at,
            plannedDismantling: route.planned_dismantling,
            closedDate: route.closed_at,
            averageRanking: route.average_ranking,
            averageRankingCount: route.average_ranking_count,
            averageGrading: route.average_grading,
            successRate: route.success_rate,
            successRateBySameLevelClimbers: route.success_rate_by_same_level_climbers,
            successFlashRate: route.success_flash_rate,
            successFlashRateBySameLevelClimbers: route.success_flash_rate_by_same_level_climbers,
            totalTries: route.total_tries,
            averageTriesBeforeTop: route.average_tries_before_top,
            averageTriesBeforeTopBySameLevelClimber: route.average_tries_before_top_by_same_level_climbers,
            routeSetter: route.route_setter,
            comments: route.comments ? route.comments.map((comment: any) => ({
                firstNameUser: comment.user.first_name,
                lastNameUser: comment.user.last_name,
                profilePictureUser: comment.user.profile_picture,
                commentId: comment.id,
                comment: comment.comment,
                createdAt: comment.created_at,
                boulderId: comment.route.id
            })) : [],
            level : route.level
        }));

        return {
            count: response.data.count,
            routes
        };
    } catch (error) {
        console.log('Error fetching info:', error);
    }
}


export const getRouteSetterUserInfo = async (
    routeSetterUuid : string 
    ) : Promise<RouteSetterProps> => {
        try {
            const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/routesetterinfos/${routeSetterUuid}`)
            
            const data : RouteSetterProps = {
                firstName: response.data.first_name,
                lastName: response.data.last_name,
                picture: response.data.profile_picture,
                isAdmin : response.data.is_admin
            }
    

            return data
        } catch (error) {
            console.log('Error fetching info : ', error)
        }
}

export const getGymOwnerClimbers = async (gymOwnerId) => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/gym-owner-climbers/${gymOwnerId}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching climbers:', error);
        throw error;
    }
};

export const getGymOwnerRouteSetters = async (userId: string): Promise<RouteSetters[]> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/gym-owner-route-setters/${userId}`);
        
        return response.data;
    } catch (error) {
        console.error('Error fetching route setter info:', error);
        throw error;  // Propagate the error to be handled by the calling code if necessary
    }
}

export const getGymGeneralStats = async (
    routeSetterUuid : string,
    gymSelectedId : number
    ) : Promise<GymStats> => {
        try {
            const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/gymgeneralstats/${routeSetterUuid}/${gymSelectedId}`)
            
            const data: GymStats = response.data;

            return data;

        } catch (error) {
            console.log('Error fetching info : ', error)
        }
}

export const getSectorsOverview = async (
    routeSetterUuid: string,
    gymSelectedId: number
): Promise<SectorsOverview[]> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/sectorsoverview/${routeSetterUuid}/${gymSelectedId}`);
        
        const data: SectorsOverview[] = response.data;

        return data;
    } catch (error) {
        console.log('Error fetching info:', error);
        throw error;
    }
};


export const getGymComments = async (gymId: number): Promise<CommentBouldersSector[]> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/gym-comments/${gymId}/`);

        const comments = response.data.map((comment: any) => ({
            firstNameUser: comment.user.first_name,
            lastNameUser: comment.user.last_name,
            profilePictureUser: comment.user.profile_picture,
            commentId: comment.id,
            comment: comment.comment,
            createdAt: comment.created_at,
            boulderId: comment.route.id,
            boulderPicture: comment.route.picture,
            boulderName: comment.route.name,
            sectorName: comment.route.sector.name,
            difficultySvg: comment.route.gym_difficulty.svg,
            level: comment.route.level,
        }));

        return comments;
    } catch (error) {
        console.error('Error fetching gym comments: ', error);
        throw error;
    }
};



export const getSectorDetails = async (gymId: number): Promise<{ sectorBasisChoices: SectorBasisChoices[], latestSectorDetails: SectorDetailsProps }> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/sector-details/${gymId}/`);
        const data = response.data;

        const sectorBasisChoices: SectorBasisChoices[] = data.sectorBasisChoices.map((sector: any) => {
            const svgMatch = sector.polyline.match(/<svg.*?width="(\d+)" height="(\d+)"/);
            const width = svgMatch ? parseInt(svgMatch[1], 10) : 0;
            const height = svgMatch ? parseInt(svgMatch[2], 10) : 0;

            // Extract 'd' attribute values for paths
            const pathMatches = sector.polyline.match(/<path.*?d="([^"]+)".*?\/>/g);
            const svgPaths = pathMatches ? pathMatches.map((pathString: string, index: number) => {
                const dMatch = pathString.match(/d="([^"]+)"/);
                return {
                    d: dMatch ? dMatch[1] : '',
                    fill: index === 0 ? 'white' : 'black'
                };
            }) : [];

            return {
                sectorId: sector.id,
                sectorName: sector.name,
                svgPaths: svgPaths,
                sectorPicture: sector.picture_sector,
                x: sector.x,
                y: sector.y,
                newestSector : sector.newest_sector,
                oldestSector: sector.oldest_sector,
                width,
                height
            };
        });

        const latestSectorDetails = {
            sectorId: data.latestSectorDetails.sectorId,
            sectorName: data.latestSectorDetails.sectorName,
            sectorPicture: data.latestSectorDetails.sectorPicture,
            totalSectorTries: data.latestSectorDetails.totalSectorTries,
            totalSectorTriesLastSector: data.latestSectorDetails.totalSectorTriesLastSector,
            triesPerDay: data.latestSectorDetails.triesPerDay,
            triesPerDayLastSector: data.latestSectorDetails.triesPerDayLastSector,
            averageGrade: data.latestSectorDetails.averageGrade,
            averageGradeLastSector: data.latestSectorDetails.averageGradeLastSector,
            percentageSuccess: data.latestSectorDetails.percentageSuccess,
            percentageSuccessLastSector: data.latestSectorDetails.percentageSuccessLastSector,
            percentageFlash: data.latestSectorDetails.percentageFlash,
            percentageLastSector: data.latestSectorDetails.percentageLastSector,
            averageTriesBeforeTop: data.latestSectorDetails.averageTriesBeforeTop,
            averageTriesBeforeTopLastSector: data.latestSectorDetails.averageTriesBeforeTopLastSector,
            ranking: data.latestSectorDetails.ranking,
            rankingCount: data.latestSectorDetails.rankingCount,
            routes: data.latestSectorDetails.routes.map((route: any) => ({
                id: route.id,
                name: route.name,
                sector: route.sector,
                color: route.color && {
                    id : route.color.id,
                    colorCode : route.color.color_code
                },
                averageRankingCount: route.average_ranking_count,
                gym: route.gym,
                difficulty: {
                    difficultyName: route.gym_difficulty.name,
                    difficultyGrade: route.gym_difficulty.corresponding_difficulty,
                    difficultySvg: route.gym_difficulty.svg,
                    difficultyColor1: route.gym_difficulty.color_1,
                    difficultyColor2: route.gym_difficulty.color_2                    
                },
                difficultyGymSystem: route.gym_difficulty,
                
                tagSteepness: route.tag_steepness.name,
                tagGrips: route.tag_grips.name,
                tagStyle: route.tag_style.name,
                level: route.level,
                openingDate: route.created_at,
                plannedDismantling: route.planned_dismantling,
                difficultyDisplay: route.difficulty_display,
                closedDate: route.closed_at,
                averageRanking: route.average_ranking,
                picture : route.picture,
                averageGrading: route.average_grading,
                successRate: route.success_rate,
                successRateBySameLevelClimbers: route.success_rate_by_same_level_climbers,
                successFlashRate: route.success_flash_rate,
                successFlashRateBySameLevelClimbers: route.success_flash_rate_by_same_level_climbers,
                totalTries: route.total_tries,
                averageTriesBeforeTop: route.average_tries_before_top,
                averageTriesBeforeTopBySameLevelClimbers: route.average_tries_before_top_by_same_level_climbers,
                routeSetter: route.route_setter,
                comments: route.comments.map((comment: any) => ({
                    firstNameUser: comment.user.first_name,
                    lastNameUser: comment.user.last_name,
                    profilePictureUser: comment.user.profile_picture,
                    commentId: comment.id,
                    comment: comment.comment,
                    createdAt: comment.created_at,
                    boulderId: comment.route.id,
                    boulderPicture: comment.route.picture,
                    boulderName: comment.route.name,
                    sectorName: comment.route.sector.name,
                    difficultySvg: comment.route.gym_difficulty.svg,
                    level: comment.route.level,
                })),
            })),
            comments: data.latestSectorDetails.comments.map((comment: any) => ({
                firstNameUser: comment.user.first_name,
                lastNameUser: comment.user.last_name,
                profilePictureUser: comment.user.profile_picture,
                commentId: comment.id,
                comment: comment.comment,
                createdAt: comment.created_at,
                boulderId: comment.route.id,
                boulderPicture: comment.route.picture,
                boulderName: comment.route.name,
                sectorName: comment.route.sector.name,
                difficultySvg: comment.route.gym_difficulty.svg,
                level: comment.route.level,
            })),
            openingDate: data.latestSectorDetails.openingDate,
            closingDate: data.latestSectorDetails.closingDate,
        };

        return { sectorBasisChoices, latestSectorDetails };
    } catch (error) {
        console.error('Error fetching sector details: ', error);
        throw error;
    }
};

export const getSectorDetailsById = async (sectorId: number): Promise<SectorDetailsProps> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/sector-details-by-id/${sectorId}/`);
        const sector = response.data;

        const svgMatch = sector.sectorSvg.match(/<svg.*?width="(\d+)" height="(\d+)"/);
        const width = svgMatch ? parseInt(svgMatch[1], 10) : 0;
        const height = svgMatch ? parseInt(svgMatch[2], 10) : 0;

        // Extract 'd' attribute values for paths
        const pathMatches = sector.sectorSvg.match(/<path.*?d="([^"]+)".*?\/>/g);
        const svgPaths = pathMatches ? pathMatches.map((pathString: string, index: number) => {
            const dMatch = pathString.match(/d="([^"]+)"/);
            return {
                d: dMatch ? dMatch[1] : '',
                fill: index === 0 ? 'white' : 'black'
            };
        }) : [];

        return {
            sectorId: sector.sectorId,
            sectorName: sector.sectorName,
            sectorPicture: sector.sectorPicture,
            totalSectorTries: sector.totalSectorTries,
            totalSectorTriesLastSector: sector.totalSectorTriesLastSector,
            triesPerDay: sector.triesPerDay,
            triesPerDayLastSector: sector.triesPerDayLastSector,
            averageGrade: sector.averageGrade,
            averageGradeLastSector: sector.averageGradeLastSector,
            percentageSuccess: sector.percentageSuccess,
            percentageSuccessLastSector: sector.percentageSuccessLastSector,
            percentageFlash: sector.percentageFlash,
            percentageLastSector: sector.percentageLastSector,
            averageTriesBeforeTop: sector.averageTriesBeforeTop,
            averageTriesBeforeTopLastSector: sector.averageTriesBeforeTopLastSector,
            ranking: sector.ranking,
            rankingCount: sector.rankingCount,
            routes: sector.routes ? sector.routes.map((route: any) => ({
                id: route.id,
                name: route.name,
                sector: route.sector,
                color : route.color && {
                    id : route.color.id,
                    colorCode : route.color.color_code
                },
                averageRankingCount: route.average_ranking_count,
                gym: route.gym,
                difficulty: {
                    difficultyName: route.gym_difficulty.name,
                    difficultyGrade: route.gym_difficulty.corresponding_difficulty,
                    difficultySvg: route.gym_difficulty.svg,
                    difficultyColor1: route.gym_difficulty.color_1,
                    difficultyColor2: route.gym_difficulty.color_2
                },
                difficultyGymSystem: route.gym_difficulty,
                
                tagSteepness: route.tag_steepness.name,
                tagGrips: route.tag_grips.name,
                tagStyle: route.tag_style.name,
                level: route.level,
                openingDate: route.created_at,
                plannedDismantling: route.planned_dismantling,
                difficultyDisplay: route.difficulty_display,
                closedDate: route.closed_at,
                averageRanking: route.average_ranking,
                picture : route.picture,
                averageGrading: route.average_grading,
                successRate: route.success_rate,
                successRateBySameLevelClimbers: route.success_rate_by_same_level_climbers,
                successFlashRate: route.success_flash_rate,
                successFlashRateBySameLevelClimbers: route.success_flash_rate_by_same_level_climbers,
                totalTries: route.total_tries,
                averageTriesBeforeTop: route.average_tries_before_top,
                averageTriesBeforeTopBySameLevelClimbers: route.average_tries_before_top_by_same_level_climbers,
                routeSetter: route.route_setter,
                comments: route.comments.map((comment: any) => ({
                    firstNameUser: comment.user.first_name,
                    lastNameUser: comment.user.last_name,
                    profilePictureUser: comment.user.profile_picture,
                    commentId: comment.id,
                    comment: comment.comment,
                    createdAt: comment.created_at,
                    boulderId: comment.route.id,
                    boulderPicture: comment.route.picture,
                    boulderName: comment.route.name,
                    sectorName: comment.route.sector.name,
                    difficultySvg: comment.route.gym_difficulty.svg,
                    level: comment.route.level
                }))
            })):
            [],
            comments: sector.comments.map((comment: any) => ({
                firstNameUser: comment.user.first_name,
                lastNameUser: comment.user.last_name,
                profilePictureUser: comment.user.profile_picture,
                commentId: comment.id,
                comment: comment.comment,
                createdAt: comment.created_at,
                boulderId: comment.route.id,
                boulderPicture: comment.route.picture,
                boulderName: comment.route.name,
                sectorName: comment.route.sector.name,
                difficultySvg: comment.route.gym_difficulty.svg,
                level: comment.route.level,
            })),
            openingDate: sector.openingDate,
            closingDate: sector.closingDate,
           
        };
    } catch (error) {
        console.error('Error fetching sector details by ID: ', error);
        throw error;
    }
}

export const getDifficultyCircuitHeaders = async (gymId: number): Promise<DifficultyCircuitHeader[]> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/difficulty-circuit-header/${gymId}`);
        return response.data.map((header: any) => ({
            difficultySplit: {
                difficultyName: header.difficulty_split.name,
                boulderCount: header.boulder_count,
                difficultyGrade: header.difficulty_split.corresponding_difficulty,
                difficultyColor1: header.difficulty_split.color_1,
                difficultyColor2: header.difficulty_split.color_2,
                svg: header.difficulty_split.svg,
                id : header.difficulty_split.id
            },
            level1Count: header.level1_count,
            level2Count: header.level2_count,
            level3Count: header.level3_count,
            boulderCount: header.boulder_count,
            triesTotal: header.tries_total,
            triesPerDay: header.tries_per_day,
            successRate: header.success_rate,
            activeClimbers: header.active_climbers,
            triesTotalLastMonth: header.tries_total_last_month,
            triesPerDayLastMonth: header.tries_per_day_last_month,
            successRateLastMonth: header.success_rate_last_month,
            activeClimbersLastMonth: header.active_climbers_last_month
        }));
    } catch (error) {
        console.error('Error fetching difficulty circuit headers: ', error);
        throw error;
    }
};


export const getDifficultyCircuitDetails = async (gymId: number, difficultyGymId: number): Promise<DifficultyCircuitFullDetails> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/difficulty-circuit-full-details/${gymId}/${difficultyGymId}`);
        const data = response.data[0]; // Assuming the response is an array with one object

        return {
            difficultySplit: { 
                id: data.difficulty_split.id,
                difficultyName: data.difficulty_split.name,
                boulderCount: data.boulder_count,
                difficultyGrade: data.difficulty_split.corresponding_difficulty,
                difficultyColor1: data.difficulty_split.color_1,
                difficultyColor2: data.difficulty_split.color_2,
                svg: data.difficulty_split.svg,
            },
            level1Count: data.level1_count,
            level2Count: data.level2_count,
            level3Count: data.level3_count,
            boulderCount: data.boulder_count,
            triesTotal: data.tries_total,
            triesPerDay: data.tries_per_day,
            successRate: data.success_rate,
            activeClimbers: data.active_climbers,
            triesTotalLastMonth: data.tries_total_last_month,
            triesPerDayLastMonth: data.tries_per_day_last_month,
            successRateLastMonth: data.success_rate_last_month,
            activeClimbersLastMonth: data.active_climbers_last_month,
            averageGrade: data.average_grade,
            averageGradeLastMonth: data.average_grade_last_month,
            flashRate: data.flash_rate,
            flashRateLastMonth: data.flash_rate_last_month,
            averageTriesBeforeTop: data.average_tries_before_top,
            averageTriesBeforeTopLastMonth: data.average_tries_before_top_last_month,
            rank: data.rank,
            rankCount: data.rank_count,
            boulders: data.boulders.map((boulder: any) => ({
                name: boulder.name,
                id: boulder.id,
                picture: boulder.picture,
                sectorName: boulder.sectorName,
                difficultySvg: boulder.difficultySvg,
                level: boulder.level,
                gripColor: boulder.gripColor,
                difficultyColor : boulder.difficultyColor,
                triesPerDay: boulder.triesPerDay,
                ranking: boulder.ranking,
                rankingCount: boulder.rankingCount,
                totalTries: boulder.totalTries,
                averageTriesBeforeTop: boulder.averageTriesBeforeTop
            })),
            comments: data.comments.map((comment: any) => ({
                firstNameUser: comment.user.first_name,
                lastNameUser: comment.user.last_name,
                profilePictureUser: comment.user.profile_picture,
                commentId: comment.id,
                comment: comment.comment,
                createdAt: comment.created_at,
                boulderId: comment.route.id,
                boulderPicture: comment.route.picture,
                boulderName: comment.route.name,
                sectorName: comment.route.sector.name,
                difficultySvg: comment.route.gym_difficulty.svg,
                level: comment.route.level
            }))
        };
    } catch (error) {
        console.error('Error fetching difficulty circuit details: ', error);
        throw error;
    }
};

export const getRouteSetterGymFilterInfo = async (
    routeSetterUuid : string ,
    ) : Promise<GymMinimalProps[]> => {
        try {
            const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/routesettergymonlyinfos/${routeSetterUuid}/`)
            
            const data : GymMinimalProps[] = response.data.map(gym => ({
                id : gym.id,
                name: gym.name,
                hasCustomDifficultySystem: gym.has_additionnal_difficulty_grading,
                isCustomDifficultySystemColor: gym.is_custom_difficulty_system_color,
                sectors: gym.sectors.map(sector => ({
                    id:sector.id,
                    name:sector.name
                })), 
                colors: gym.colors.map(color => ({
                    id : color.id,
                    colorCode: color.color_code
                })),
                difficultySystem: gym.difficultySystem.map(difficultySystem => ({
                    id: difficultySystem.id,
                    svg: difficultySystem.svg,
                    grade : difficultySystem.corresponding_difficulty
                })),
                summaryDifficulty: gym.active_routes_info.map(info => ({
                    difficultySvg: info.gym_difficulty, // Assuming this is the SVG or identifier for the difficulty
                    difficultyCount: info.count_routes,
                    difficultySubCount: Object.keys(info).filter(key => key.startsWith('level ')).map(level => ({
                        difficultyValue: parseInt(level.split(' ')[1]), // Parse the level number
                        difficultyCount: info[level],
                    })),
                })),
                countTotalRoutes: gym.total_active_routes,
            }));
    

            return data
        } catch (error) {
            console.log('Error fetching info : ', error)
        }
}

export const getRouteSetterGymFilterInfoSpecific = async (
    routeSetterUuid : string ,
    gym_id : number
    ) : Promise<GymMinimalProps[]> => {
        try {
            const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/routesettergymonlyinfosspecific/${routeSetterUuid}/${gym_id}`)
            
            const data : GymMinimalProps[] = response.data.map(gym => ({
                id : gym.id,
                name: gym.name,
                hasCustomDifficultySystem: gym.has_additionnal_difficulty_grading,
                isCustomDifficultySystemColor: gym.is_custom_difficulty_system_color,
                sectors: gym.sectors.map(sector => ({
                    id:sector.id,
                    name:sector.name
                })), 
                colors: gym.colors.map(color => ({
                    id : color.id,
                    colorCode: color.color_code
                })),
                difficultySystem: gym.difficultySystem.map(difficultySystem => ({
                    id: difficultySystem.id,
                    svg: difficultySystem.svg,
                    grade : difficultySystem.corresponding_difficulty
                })),
                summaryDifficulty: gym.active_routes_info.map(info => ({
                    difficultySvg: info.gym_difficulty, // Assuming this is the SVG or identifier for the difficulty
                    difficultyCount: info.count_routes,
                    difficultySubCount: Object.keys(info).filter(key => key.startsWith('level ')).map(level => ({
                        difficultyValue: parseInt(level.split(' ')[1]), // Parse the level number
                        difficultyCount: info[level],
                    })),
                })),
                countTotalRoutes: gym.total_active_routes,
            }));
    

            return data
        } catch (error) {
            console.log('Error fetching info : ', error)
        }
}

export const getTagListInfo = async (
    ) : Promise<TagsList> => {
        try {
            const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/tagslist/`)
            
            const data :TagsList = {
               
                    tagGrips : response.data.tag_grips.map(grip => ({
                        id: grip.id,
                        name: grip.name   
                    })),
                    tagSteepness: response.data.tag_steepness.map(steepness => ({
                        id: steepness.id,
                        name: steepness.name   
                    })),
                    tagStyle: response.data.tag_style.map(style => ({
                        id: style.id,
                        name: style.name   
                    })),
                  
                }
    

            return data
        } catch (error) {
            console.log('Error fetching info : ', error)
        }
}

export const fetchRouteImage = async (id:number) => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/fetchroutepicture/${id}/`);

        const data : PictureRouteImg ={
            id: response.data.id,
            url : response.data.picture

        }
        return data; 
    } catch (error) {
        console.error('Error fetching the image', error);
        throw error; // Propagate the error so you can handle it in the component if needed
    }
};


export const getRankingInformations = async (): Promise<{ male: RankingLineProps[], female : RankingLineProps[] ,postCount: number }> => {
    try {
        axios.defaults.headers.common['X-CSRFToken'] = csrftoken
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/SoiréeSpécialeLancement/`);
        const data = response.data;

        const rankedClimbersCorti = data.climbers_sessions_corti.map((item: any, index: number) => ({
            firstName: item.uid__first_name,
            lastName: item.uid__last_name,
            points: item.sessions_count,
            rank: index + 1 // Assign rank based on the order in the array
        }));

        const rankedClimbersContrepointe = data.climbers_sessions_contrepointe.map((item: any, index: number) => ({
            firstName: item.uid__first_name,
            lastName: item.uid__last_name,
            points: item.sessions_count,
            rank: index + 1 // Assign rank based on the order in the array
        }));


        const postCount = data.post_count;  // Assuming post_count is included in the response

        return {
            male: rankedClimbersCorti,
            female : rankedClimbersContrepointe,
            postCount: postCount,
        };
    } catch (error) {
        console.error('Error fetching ranking information:', error);
        return {
            male: [],
            female : [],
            postCount: 0,
        }; // Return an empty object in case of error
    }
}

export const getTopBoulders = async (gymId: number): Promise<BestBoulderCard[]> => {
    try {
        const response = await axios.get(`${httpToUse}://${localIpAdress}${portToUse}/api/top-boulders/${gymId}/`);
        return response.data;
    } catch (error) {
        console.error('Error fetching top boulders:', error);
        throw error;
    }
};

export const getLatestGymRouteComments = async (gymId: number): Promise<CommentBouldersSector[]> => {
    try {
        const response = await axios.get(`/api/latest-gym-route-comments/${gymId}/`);
        const comments = response.data.map((item: any) => ({
            firstNameUser: item.user.first_name,
            lastNameUser: item.user.last_name,
            profilePictureUser: item.user.profile_picture,
            commentId: item.id,
            comment: item.comment,
            createdAt: item.created_at,
            boulderId: item.route.id,
            boulderPicture: item.route.picture,
            boulderName: item.route.name,
            sectorName: item.route.sector.name,
            difficultySvg: item.route.gym_difficulty.svg,
            level: item.route.level
        }));
        return comments;
    } catch (error) {
        console.error('Error fetching latest gym route comments:', error);
        throw error;
    }
};