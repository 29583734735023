import React, { useState,useEffect, useContext } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { DifficultyCircuitHeader, DifficultyHeader } from '../../constants/types';
import { Colors } from '../../constants/Consts';
import DifficultySvg from '../UI/DifficultySvg';
import { GlobalContext } from '../../context/GlobalStateProvider';
import LevelDisplayerDirect from '../backOffice/LevelDisplayerDirect';
import LevelDisplayerDirect2 from '../backOffice/LevelDisplayerDirect2';
import { useNavigate } from 'react-router-dom';

interface DifficultySummaryCircuitItemProps {
    difficultyheader: DifficultyCircuitHeader;
    width : number
    totalRoutes: number;
    activatedByDefault ? : boolean
}

const DifficultySummaryCircuitItem: React.FC<DifficultySummaryCircuitItemProps> = ({ difficultyheader, totalRoutes, width, activatedByDefault }) => {
    const globalContext = useContext(GlobalContext);
    const [isHovered, setIsHovered] = useState(false);
    const navigate  = useNavigate()
    const { currentGym, setLastDifficultyChecked } = globalContext;


    
    const gradientBackground = difficultyheader.difficultySplit.difficultyColor1 === null
        ? Colors.colorgrey300
        : difficultyheader.difficultySplit.difficultyColor2
            ? `linear-gradient(to bottom, ${difficultyheader.difficultySplit.difficultyColor1} 50%, ${difficultyheader.difficultySplit.difficultyColor2} 50%)`
            : difficultyheader.difficultySplit.difficultyColor1;

    const isSpecialColor = (color: string | undefined) => {
        return color === '#F4F443' || color === '#FFFFFF';
    };

    const fontColor = difficultyheader.difficultySplit.difficultyColor1 === null
        ? Colors.colorgrey800
        : isSpecialColor(difficultyheader.difficultySplit.difficultyColor1) || isSpecialColor(difficultyheader.difficultySplit.difficultyColor2)
            ? Colors.colorgrey800
            : '#FFFFFF';

    const InnerLevelSplit: React.FC<{ level: number; boulderCount: number; levelCount: number }> = ({ level, boulderCount, levelCount }) => {
        const proportion = (levelCount / boulderCount) * 100;

        const getBorderRadius = (level: number) => {
            switch (level) {
                case -1:
                    return '10px 0 0 10px';
                case 0:
                    return '0';
                case 1:
                    return '0 10px 10px 0';
                default:
                    return '0';
            }
        };
        return (
            <div className='common-inner-level-container-style' style={{ width: `${60*(1+(proportion/100))*(1+width/100)}px`,  }} >
                <div className='regular lower2' style={{color:Colors.colorgrey700}}>{proportion.toFixed(0)}%</div>
                <div className='common-inner-level-style bold lower' style={{
                        color: fontColor,
                        borderRadius: getBorderRadius(level),
                        background: gradientBackground
                    }}
                >
                    {levelCount}
                </div>
                <LevelDisplayerDirect2 level={level} />
            </div>
        )
    };

    

    return (
        <div
            onClick={()=>{navigate('/circuits-details'); setLastDifficultyChecked(difficultyheader)}}
            className='difficulty-circuit-item-container-visual '
            style={Object.assign(
                {
                    width: '100%',
                    borderLeft: 'solid',
                    borderBottom: 'solid',
                    borderLeftWidth: "3px",
                    borderBottomWidth: "3px",
                    borderColor: difficultyheader.difficultySplit.difficultyColor1 ? difficultyheader.difficultySplit.difficultyColor1 : Colors.colorgrey300 ,
                    minWidth: 'fit-content',
                    background: (isHovered || activatedByDefault) ? Colors.colorbackoffice100 : 'transparent',
                    borderTop : (isHovered && difficultyheader.difficultySplit.difficultyColor2) ? `3px solid ${difficultyheader.difficultySplit.difficultyColor2}`: (isHovered && difficultyheader.difficultySplit.difficultyColor1) ?  `3px solid ${difficultyheader.difficultySplit.difficultyColor1}` : isHovered && `3px solid ${Colors.colorgrey300}`,
                    borderRight : (isHovered && difficultyheader.difficultySplit.difficultyColor2) ? `3px solid ${difficultyheader.difficultySplit.difficultyColor2}`: (isHovered && difficultyheader.difficultySplit.difficultyColor1) ?  `3px solid ${difficultyheader.difficultySplit.difficultyColor1}` : isHovered && `3px solid ${Colors.colorgrey300}`
                },
                activatedByDefault && {
                    borderTop: `3px solid ${difficultyheader.difficultySplit.difficultyColor2 ? difficultyheader.difficultySplit.difficultyColor2 : difficultyheader.difficultySplit.difficultyColor1 ? difficultyheader.difficultySplit.difficultyColor1 : Colors.colorgrey300}`,
                    borderRight: `3px solid ${difficultyheader.difficultySplit.difficultyColor2 ? difficultyheader.difficultySplit.difficultyColor2 : difficultyheader.difficultySplit.difficultyColor1 ? difficultyheader.difficultySplit.difficultyColor1 : Colors.colorgrey300}`,
                    
                    justifyContent: 'flex-start'
                }
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            
        >
            <div style={{display:'flex', flexDirection:'row', gap: '8px'}}>
                <div style={{borderRadius:'4px'}}>
                    <DifficultySvg
                        svg={difficultyheader.difficultySplit.svg}
                        text=''
                        hasDifficultySystem={currentGym.hasCustomDifficultySystem}
                    />
                </div>
                <div className='regular normal' style={{color:Colors.colorbackoffice800}}>{difficultyheader.difficultySplit.difficultyName}</div>
            </div>
            <div style={{display:'flex', flexDirection:'row', gap: '20px', alignItems:'center'}}>
                <div className='inner-level-split-container'>
                    {difficultyheader.level1Count>0 &&
                        <InnerLevelSplit
                            level={-1}
                            boulderCount={difficultyheader.boulderCount}
                            levelCount={difficultyheader.level1Count}
                        />
                    }
                    {difficultyheader.level2Count > 0 &&
                        <InnerLevelSplit
                            level={0}
                            boulderCount={difficultyheader.boulderCount}
                            levelCount={difficultyheader.level2Count}
                        />
                    }
                    {difficultyheader.level3Count >0 &&
                        <InnerLevelSplit
                            level={1}
                            boulderCount={difficultyheader.boulderCount}
                            levelCount={difficultyheader.level3Count}
                        />
                    }
                </div>  
                <div className='difficulty-summary-item-container-visual-description bold normal' style={{color:Colors.colorgrey700}}>
                    {difficultyheader.boulderCount > 0 && (
                        <>
                            {difficultyheader.boulderCount} {difficultyheader.boulderCount <= 1 ? 'bloc ' : 'blocs '}
                            
                        </>
                    )}
                </div>
            </div>
            {!activatedByDefault &&
                <CustomButton 
                    LeftSvg={SVGs.plusBlueButton}
                    text='Détail circuit'
                    customStyle='detail-circuit-button regular lower'
                />
            }
        </div>
    );
};

export default DifficultySummaryCircuitItem;