import React from 'react';
import LevelSelector from './LevelSelector'; // Ensure the correct path to your LevelSelector component

interface LevelDisplayerProps {
    difficultyGrading: number;
    difficultyGymSystemGrade: number;
}

const LevelDisplayer: React.FC<LevelDisplayerProps> = ({ difficultyGrading, difficultyGymSystemGrade }) => {
    const renderLevelSelectors = () => {
        if (difficultyGrading < difficultyGymSystemGrade) {
            return (
                <>
                    <LevelSelector isClicked={true} text={'1'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'2'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'3'} shouldShowFigure={false} />
                </>
            );
        } else if (difficultyGrading === difficultyGymSystemGrade) {
            return (
                <>
                    <LevelSelector isClicked={false} text={'1'} shouldShowFigure={false} />
                    <LevelSelector isClicked={true} text={'2'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'3'} shouldShowFigure={false} />
                </>
            );
        } else {
            return (
                <>
                    <LevelSelector isClicked={false} text={'1'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'2'} shouldShowFigure={false} />
                    <LevelSelector isClicked={true} text={'3'} shouldShowFigure={false} />
                </>
            );
        }
    };

    return (
        <div className={"row__difficulty normal regular"} style={{ justifyContent: 'flex-end' }}>
            {renderLevelSelectors()}
        </div>
    );
};

export default LevelDisplayer;