import React from 'react';



import { StarRatingProps } from '../../constants/types';
import CustomButton from './CustomButton';
import { SVGs } from '../../constants/Svgs';



const StarRating: React.FC<StarRatingProps> = ({ review,styleText,customStyle }) => {

    const baseClasses = [ ];

    if (customStyle) {
        baseClasses.push(customStyle);
    }
    if(styleText) {
        baseClasses.push(styleText)
    }

  if (review === undefined || review === null ) {
    return <p className={styleText} >Pas encore de votes</p>;
  } else {
    const filledStars = Math.floor(review || 0);
    const hasHalfStar = review ? review - filledStars >= 0.5 : false;



    const stars = [];
      for (let i = 1; i <= 5; i++) {
        if (i <= filledStars) {
          stars.push(<CustomButton  key={i} Svg={SVGs.kindOfFilledStar} customStyle={customStyle}  />);
        } else if (i === filledStars + 1 && hasHalfStar) {
          
          stars.push(<CustomButton customStyle={customStyle} key={i} Svg={SVGs.kindOfHalfEmptyStar}   />);
        } else {
          stars.push(<CustomButton customStyle={customStyle} key={i} Svg={SVGs.kindOfEmptyStar}  />);
        }
      }

    return <div style={{ flexDirection: 'row', display:'flex', gap:'4px' }}>{stars}</div>;
  }
};

export default StarRating;

