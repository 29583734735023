import React, { useState,useEffect } from 'react';

interface ColorSquareProps {

    text:string
    isClicked? : boolean,
    customStyle? : string,
    additionalCustomStyle?:string,
    customTextStyle? : string,
    onClickProp?: () => void,
    isWhite?: boolean

}


const ColorSquare : React.FC<ColorSquareProps> = ({ isClicked, customStyle, customTextStyle,text,onClickProp,isWhite,additionalCustomStyle }) => {
    const [isClickedStatus, setIsClickedStatus] =useState(isClicked)
  
    const baseClasses = [ ];

    if (customStyle) {
        baseClasses.push(additionalCustomStyle);
    }

    useEffect(() => {
        setIsClickedStatus(isClicked);
    }, [isClicked]);

    return (
        <div className={`boulder-color__filter ${isClickedStatus && "clicked2"} ${baseClasses.join(' ')}`} style={{
            ...{ backgroundColor: customStyle },
            ...(isWhite ? {  border:'solid',borderWidth: 2, borderColor: '#98A2B3' } : {})
        }}  onClick={onClickProp}>
            <span className="text-hidden">{text}</span>
        </div>
    )
}


export default ColorSquare