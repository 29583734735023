import React, { useState,useEffect, useContext } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { CommentBouldersSector, CommentBouldersSectorSimple } from '../../constants/types';
import { Colors } from '../../constants/Consts';
import { getDaysDifferenceMessage, getDaysDifferenceMessageWithUTC } from '../../constants/helpers';
import DifficultySvg from '../UI/DifficultySvg';
import { GlobalContext } from '../../context/GlobalStateProvider';
import LevelDisplayerDirect from './LevelDisplayerDirect';



const CommentsCardSimple : React.FC<CommentBouldersSectorSimple> = ({  boulderId,comment,commentId,createdAt,firstNameUser,lastNameUser,profilePictureUser}) => {
   
    const globalContext = useContext(GlobalContext);
    const { currentGym} = globalContext;


   
   
    return (
        <div className='comment-box-container-small-card'>  
            <div className='comment-box-content'>
                <div className='comment-box-content-no-date'>
                    <div className='comment-box-content-picture'>
                        <img src={profilePictureUser}/>
                    </div>
                    <div className='comment-box-content-written'>
                        <div className='bold lower' style={{color:Colors.colorgrey600}}>
                            {firstNameUser}{' '}{lastNameUser}
                        </div>
                        <div className='regular normal' style={{color:Colors.colorgrey700}}>
                            {comment}
                        </div>

                    </div>
                </div>
                <div className='bold lower2' style={{color:Colors.colorgrey500, paddingLeft:'56px'}}>
                    {getDaysDifferenceMessageWithUTC(createdAt)}
                </div>
                

            </div>
            
        </div>
        

   
    )
}


export default CommentsCardSimple