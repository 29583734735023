import React, { useState,useEffect, useContext } from 'react';
import { SVGs } from '../../constants/Svgs';
import CustomButton from '../UI/CustomButton';
import { CommentBouldersSector } from '../../constants/types';
import { Colors } from '../../constants/Consts';
import { getDaysDifferenceMessage, getDaysDifferenceMessageWithUTC } from '../../constants/helpers';
import DifficultySvg from '../UI/DifficultySvg';
import { GlobalContext } from '../../context/GlobalStateProvider';
import LevelDisplayerDirect from './LevelDisplayerDirect';



const CommentsCard : React.FC<CommentBouldersSector> = ({  boulderId,boulderName,boulderPicture,comment,commentId,createdAt,difficultySvg,firstNameUser,lastNameUser,level,profilePictureUser,sectorName }) => {
   
    const globalContext = useContext(GlobalContext);
    const { currentGym} = globalContext;


   
   
    return (
        <div className='comment-box-container'>  
            <div className='comment-box-content'>
                <div className='comment-box-content-no-date'>
                    <div className='comment-box-content-picture'>
                        <img src={profilePictureUser}/>
                    </div>
                    <div className='comment-box-content-written'>
                        <div className='bold lower' style={{color:Colors.colorgrey600}}>
                            {firstNameUser}{' '}{lastNameUser}
                        </div>
                        <div className='regular normal' style={{color:Colors.colorgrey700}}>
                            {comment}
                        </div>

                    </div>
                </div>
                <div className='bold lower2' style={{color:Colors.colorgrey500, paddingLeft:'56px'}}>
                    {getDaysDifferenceMessageWithUTC(createdAt)}
                </div>
                

            </div>
            <div className='comment-box-boulder'>
                <div className='comment-box-boulder-picture'>
                    <img src={boulderPicture}/>
                </div>
                <div className='comment-box-boulder-info'>
                    <div className='comment-box-boulder-info-written regular lower2'> 
                        <div>{boulderName}</div>
                        <div>{sectorName}</div>
                    </div>
                    <div className='comment-box-boulder-info-visual'> 
                        {currentGym &&
                            <DifficultySvg 
                                hasDifficultySystem={currentGym.hasCustomDifficultySystem}
                                svg={difficultySvg}
                                text=''
                            />
                        }
                        <LevelDisplayerDirect 
                            level={level}
                        />
                    </div>
                </div>
               
            </div>
            
        </div>
        

   
    )
}


export default CommentsCard