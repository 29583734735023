import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { getGymGeneralStats, getRouteSetterGymFilterInfo, getRouteSetterUserInfo, getSectorDetails, getSectorDetailsById, getSectorsOverview, ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken, formatDate, getDaysDifferenceMessage, getGradeFromIndex } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButton';

import NavBarBackOffice from '../components/backOffice/Navbar';
import { DifficultySplit, GymMinimalProps, GymStats, RouteSetterProps, SectorBasisChoices, SectorCategory, SectorDetailsProps, SectorsOverview } from '../constants/types';
import { GlobalContext } from '../context/GlobalStateProvider';
import Modal from '../components/UI/ModalRoute';
import { Colors, pages } from '../constants/Consts';
import StatBox from '../components/backOffice/StatBox';
import DifficultySummaryHome from '../components/HomePage/DifficultySummaryHome';
import SectorSummaryRow from '../components/backOffice/SectorSummaryRow';
import SectorItem from '../components/UI/SectorItem';
import StatBoxSector from '../components/backOffice/StatBoxSector';
import StatisticRow from '../components/backOffice/StatisticRow';
import StarRating from '../components/UI/StarRating';
import DifficultySplitComponent from '../components/UI/DifficultySplit';
import SectorDetailsRow, { SectorDetailsRowProps } from '../components/backOffice/SectorDetailsRow';
import CommentsCard from '../components/backOffice/CommentsCard';
import { TailSpin } from 'react-loader-spinner';





const SectorsDetailsPage: React.FC = () => {
    const navigate  = useNavigate()
    
    const [sectorsInitialDetails, setSectorsInitialDetails] = useState<SectorBasisChoices[]>([]);
    const [sectorsDetails, setSectorsDetails] = useState<SectorDetailsProps[]>([]);  
    const [selectedSector, setSelectedSector] = useState<SectorDetailsProps>(null) 
    const [difficultySplit, setDifficultySplit] = useState<DifficultySplit[]>([]);
    const [sectorDetailsRows, setSectorDetailsRows] = useState<SectorDetailsRowProps[]>([]);
    const globalContext = useContext(GlobalContext);
    const [shouldBeLoading, setShouldBeLoading] = useState(true)
    const [changeDateClosingFromSelectedSector, setChangeDateClosingSector] = useState(false)
    const [changeDateOpeningFromSelectedSector, setChangeDateOpeningSector] = useState(false)
    const toggleChangeDateClosingFromSelectedSector = () => {
        setChangeDateClosingSector(prev => !prev); // Toggle the state of extendedRow
    };
    const [date, setDateClosingSelectedSector] = useState('');
    const [errorClosing, setErrorClosing] = useState('');
    const toggleChangeDateOpeningFromSelectedSector = () => {
        setChangeDateOpeningSector(prev => !prev); // Toggle the state of extendedRow
    };
    const [dateOpeningSelectedSector, setDateOpeningSelectedSector] = useState('');
    const [errorOpening, setErrorOpening] = useState('');


    const handleDateClosingChange = (e) => {
        const value = e.target.value;
        setDateClosingSelectedSector(value);
    
        // Validate the date format (DD/MM/YYYY)
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!datePattern.test(value)) {
          setErrorClosing('La date doit être saisie au format JJ/MM/AAAA');
        } else {
          setErrorClosing('');
        }
    };

    const handleDateOpeningChange = (e) => {
        const value = e.target.value;
        setDateOpeningSelectedSector(value);
    
        // Validate the date format (DD/MM/YYYY)
        const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!datePattern.test(value)) {
          setErrorOpening('La date doit être saisie au format JJ/MM/AAAA');
        } else {
          setErrorOpening('');
        }
    };

    const handleConfirmClosingSelectedSector = async () => {
        if (errorClosing) {
            alert('Veuillez corriger les erreurs avant de confirmer.');
            return;
        }

        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
            const response = await axios.post(`/api/update-sector/${selectedSector.sectorId}/`, {
                date
                
            });

            if (response.status === 200) {
                window.location.reload(); // Refresh the page on success
            }
        } catch (error) {
            console.error('Error updating route:', error);
        }
    };

    const handleConfirmOpeningSelectedSector = async () => {
        if (errorOpening) {
            alert('Veuillez corriger les erreurs avant de confirmer.');
            return;
        }

        try {
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken;
            const response = await axios.post(`/api/update-sector-opening/${selectedSector.sectorId}/`, {
                dateOpeningSelectedSector
                
            });

            if (response.status === 200) {
                window.location.reload(); // Refresh the page on success
            }
        } catch (error) {
            console.error('Error updating route:', error);
        }
    };

    const { currentGym, setCurrentGym,setCurrentPage,currentPage,setGymsFilterInfo,gymsFilterInfo,user,setUser,routeSetterInfo, setRouteSetterInfo } = globalContext;


    useEffect(() => {
        document.title = "Secteurs"
    },[])

    useEffect(() => {
        if (!currentGym) {
            const favoriteGym = localStorage.getItem('favoriteGymInfo');
            setCurrentGym(JSON.parse(favoriteGym))
        }
        if (!user) {
            const loggedUser = localStorage.getItem('user');
            if (loggedUser) {
                setUser(JSON.parse(loggedUser));
            } else {
                navigate('/login-pro');
            }
        }
    }, []);

    useEffect(() => {
        setCurrentPage(pages.secteurs)
    }, []);





    useEffect(() => {
        if (user && currentGym) {
            getRouteSetterUserInfo(user).then(data => {
                setRouteSetterInfo(data);
            });
            getSectorDetails(currentGym.id).then(({ sectorBasisChoices, latestSectorDetails }) => {
                setSectorsInitialDetails(sectorBasisChoices);
                setSectorsDetails([latestSectorDetails])
                setSelectedSector(latestSectorDetails);
                setShouldBeLoading(false)
            });
           
        } else {
            console.error("user not provided again");
        }
    }, [user, currentGym]);
    
    const handleSectorClick = async (sectorId: number) => {
        setShouldBeLoading(true);
        const existingSector = sectorsDetails.find(s => s.sectorId === sectorId);
        if (existingSector) {
            setSelectedSector(existingSector);
            setShouldBeLoading(false)
        } else {
            
            try {
                const newSector = await getSectorDetailsById(sectorId);
                setSectorsDetails(prevDetails => [...prevDetails, newSector]);
                setSelectedSector(newSector);
                
            } catch (error) {
                console.error('Error fetching sector details: ', error);
            }
            finally{
                setShouldBeLoading(false)
            }
            
        }
        
    };
    
    
    useEffect(() => {
        if (selectedSector) {
            const difficultyMap: { [key: string]: DifficultySplit } = {};
            let detailsRows: SectorDetailsRowProps[] = selectedSector.routes.map(route => {
                const difficulty = route.difficulty;
                const key = difficulty.difficultyName;
                if (!difficultyMap[key]) {
                    difficultyMap[key] = {
                        difficultyName: difficulty.difficultyName,
                        difficultyGrade: difficulty.difficultyGrade,
                        boulderCount: 0,
                        difficultyColor1: difficulty.difficultyColor1,
                        difficultyColor2: difficulty.difficultyColor2,
                    };
                }
                difficultyMap[key].boulderCount += 1;
        
                return {
                    id: route.id,
                    name: route.name,
                    difficultySvg: route.difficulty.difficultySvg,
                    difficultyId: route.difficulty.difficultyGrade,
                    level: route.level,
                    tagSteepness: route.tagSteepness,
                    tagGrips: route.tagGrips,
                    tagStyle: route.tagStyle,
                    openingDate: route.openingDate,
                    plannedDismantling: route.plannedDismantling,
                    difficultySystem: currentGym.hasCustomDifficultySystem,
                    sector: route.sector,
                    color: route.color,
                    gym: route.gym,
                    difficultyGrading: route.difficulty.difficultyGrade,
                    difficultyGymSystem: route.difficultyGymSystem,
                    status: route.status,
                    picture: route.picture,
                    difficultyDisplay: route.difficultyDisplay,
                    closedDate: route.closedDate,
                    averageRanking: route.averageRanking,
                    averageGrading: route.averageGrading,
                    successRate: route.successRate,
                    successRateBySameLevelClimbers: route.successRateBySameLevelClimbers,
                    successFlashRate: route.successFlashRate,
                    successFlashRateBySameLevelClimbers: route.successFlashRateBySameLevelClimbers,
                    totalTries: route.totalTries,
                    averageTriesBeforeTop: route.averageTriesBeforeTop,
                    averageTriesBeforeTopBySameLevelClimbers: route.averageTriesBeforeTopBySameLevelClimber,
                    routeSetter: route.routeSetter,
                    averageRankingCount: route.averageRankingCount,
                    comments: route.comments,
                    averageTriesBeforeTopBySameLevelClimber:route.averageTriesBeforeTopBySameLevelClimber
                };

            });
        
            detailsRows = detailsRows.sort((a, b) => a.difficultyId - b.difficultyId);
            const difficultyArray = Object.values(difficultyMap).sort((a, b) => a.difficultyGrade - b.difficultyGrade);
            setDifficultySplit(difficultyArray);
            setSectorDetailsRows(detailsRows);
        };
    }, [selectedSector]);

    const SpecificDateMessage = ({ selectedSector }) => {
        const daysDifferenceMessage = getDaysDifferenceMessage(selectedSector.closingDate);
        const closingDate = new Date(selectedSector.closingDate);
        const currentDate = new Date();
        const timeDifference = closingDate.getTime() - currentDate.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Difference in days
    
        let style: React.CSSProperties = { display: 'flex', flexDirection: 'row', gap: '8px' };
        let message = null;
    
        if (daysDifference < 0) {
            style = { ...style, color: Colors.colorred300 };
            message = <CustomButton Svg={SVGs.redWarning} />;
        } else if (daysDifference <= 5) {
            style = { ...style, color: Colors.colororange500 };
            message = <CustomButton Svg={SVGs.orangeWarning} />;
        }
    
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap : '8px' }}>
                <div className='bold normal' style={style}>
                    {daysDifferenceMessage}
                </div>
                {message}
            </div>
        );
    };


    return (
        <div className='outerContainer'>
            <NavBarBackOffice
                profilePicture={routeSetterInfo?.picture}
                firstName={routeSetterInfo?.firstName}
                lastName={routeSetterInfo?.lastName}
                gyms={currentGym && currentGym.name}
                
            />
            <div className="bo-main-content">
                {shouldBeLoading && 
                    <div className="loading-overlay">
                        <TailSpin color="#0099CC" height={80} width={80} />
                    </div>
                }   
                <div className="bo-main-content__title">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 13.9231V4.30769C14 3.45802 14.597 2.76923 15.3333 2.76923H20.6667C21.403 2.76923 22 3.45802 22 4.30769L22 20.4615C22 21.3112 21.403 22 20.6667 22H6.66667C5.93029 22 5.33333 21.3112 5.33333 20.4615V6.61538C5.33333 5.76572 5.93029 5.07692 6.66667 5.07692H8.66667C9.40305 5.07692 10 5.76572 10 6.61538V17.3846C10 18.2343 10.597 18.9231 11.3333 18.9231H16.6667C17.403 18.9231 18 18.2343 18 17.3846V12M10.6667 2H3C2.44772 2 2 2.44772 2 3V15.8462" stroke="#1D5073" stroke-linecap="round"/>
                    </svg>

                    <div className="bold large3" >
                        <p>Vue secteurs</p>
                    </div>
                </div>
                <div className="pannels-filter__filters">
                        <div className='header-tips'>
                            <CustomButton 
                                Svg={SVGs.brilliantIdea}
                            />
                            <div className='bold normal'>Astuce Gaston : qu’est ce qu’on voit sur cette page ?</div>
                        </div>
                        <div className='regular lower'>
                            Tous les tableaux de la page sont mis à jour selon le secteur sélectionné.
                        </div>
                    </div>
                <div style={{display:'flex', flexDirection:'row', gap:'20px', justifyContent:'space-between'}}>
                    <div className='sectors-presentation-container'>
                        <div className='sectors-presentation-title bold large3' style={{color:Colors.colorbackoffice900}}>Choisis le secteur à analyser</div>
                        <div className='sectors-presentation-visual-display'>
                            <div className='sectors-presentation-visual-display-buttons-list'>
                            {sectorsInitialDetails.map(sector => (
                                    <CustomButton
                                        key={sector.sectorId}
                                        onClickProp={() => {handleSectorClick(sector.sectorId);}}
                                        text={sector.sectorName}
                                        customStyle={sector.sectorId == selectedSector.sectorId ? 'selected-sector-button bold lower2': sector.newestSector ?'newest-sector-button bold lower2' : sector.oldestSector ? 'oldest-sector-button bold lower2' :  'select-sector-button bold lower2'}
                                    />
                                ))}
                            </div>
                            <div className='separator-line-sectors-presentation'/>
                            <div className='sectors-presentation-visual-display-container'>
                                <div className='sectors-presentation-visual-display-sectors-svgs-container'>
                                    <div className='sectors-presentation-visual-display-sectors-svgs-lines'>
                                
                                        {sectorsInitialDetails.map(sector => (
                                            <SectorItem
                                                key={sector.sectorId}
                                                {...sector}
                                                onSelect={handleSectorClick}
                                                isSelected={selectedSector?.sectorId === sector.sectorId}
                                                newestSector ={sector.newestSector}
                                                oldestSector={sector.oldestSector}
                                            />
                                        ))}
                                    </div>
                                    <div className='sectors-presentation-visual-display-sectors-svgs-name bold larger2' style={{color :Colors.colorgrey600}}>
                                        {selectedSector &&
                                        selectedSector.sectorName
                                        }
                                    </div>
                                </div>
                                <div className='sectors-presentation-visual-display-sectors-picture'>
                                    <img src=
                                    {selectedSector &&
                                        selectedSector.sectorPicture
                                    }
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'row', gap :'12px', alignItems:'flex-start',justifyContent:'flex-start'}}>
                            <CustomButton 
                                Svg={SVGs.information}
                            />
                            <div className='regular lower2' style={{color:Colors.colorgrey600}}>Le dernier secteur monté est visible en vert. Le prochain à démonter, en rouge</div>
                        </div>
                    </div>
                    <div className='sectors-presentation-container'>
                        <div>
                            <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Statistiques {selectedSector && selectedSector.sectorName}</div>
                            <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Quelques données sur la fréquentation du secteur comparées à l’ouverture précédente</div>
                        </div>
                        {selectedSector &&
                            <div style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                                
                                <div style={{display: 'flex', flexDirection:'row', gap:'16px'}}>
                                    
                                    <StatBox
                                        currentMonthStat={selectedSector.totalSectorTries}
                                        lastMonthStat={parseFloat(selectedSector.totalSectorTriesLastSector.toFixed(0))}
                                        text='Essais'
                                    />
                                    <StatBox
                                        currentMonthStat={parseFloat(selectedSector.triesPerDay.toFixed(1))}
                                        lastMonthStat={parseFloat(selectedSector.triesPerDayLastSector.toFixed(1))}
                                        text='Essais / jour'
                                    />
                                    <StatBoxSector
                                        currentMonthStat={getGradeFromIndex(Math.round(parseFloat(selectedSector.averageGrade)))}
                                        lastMonthStat={getGradeFromIndex(Math.round(parseFloat(selectedSector.averageGradeLastSector)))}
                                        text='Cotation moyenne'
                                    />
                                    
                                </div>
                            
                                <div style={{display:'flex',flexDirection:'column', gap:'8px'}}>
                                    <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey700, alignSelf:'flex-end'}}>Secteur actuel <p style={{color:Colors.colorbackoffice500}}>(Ancien Secteur)</p></div>
                                        <div style={{display:'flex',flexDirection:'column', gap:'4px'}}>
                                            <StatisticRow 
                                                customComponent={
                                                <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey500}}>
                                                    {selectedSector.percentageSuccess.toFixed(0)}%
                                                    <p style={{color:Colors.colorbackoffice500}}>({selectedSector.percentageSuccessLastSector.toFixed(0)}%)</p>
                                                </div>
                                                }
                                                label={'Taux de réussite'}
                                            />
                                            <StatisticRow 
                                                customComponent={
                                                <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey500}}>
                                                    {selectedSector.percentageFlash.toFixed(0)}%
                                                    <p style={{color:Colors.colorbackoffice500}}>({selectedSector.percentageLastSector.toFixed(0)}%)</p>
                                                </div>
                                                }
                                                label={'Taux de flash'}
                                            />
                                            <StatisticRow 
                                                customComponent={
                                                <div className='bold lower' style={{display:'flex', flexDirection:'row', gap:'8px', color:Colors.colorgrey500}}>
                                                    {selectedSector.averageTriesBeforeTop && selectedSector.averageTriesBeforeTop.toFixed(1)}
                                                    <p style={{color:Colors.colorbackoffice500}}>{selectedSector.averageTriesBeforeTopLastSector && '('}{selectedSector.averageTriesBeforeTopLastSector && selectedSector.averageTriesBeforeTopLastSector.toFixed(1)}{selectedSector.averageTriesBeforeTopLastSector &&')'}</p>
                                                </div>
                                                }
                                                label={'essais pour la croix'}
                                            />
                                            <StatisticRow 
                                                customComponent={
                                                <div className='bold lower' style={{display: 'flex',flexDirection:'row', gap:'4px'}}>
                                                    <StarRating 
                                                        review={selectedSector.ranking}
                                                        styleText={"Pas d'avis"}
                                                    />                                                
                                                    <p className='extended-row-title-container-route-summary-second-part-stats-portion-3-title-1 bold larger'>{selectedSector.rankingCount !== 0? `(${selectedSector.rankingCount})` : ' '}</p>    
                                                </div>                                 
                                                }
                                                label={'Les étoiles'}
                                            />
                                        </div>
                                </div>
                            </div>
                        } 
                        <div style={{display:'flex', flexDirection:'row', gap :'12px', alignItems:'flex-start',justifyContent:'flex-start'}}>
                            <CustomButton 
                                Svg={SVGs.information}
                            />
                            <div className='regular lower2' style={{color:Colors.colorgrey600}}>Les données de couleur bleue clair permettent de comparer l’ouveture à la précédente</div>
                        </div>
                    </div>
                    
                </div>
            
                <div className="pannels-filter__filters">
                    <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Informations générales</div>
                    {selectedSector && selectedSector.routes.length>0 &&
                        <div style={{display:'flex', flexDirection:'row', gap :'20px'}}>
                            <div className='box-date-container'>  
                                <div className='box-stat-actual-date-sector-container regular large2'>
                                    { formatDate(selectedSector.openingDate)}
                                    <CustomButton 
                                        Svg={SVGs.modifySectorWhite}
                                        onClickProp={()=>setChangeDateOpeningSector(true)}
                                    />
                                </div>
                                <div className='bold normal'>
                                    {getDaysDifferenceMessage(selectedSector.openingDate)}
                                </div>
                                <div className='regular normal'>Date d'ouverture</div>
                            </div>
                            <div className='box-date-container'>  
                                <div className='box-stat-actual-date-sector-container regular large2'>
                                    { formatDate(selectedSector.closingDate)}
                                    <CustomButton 
                                        Svg={SVGs.modifySectorWhite}
                                        onClickProp={()=>setChangeDateClosingSector(true)}
                                    />
                                </div>
                                <SpecificDateMessage
                                    selectedSector={selectedSector}
                                />
                                <div className='regular normal'>Démontage prévu</div>
                            </div>
                        </div>
                    }
                    {difficultySplit &&
                        <div style={{display:'flex', flexDirection:'row', gap:'16px',alignItems:'flex-end'}}>
                            {difficultySplit.map((difficulty, index) => (
                                <DifficultySplitComponent key={index} difficulty={difficulty} />
                            ))}
                        </div>
                    }
                </div>
                
            
                <div className="pannels-filter__filters">
                    <div>
                        <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>{selectedSector && selectedSector.routes.length} blocs à parcourir</div>
                        <div className='extended-row-title-container-route-summary-second-part-stats-portion-subtitle regular normal'>Clique sur un bloc pour accéder à ses statistiques</div>
                    </div>
                    <div className= "sectors-selected-sector-tab">
                        <div className="sectors-tab-header bold lower">
                            <div className='sector-header-selected-sector-name'>Nom</div>
                            <div className='sector-header-selected-sector-difficulty'>Difficulté</div>
                            <div className='sector-header-selected-sector-level'>Sous-dif.</div>
                            <div className='sector-header-selected-sector-tags'>Tags</div>
                            <div className='sector-header-selected-sector-opening'>Ouverture</div>
                            <div className='sector-header-selected-sector-closing'>Démontage</div>
                            <div className='sector-header-selected-sector-modify'></div>
                        </div>
                        
                        <div className="sectors-tab-rows">
                            {sectorDetailsRows && sectorDetailsRows.map(row => (
                                <SectorDetailsRow  {...row} />
                            ))}
                        </div>
                        
                    </div>
                </div>
                <div className="pannels-filter__filters">
                    <div className='filter-toggle bold large3' style={{alignItems:'baseline'}}>Derniers commentaires</div>
                    {(selectedSector && selectedSector.comments.length>0) ? selectedSector.comments.map(comment=>(
                        <CommentsCard key={comment.boulderId} {...comment} />
                        
                        ))
                        :
                        <div className='no-routes-msg light large2'>
                          Pas encore de commentaire  
                        </div>
                     }
                </div>

                
                
            </div>
            <Modal isOpen={changeDateClosingFromSelectedSector} onClose={toggleChangeDateClosingFromSelectedSector} titleModal='Modification date de démontage' width='fit-content' position='relative'>
                <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '32px 40px 32px 40px', alignItems:'center'}}>
                    <div className='modal-change-closing-date-container'>
                        <div className="form-change-closing-date-container">
                            <label htmlFor="demontage-date" className='bold normal' style={{color:Colors.colorbackoffice800}}>Date démontage</label>
                            <input
                            type="text"
                            id="demontage-date"
                            value={date}
                            onChange={handleDateClosingChange}
                            placeholder="JJ/MM/AAAA"
                            
                            />
                            { <div className={errorClosing ?"error-change-date-message regular lower2" : "change-date-message regular lower2"}>
                                <CustomButton
                                    Svg={SVGs.information}
                                />
                                <div>La date doit être saisie au format JJ/MM/AAAA</div>
                            </div>}
                        </div>
                        <div className="modal-change-closing-date-button">
                            <CustomButton 
                                customStyle='modal-change-closing-date-button-confirm bold normal'
                                text='Confirmer'
                                disabled={!!errorClosing || date == ''}
                                onClickProp={handleConfirmClosingSelectedSector}
                                customDisableStyle='modal-change-closing-date-button-confirm-disabled bold normal'
                            />
                             <CustomButton 
                                customStyle='modal-change-closing-date-button-cancel bold normal'
                                text='Retour'
                                onClickProp={()=>setChangeDateClosingSector(false)}
                               
                            />
                            
                        </div>
                    </div>
                </div>

            </Modal>
            <Modal isOpen={changeDateOpeningFromSelectedSector} onClose={toggleChangeDateOpeningFromSelectedSector} titleModal="Modification date d'ouverture" width='fit-content' position='relative'>
                <div style={{display:'flex', flexDirection:'column', gap:'24px',padding : '32px 40px 32px 40px', alignItems:'center'}}>
                    <div className='modal-change-closing-date-container'>
                        <div className="form-change-closing-date-container">
                            <label htmlFor="demontage-date" className='bold normal' style={{color:Colors.colorbackoffice800}}>Date ouverture</label>
                            <input
                            type="text"
                            id="demontage-date"
                            value={dateOpeningSelectedSector}
                            onChange={handleDateOpeningChange}
                            placeholder="JJ/MM/AAAA"
                            
                            />
                            { <div className={errorOpening ?"error-change-date-message regular lower2" : "change-date-message regular lower2"}>
                                <CustomButton
                                    Svg={SVGs.information}
                                />
                                <div>La date doit être saisie au format JJ/MM/AAAA</div>
                            </div>}
                        </div>
                        <div className="modal-change-closing-date-button">
                            <CustomButton 
                                customStyle='modal-change-closing-date-button-confirm bold normal'
                                text='Confirmer'
                                disabled={!!errorOpening || dateOpeningSelectedSector == ''}
                                onClickProp={handleConfirmOpeningSelectedSector}
                                customDisableStyle='modal-change-closing-date-button-confirm-disabled bold normal'
                            />
                             <CustomButton 
                                customStyle='modal-change-closing-date-button-cancel bold normal'
                                text='Retour'
                                onClickProp={()=>setChangeDateOpeningSector(false)}
                               
                            />
                            
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    );
}

export default SectorsDetailsPage;

