import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ipAdress } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButtonBO';

const PasswordForgottenPage2 = () => {
   

    return (
        <div className='password_changed_confirmed_outercontainer'>
            <div className='password_changed_confirmed_container'>
                <IconButton 
                    text=''
                    Svg={SVGs.checkMarkPurple}
                />
                <div className='password_reset_title_description'> 
                    <h2 className='password_changed_confirmed_title'>Ton mot de passe a été modifié</h2>
                    <p className='password_changed_confirmed_description'>Tu peux retourner te connecter sur l’app Gastón avec ton nouveau mot de passe</p>
                </div>
             
            </div>
        </div>
    );
}

export default PasswordForgottenPage2;