import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';

interface LevelSelectorProps {
    text:string
    isClicked : boolean,
    customStyle? : string,
    customTextStyle? : string,
    onClickProp?: () => void
    shouldShowFigure?: boolean
    customSize?:[number,number]
}


const LevelSelector : React.FC<LevelSelectorProps> = ({ isClicked, customStyle, shouldShowFigure, customTextStyle,text,onClickProp,customSize }) => {
    const [isClickedStatus, setIsClickedStatus] =useState(isClicked)

    const style = {
       
        ...(customSize && { width: `${customSize[0]}px`, height: `${customSize[1]}px` })
    };
   
    useEffect(() => {
        setIsClickedStatus(isClicked);
    }, [isClicked]); // This useEffect runs whenever 'isClicked' prop changes
    return (
        <div className='level-inner-container'>  
            <div className={`level-${text} ${isClickedStatus ? `blue-level-${text}` : "grey-level"}`} style={style} onClick={onClickProp}></div>
            {shouldShowFigure && <p>{text}</p>}
        </div>
        

   
    )
}


export default LevelSelector