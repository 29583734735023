import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ipAdress, loginUser, passwordForgottenRequest } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import { GlobalContext } from '../context/GlobalStateProvider';
import { Colors } from '../constants/Consts';
import Modal from '../components/UI/ModalRoute';
import CustomButton from '../components/UI/CustomButton';

const PasswordResetPage = () => {
    const globalContext = useContext(GlobalContext);


    useEffect(() => {
        document.title = "Login ouverture"},[])
    const navigate = useNavigate()
    const [openRedirectionModal, setOpenRedirectionModal] = useState(false)
    const toggleStatusRedirectionModal = () => {
        setOpenRedirectionModal(prev => !prev); // Toggle the state of extendedRow
    };
    
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
  
    const [modalTitle, setModalTitle] = useState('');
    const [modalDescription, setModalDescription] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

   

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Adresse email invalide')
                .required("L'email est requis"),
            
        }),
        onSubmit: (values) => {
            // Replace with your API endpoint and necessary payload
            passwordForgottenRequest(
                values.email, // assuming your loginUser function expects an email for username
                
                () => { // onSuccess callback
                    setModalTitle('Email Envoyé');
                    setModalDescription('Tu as reçu un lien de réinitialisation de mot de passe dans ta boîte mail. Pense bien à vérifier dans tes spams.');
                    setOpenRedirectionModal(true);
                    setIsSuccess(true); // Set success state to true
                },
                (error) => { // onError callback
                    setModalTitle('Une erreur est survenue.');
                    setModalDescription("Nous n'avons pas pu envoyer de mail à cette adresse. Vérifie que c'est bien à cette adresse que tu as créé ton compte, si tu continues de rencontrer des problèmes, contacte-nous sur insta (gaston_grimpe).");
                    setErrorMsg(error);
                    setOpenRedirectionModal(true);
                    setIsSuccess(false); // Set success state to false
                }
            );
        
       
        },
    });

    return (
        <div className='login_outercontainer'>
            <div style={{alignSelf:'center', justifySelf:'center'}}>
                    <IconButton 
                        Svg={SVGs.gastonOuvertureLogo}
                    />
                </div>
            <div className='login_container'>
                <div className='password_reset_title_description'> 
                    <h2 className='password_reset_title'>Réinitialise ton mot de passe</h2>
                    <p className='password_reset_description'>Tu recevras un lien pour changer ton mot de passe. Pense bien à vérifier dans tes spams</p>
                </div>
                <form className='password_reset_container_form' onSubmit={formik.handleSubmit}>
                    <div className='password_reset_container_form_full_formik_validation'>
                        <div className={formik.touched.email && formik.errors.email ? 'password_reset_container_form_input_icon_container_error' : 'password_reset_container_form_input_icon_container' }>
                            <input 
                                type="text"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="E-mail *"
                                className={(formik.touched.email && formik.errors.email) && 'error_input'}
                                onFocus={() => formik.setFieldError('email', '')}
                                
                            />
                            {(formik.touched.email && formik.errors.email) &&
                                <IconButton 
                                    text=''
                                    Svg={SVGs.warningIcon}
                                />
                            }
                        
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                            <div className='error_password_message'>{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <button 
                        className={`${formik.isValid && formik.dirty && !formik.isSubmitting ? 'login_button' : 'login_button_disabled'}`}
                        type="submit"
                        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
                    >
                        Envoyer le lien
                        
                    </button>
                </form>
                {errorMsg &&
                        <p style={{marginTop:'-16px'}} className='Landing-page-gym-finder-header-description-error'>{errorMsg}</p>
                }
            </div>
            <Modal isOpen={openRedirectionModal} onClose={toggleStatusRedirectionModal} titleModal={modalTitle} position='relative' width='fit-content'>
                <div className='login_container'>
                    <div className='password_reset_title_description'> 
                        <h2 className='password_reset_title'>{modalTitle}</h2>
                        <p className='password_reset_description'>{modalDescription}</p>
                    </div>
                    {isSuccess ? (
                        <CustomButton 
                            text='Confirmer'
                            customStyle='login_button'
                            onClickProp={() => navigate(`/login-pro`)}

                        />
                    ) : (
                        <CustomButton 
                            text='Retour'
                            customStyle='login_button'
                            onClickProp={() => setOpenRedirectionModal(false)}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default PasswordResetPage;