import React, { useState,useEffect } from 'react';
import { SVGs } from '../../constants/Svgs';

interface IconButtonProps {

    text?:string
    isClicked? : boolean,
    customStyle? : string,
    customTextStyle? : string,
    onClickProp?: () => void
    Svg? : React.FC

}


const IconButton : React.FC<IconButtonProps> = ({ isClicked, customStyle, customTextStyle,text,onClickProp,Svg }) => {
    const [isClickedStatus, setIsClickedStatus] =useState(isClicked)
   
    useEffect(() => {
        setIsClickedStatus(isClicked);
    }, [isClicked]); // This useEffect runs whenever 'isClicked' prop changes
    const baseClasses = [];
    if (customStyle) {
        baseClasses.push(customStyle);
    }
    if(customTextStyle) {
        baseClasses.push(customTextStyle)
    }

    return (
        <>
            {text}
            {Svg && <Svg />}
        </>
       
    );

}


export default IconButton