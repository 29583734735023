import React from 'react';
import LevelSelector from './LevelSelector'; // Ensure the correct path to your LevelSelector component

interface LevelDisplayerDirectProps {
    level : number
}

const LevelDisplayerDirect: React.FC<LevelDisplayerDirectProps> = ({ level}) => {
    const renderLevelSelectors = () => {
        if (level == -1) {
            return (
                <>
                    <LevelSelector isClicked={true} text={'1'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'2'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'3'} shouldShowFigure={false} />
                </>
            );
        } else if (level == 0) {
            return (
                <>
                    <LevelSelector isClicked={false} text={'1'} shouldShowFigure={false} />
                    <LevelSelector isClicked={true} text={'2'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'3'} shouldShowFigure={false} />
                </>
            );
        } else {
            return (
                <>
                    <LevelSelector isClicked={false} text={'1'} shouldShowFigure={false} />
                    <LevelSelector isClicked={false} text={'2'} shouldShowFigure={false} />
                    <LevelSelector isClicked={true} text={'3'} shouldShowFigure={false} />
                </>
            );
        }
    };

    return (
        <div className={"sector-details-row-level normal regular"} style={{ justifyContent: 'flex-stard' }}>
            {renderLevelSelectors()}
        </div>
    );
};

export default LevelDisplayerDirect;